import {css} from "@emotion/react";
import {ExtendedKeyboardEvent} from "mousetrap";
import {MouseEvent} from "react";
import {size, smartphone} from "src/components/constants/constants";


interface AvatarImageProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "children" | "onClick"> {
  /**
   * アバターの種類。
   * @defaultValue `"hair"`
   */
  avatarType: string;
  /*
  * アバターの名前。
  */
  avatarName: string | null;
  /** */
  onClick?: (event: MouseEvent<HTMLDivElement> | ExtendedKeyboardEvent) => void;
};

const styles = {
  imageCover: css`
    width: ${size(24)};
    height: ${size(24)};
    display: flex;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    ${smartphone()} {
      width: calc(50% - ${size(1)});
      height: auto;
      aspect-ratio: 1 / 1;
    }
  `,
  avatarImage: css`
    height: 580%;
    transform: translateY(-2%);
    &[data-type="hair"] {
      transform: translateY(0);
    }
    &[data-type="outfit"] {
      transform: translateY(-10%);
    }
    &[data-type="body"] {
      transform: translateY(-4%);
    }
  `
};

/**
 * - **Inner Props**: {@link AvatarImageProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const AvatarImage: React.FC<AvatarImageProps> = (({
  avatarType,
  avatarName,
  onClick,
  ...props
}) => {
  const AVATAR_BASE_URL = "https://d14riej7e18ms7.cloudfront.net/Wearable";

  return (
    <div css={styles.imageCover} onClick={onClick} {...props}>
      <img css={styles.avatarImage} data-type={avatarType} src={`${AVATAR_BASE_URL}/${avatarType}/${avatarName}.png`}/>
    </div>
  );
});