/**
 * @group 列挙型
 * @category 検閲
 */
export const CensorshipReason = {
  /** */
  None: "CensorshipReasonNone",
  /** */
  Inappropriate: "CensorshipReasonInappropriate",
  /** */
  CopyrightViolation: "CensorshipReasonCopyrightViolation",
  /** */
  Other: "CensorshipReasonOther",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type CensorshipReason = typeof CensorshipReason[keyof typeof CensorshipReason];