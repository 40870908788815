import {Competition, CompetitionQuizResult, CompetitionUserQuizResult, CompetitionUserResult, CompetitionWholeResult, CompetitionWholeResultPerUser, ListedCompetition} from "src/types";
import {DeepNonNullable} from "ts-essentials";
import * as raw from "../raw/quizium/competition";


export const toCompetition = (raw: DeepNonNullable<raw.Competition>): Competition => {
  return {
    ...raw,
    aggregationSettings: raw.aggregationSettings.map((setting) => ({name: setting.name, tags: [setting.firstTag, setting.secondTag, setting.thirdTag]}))
  };
};

export const toListedCompetition = (raw: DeepNonNullable<raw.ListedCompetition>): ListedCompetition => {
  return {
    ...raw,
    aggregationSettings: raw.aggregationSettings.map((setting) => ({name: setting.name, tags: [setting.firstTag, setting.secondTag, setting.thirdTag]}))
  };
};

export const toCompetitionResult = (raw: raw.CompetitionResult): CompetitionWholeResult => {
  return {
    ...raw,
    teamResults: raw.teamResult.map(toCompetitionScoreResult),
    userResults: raw.userResult.map(toCompetitionScoreResult)
  };
};

export const toCompetitionQuizResult = (raw: Array<raw.CompetitionQuizResult>): CompetitionQuizResult => {
  return raw.map((eachRaw) => ({
    ...eachRaw,
    points: [eachRaw.firstPoint, eachRaw.secondPoint, eachRaw.thirdPoint]
  }));
};

export const toCompetitionUserQuizResult = (raw: raw.CompetitionQuizResult): CompetitionUserQuizResult => {
  return {
    ...raw,
    points: [raw.firstPoint, raw.secondPoint, raw.thirdPoint]
  };
};

export const toCompetitionUserResult = (raw: raw.CompetitionUserResult): CompetitionUserResult => {
  return {
    points: [raw.firstPoint, raw.secondPoint, raw.thirdPoint],
    answerCounts: {
      correct: raw.correctAnswerCount,
      incorrect: raw.incorrectAnswerCount,
      neutral: raw.neutralCount,
      unqualified: raw.unqualifiedCount
    },
    rank: raw.rank,
    quizResults: raw.quizResults
  };
};

export const toCompetitionScoreResult = (raw: raw.ScoreResult): CompetitionWholeResultPerUser => {
  return {
    id: raw.id,
    name: raw.name,
    points: [raw.firstPoint, raw.secondPoint, raw.thirdPoint],
    rank: raw.rank
  };
};
