import {isInaccessibleError, isNotFoundError} from "src/modules";


export type RawApi<T = any> = (...args: any) => T;
export type AsyncRawApi<T = any> = (...args: any) => Promise<T>;

export type Api<F extends RawApi, N extends string = string> = F & {readonly apiName: N};
export type AsyncApi<F extends AsyncRawApi, N extends string = string> = F & {readonly apiName: N};

/**
 * 与えられた API 関数をラップして、アクセスしようとしたデータが存在しなかったことが原因のエラーが発生したときに、エラーを発生させずに `null` を返すような API 関数を作ります。
 * API 関数がそれ以外のエラーを発生させた (権限不足やタイムアウトなど) 場合は、依然としてエラーを発生させます。
 *
 * 例えば、`getQuiz` 関数は、指定された ID のクイズが存在しなかった場合にエラーを発生させます。
 * しかし、以下のように `orNull` 関数を使ってラップすることで、クイズが存在しなかった場合にはエラーを発生させず `null` を返すようになります。
 * ```
 * // クイズが存在しない場合はエラー
 * // quiz は Quiz 型
 * const quiz = getQuiz(id);
 *
 * // クイズが存在しない場合は null を返す
 * // quiz は Quiz | null 型
 * const quizOrNull = orNull(getQuiz)(id);
 * ```
 * @param api API 関数
 * @returns ラップされた API 関数
 */
export const orNullWhenNotFound = <F extends AsyncRawApi, N extends string>(api: AsyncApi<F, N>): AsyncApi<(...args: Parameters<F>) => Promise<Awaited<ReturnType<F>> | null>, `${N}.orNullWhenNotFound`> => {
  const wrappedApi = Object.assign((async (...args) => {
    try {
      return await api(...args);
    } catch (error) {
      if (isNotFoundError(error)) {
        return null;
      } else {
        throw error;
      }
    }
  }) as AsyncRawApi, {
    apiName: `${api.apiName}.orNullWhenNotFound` as const
  });
  return wrappedApi;
};

/**
 * 与えられた API 関数をラップして、アクセスしようとしたデータにアクセスできなかったことが原因のエラーが発生したときに、エラーを発生させずに `null` を返すような API 関数を作ります。
 * API 関数がそれ以外のエラーを発生させた (タイムアウトなど) 場合は、依然としてエラーを発生させます。
 * @param api API 関数
 * @returns ラップされた API 関数
 */
export const orNullWhenInaccessible = <F extends AsyncRawApi, N extends string>(api: AsyncApi<F, N>): AsyncApi<(...args: Parameters<F>) => Promise<Awaited<ReturnType<F>> | null>, `${N}.orNullWhenInaccessible`> => {
  const wrappedApi = Object.assign((async (...args) => {
    try {
      return await api(...args);
    } catch (error) {
      if (isInaccessibleError(error)) {
        return null;
      } else {
        throw error;
      }
    }
  }) as AsyncRawApi, {
    apiName: `${api.apiName}.orNullWhenInaccessible` as const
  });
  return wrappedApi;
};

/**
 * 与えられた API 関数をラップして、その関数内でエラーが発生したときに、エラーを発生させずに `null` を返すような API 関数を作ります。
 * @param api API 関数
 * @returns ラップされた API 関数
 */
export const orNullWhenError = <F extends AsyncRawApi, N extends string>(api: AsyncApi<F, N>): AsyncApi<(...args: Parameters<F>) => Promise<Awaited<ReturnType<F>> | null>, `${N}.orNullWhenError`> => {
  const wrappedApi = Object.assign((async (...args) => {
    try {
      return await api(...args);
    } catch (error) {
      return null;
    }
  }) as AsyncRawApi, {
    apiName: `${api.apiName}.orNullWhenError` as const
  });
  return wrappedApi;
};

/**
 * `orNullWhenNotFound` の別名です。
 * よく使うので短い名前で使えるようにしてあります。
 * @param api API 関数
 * @returns ラップされた API 関数
 */
export const orNull = orNullWhenNotFound;