import {AnswerType, CensoredQuiz, MediumType, Quiz, getPlayableDeck, getQuizzes} from "@baton8/qroud-lib-repositories";
import {Actor, CollisionType} from "excalibur";
import {TriggerMethod, TriggerType, withFieldEntity, withPlayerTrigger} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {RandomQuizOverlay} from "src/overlays/common/randomQuizOverlay";


interface Properties {
  /**
   * クイズデッキのIO。
   */
  deckId: string;
  /**
   * トリガーの種類。
   * @defaultValue `"none"`
   */
  triggerType?: TriggerType;
  /**
   * トリガーを判定する方法。
   * @defaultValue `"touch"`
   */
  triggerMethod?: TriggerMethod;
  /**
   * これが `true` のときは、プレイヤーがこのエンティティから離れたときに自動的にメッセージを消します。
   * これが `false` のときは、メッセージウィンドウの閉じるボタンを押さない限りメッセージは消えません。
   * @defaultValue `false`
   */
  hideOnEnd?: boolean;
};

export interface RandomQuiz {
  id: string;
  deckId: string;
  question: string;
  choices: Array<{text: string, isCorrect: boolean}>;
}

export class RandomQuizTrigger extends withPlayerTrigger(withFieldEntity(Actor)) {
  private deckId: string;
  private quizzes: Array<RandomQuiz> = [];
  private currentQuizIndex: number = 0;

  public constructor(configs: FieldEntityConfigs<Properties>) {
    super(configs);
    this.body.collisionType = CollisionType.Passive;
    this.deckId = configs.properties.deckId;
    this.setupEventListeners(configs.properties);
  }

  private setupEventListeners(properties: Properties): void {
    const triggerType = properties.triggerType ?? "none";
    const triggerMethod = properties.triggerMethod ?? "touch";
    const hideOnExit = properties.hideOnEnd ?? false;

    if (triggerMethod === "touch") {
      if (triggerType === "none") {
        this.on("playerTrigger.touchstart", this.showMessage.bind(this));
      } else {
        this.on("playerTrigger.touchpressed", this.showMessage.bind(this));
      }
      if (hideOnExit) {
        this.on("playerTrigger.touchend", this.hideMessage.bind(this));
      }
    } else {
      if (triggerType === "none") {
        this.on("playerTrigger.facestart", this.showMessage.bind(this));
      } else {
        this.on("playerTrigger.facepressed", this.showMessage.bind(this));
      }
      if (hideOnExit) {
        this.on("playerTrigger.faceend", this.hideMessage.bind(this));
      }
    }
  }

  private async showMessage(): Promise<void> {
    this.quizzes = await createQuizzes(this.deckId);

    const nextQuizIndex = this.getNextQuizIndex();
    const nextQuiz = this.quizzes[nextQuizIndex];

    const handleOnClickNext = (): void => {
      this.currentQuizIndex = this.getNextQuizIndex();
      const quiz = this.quizzes[this.currentQuizIndex];
      RandomQuizOverlay.propsSubject.update({
        isVisible: true,
        quiz
      });
    };

    const handleOnClickQuit = (): void => {
      this.quizzes = [];
      this.currentQuizIndex = 0;
      this.hideMessage();

    };

    RandomQuizOverlay.propsSubject.update({
      isVisible: true,
      quiz: nextQuiz,
      onClickNext: handleOnClickNext,
      onClickQuit: handleOnClickQuit
    });
  }

  private hideMessage(): void {
    RandomQuizOverlay.propsSubject.update({
      isVisible: false
    });
  }

  private getNextQuizIndex(): number {
    return Math.floor(Math.random() * this.quizzes.length);
  }
}

const isQuiz = (quiz: Quiz | CensoredQuiz): quiz is Quiz => {
  return (quiz as Quiz).question !== undefined;
};

const createQuizzes = async (deckId: string): Promise<Array<RandomQuiz>> => {
  const deck = await getPlayableDeck(deckId);
  const quizzes = await getQuizzes(deck.quizIds);

  return quizzes.map((quiz) => {
    if (isQuiz(quiz)) {
      return {
        id: quiz.id,
        question: quiz.question.find((medium) => medium.type === MediumType.Text)?.content ?? "",
        choices: quiz.answer.map((choice) => ({
          text: choice.media.find((medium) => medium.type === MediumType.Text)?.content ?? "",
          isCorrect: choice.answerType === AnswerType.Correct
        })),
        deckId
      };
    } else {
      return {
        id: quiz.id,
        question: "",
        choices: [],
        deckId
      };
    }
  });
};