import {client} from "src/clients/client";
import {
  Group
} from "src/types";
import {DateString, Id} from "src/types/modified/misc";
import {
  CreateGroupReq,
  CreateGroupRes,
  DeleteGroupReq,
  DeleteGroupRes,
  GetGroupNoticeReq,
  GetGroupNoticeRes,
  GetGroupReq,
  GetGroupRes,
  MultiGetGroupReq,
  MultiGetGroupRes,
  UpdateGroupNoticeReq,
  UpdateGroupNoticeRes,
  UpdateGroupReq,
  UpdateGroupRes
} from "src/types/raw/quizium/user_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category グループ
 */
export interface GroupForCreate {
  handle: string;
  name: string;
  description: string;
  notice: string;
  tags: Array<string>;
}

/**
 * 新しいグループを作成します。
 *
 * 返される値はグループのハンドルです。
 * ID ではありません。
 * @param user 作成するユーザー情報
 * @returns ユーザー ID
 * @group API 関数
 * @category グループ
 */
export const createGroup = createAsyncApi(async (group: GroupForCreate): Promise<string> => {
  const req: CreateGroupReq = {group};
  const res = await client.post<CreateGroupRes>("/quizium/v3/group", req);
  return res.data.handle;
}, "createGroup");

/**
 * @group 型
 * @category グループ
 */
export interface GroupForUpdate {
  handle: string;
  name: string;
  description: string;
  tags: Array<string>;
  icon: Id;
}

/**
 * @group API 関数
 * @category グループ
 */
export const updateGroup = createAsyncApi(async (group: GroupForUpdate): Promise<void> => {
  const req: UpdateGroupReq = {group};
  const res = await client.post<UpdateGroupRes>("/quizium/v3/group/update", req);
}, "updateGroup");

/**
 * グループを削除します。
 * グループがデッキやクイズが所有していない必要があります。
 * @group API 関数
 * @category グループ
 */
export const deleteGroup = createAsyncApi(async (handle: string): Promise<void> => {
  const req: DeleteGroupReq = {handle};
  const res = await client.post<DeleteGroupRes>("/quizium/v3/group/delete");
}, "deleteGroup");

/**
 * @group API 関数
 * @category グループ
 */
export const getGroupByHandle = createAsyncApi(async (handle: string): Promise<Group> => {
  const req: GetGroupReq = {handle, id: ""};
  const res = await client.post<GetGroupRes>("/quizium/v3/group/get", req);
  return res.data.group!;
}, "getGroupByHandle");

/**
 * @group API 関数
 * @category グループ
 */
export const getGroupById = createAsyncApi(async (id: string): Promise<Group> => {
  const req: GetGroupReq = {id, handle: ""};
  const res = await client.post<GetGroupRes>("/quizium/v3/group/get", req);
  return res.data.group!;
}, "getGroupById");

/**
 * @group API 関数
 * @category グループ
 */
export const getGroupsByHandles = createAsyncApi(async (handles: Array<string>): Promise<Array<Group>> => {
  if (handles.length > 0) {
    const req: MultiGetGroupReq = {handles, ids: []};
    const res = await client.post<MultiGetGroupRes>("/quizium/v3/group/MultiGet", req);
    return res.data.groups;
  } else {
    return [];
  }
}, "getGroupsByHandles");

/**
 * @group API 関数
 * @category グループ
 */
export const getGroupsByIds = createAsyncApi(async (ids: Array<Id>): Promise<Array<Group>> => {
  if (ids.length > 0) {
    const req: MultiGetGroupReq = {ids, handles: []};
    const res = await client.post<MultiGetGroupRes>("/quizium/v3/group/MultiGet", req);
    return res.data.groups;
  } else {
    return [];
  }
}, "getGroupsByIds");

/**
 * @group API 関数
 * @category グループ
 */
export const getGroupNotice = createAsyncApi(async (handle: string): Promise<{notice: string, updateNoticeAt: DateString}> => {
  const req: GetGroupNoticeReq = {handle};
  const res = await client.post<GetGroupNoticeRes>("/quizium/v3/group/notice", req);
  return {notice: res.data.notice, updateNoticeAt: res.data.updateNoticeAt};
}, "getGroupNotice");

/**
 * @group API 関数
 * @category グループ
 */
export const updateGroupNotice = createAsyncApi(async (handle: string, notice: string): Promise<void> => {
  const req: UpdateGroupNoticeReq = {handle, notice};
  const res = await client.post<UpdateGroupNoticeRes>("/quizium/v3/group/notice/update", req);
}, "updateGroupNotice");
