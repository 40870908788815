import Cookies from "js-cookie";
import {isDeploy, switchEnv} from "src/modules";


const TOKEN_KEY = switchEnv({
  production: "quizium_token",
  staging: "quizium_token_stg",
  development: "quizium_token_dev"
});
const COOKIE_AGREED_KEY = switchEnv({
  production: "quizium_agreed",
  staging: "quizium_agreed_stg",
  development: "quizium_agreed_dev"
});

export class TokenManager {
  public static readonly instance = new TokenManager();

  private token: string | null;
  private isAgreed: boolean;

  private constructor() {
    if (typeof localStorage !== "undefined") {
      this.isAgreed = localStorage.getItem(COOKIE_AGREED_KEY) === "true";
    } else {
      this.isAgreed = false;
    }
    if (typeof window !== "undefined") {
      this.token = Cookies.get(TOKEN_KEY) ?? null;
    } else {
      this.token = null;
    }
  }

  public set(token: string): void {
    this.token = token;
    if (this.isAgreed) {
      if (typeof window !== "undefined") {
        Cookies.set(TOKEN_KEY, token, {expires: 30, domain: isDeploy() ? ".quizium.io" : undefined});
      }
    }
  }

  public get(): string | null {
    const token = this.token;
    return token;
  }

  public clear(): void {
    this.token = null;
    if (typeof window !== "undefined") {
      Cookies.remove(TOKEN_KEY, {expires: 30, domain: isDeploy() ? ".quizium.io" : undefined});
    }
  }

  public agree(): void {
    this.isAgreed = true;
    if (typeof window !== "undefined") {
      localStorage.setItem(COOKIE_AGREED_KEY, "true");
    }
  }

  public refuse(): void {
    this.isAgreed = false;
    this.clear();
    if (typeof window !== "undefined") {
      localStorage.setItem(COOKIE_AGREED_KEY, "false");
    }
  }

  public getAgreed(): boolean {
    return this.isAgreed;
  }

  public getAnswered(): boolean {
    if (typeof localStorage !== "undefined") {
      return !!localStorage.getItem(COOKIE_AGREED_KEY);
    } else {
      return false;
    }
  }
}
