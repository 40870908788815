import {global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {
  Actor,
  CollisionType,
  Engine,
  Random,
  Shape,
  Vector,
  vec
} from "excalibur";
import {ReactNode} from "react";
import {alpha, color, filterBlur, size} from "src/components";
import {FollowingNodeComponent} from "src/ecs";
import {FieldEntityComponent, StoryGenerator, withStories} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {withPlayerTrigger} from "src/ecs/playerTrigger/with";
import {CharacterAnimation} from "src/entities/common/characterAnimation";


// TODO: デザイン未調整
const styles = {
  root: css`
    padding-block: ${size(1)};
    padding-inline: ${size(2)};
    border-radius: ${size(2)};
    font-size: ${size(3)};
    color: ${color("whiteText")};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    transform: translate(-50%, -100%);
  `,
  image: css`
    width: ${size(36)};
    height: ${size(24)};
    margin: ${size(1)};
  `
};

export interface NpcProperties {
  image?: string;
  isMove?: boolean;
  hasCollision?: boolean;
  isRandomMessage: boolean;
  message?: {
    [C in number]: {
      [C in string]: string
    }
  };
  messageImage?: {
    [C in number]: {
      [C in string]: string
    }
  };
};

export class Npc extends withPlayerTrigger(withStories(Actor)) {
  private readonly properties: NpcProperties;
  private characterAnimation: CharacterAnimation;
  // メッセージ関連
  private node: {[C in number]: ReactNode};
  private nodeKey: Array<string>;
  private messageCount: number;
  // 移動範囲
  public areaStartPosition: Vector | null;
  public areaEndPosition: Vector | null;

  public constructor(configs: FieldEntityConfigs<NpcProperties>) {
    super({
      ...configs,
      width: 96,
      height: 96
    });
    this.properties = configs.properties;
    this.characterAnimation = new CharacterAnimation();

    this.node = {};
    this.nodeKey = Object.keys(this.properties.message!);
    this.messageCount = 0;

    this.areaStartPosition = null;
    this.areaEndPosition = null;

    this.addComponent(new FollowingNodeComponent({anchor: "top"}));
    this.setupCollision();
    this.setupNode();
    this.setupStory();
    this.on("playerTrigger.touchpressed", this.showMessage.bind(this));
    this.on("playerTrigger.touchend", this.hideMessage.bind(this));
  }

  public override onInitialize(engine: Engine): void {
    if (this.properties.image != null) {
      this.characterAnimation.setupAnimations(this.graphics, this.properties.image, this.height);
    }
  }

  public override onPreUpdate(engine: Engine, delta: number): void {
    if (this.properties.image != null) {
      this.characterAnimation.updateAnimation(this.graphics, this.vel);
    }
  }

  /**
   * 当たり判定を追加します。
   */
  private setupCollision(): void {
    if (this.properties.hasCollision) {
      const actor = new Actor({collider: Shape.Box(48, 32)});
      actor.body.collisionType = CollisionType.Fixed;
      actor.pos = new Vector(0, 16);
      this.addChild(actor);
    }
  }

  private setupNode(): void {
    this.messageCount = 0;
    this.nodeKey = Object.keys(this.properties.message!);
    this.node = {};
    this.nodeKey.forEach((key) => {
      const imageUrl = this.properties.messageImage?.[+key]?.[global.locale];
      if (imageUrl !== undefined && imageUrl?.length > 0) {
        // 画像あり
        this.node[+key] = (
          <div css={styles.root}>
            <img css={styles.image} src={this.properties.messageImage?.[+key]?.[global.locale]}/>
            <div>{this.properties.message?.[+key]?.[global.locale]}</div>
          </div>
        );
      } else {
        // 画像なし
        this.node[+key] = (
          <div css={styles.root}>
            <div>{this.properties.message?.[+key]?.[global.locale]}</div>
          </div>
        );
      }
    });
  }

  /**
   * ランダム移動処理を追加します。
   */
  private setupStory(): void {
    this.stories.addStory(function *(this: Npc) {
      const followingNode = this.get(FollowingNodeComponent)!;
      const random = new Random();
      while (true) {
        if (this.areaStartPosition === null || this.areaEndPosition === null || followingNode.node !== null) {
          yield* this.stories.storyWait(1000);
          continue;
        }

        const rate = random.integer(0, 7);// 確率は適当。いつか指定があれば修正
        switch (rate) {
        case 0:
          yield* this.storyMoveByHasArea(new Vector(10, 0), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        case 1:
          yield* this.storyMoveByHasArea(new Vector(-10, 0), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        case 2:
          yield* this.storyMoveByHasArea(new Vector(0, 10), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        case 3:
          yield* this.storyMoveByHasArea(new Vector(0, -10), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        default:
          yield* this.stories.storyWait(1000);
          break;
        }
      }
    }.bind(this));
  }

  private showMessage(): void {
    // メッセージ表示(ランダム表示で分岐)
    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    if (folloingNodeComponent.node === null) {
      if (this.properties.isRandomMessage) {
        const key = this.nodeKey[Math.floor(Math.random() * this.nodeKey.length)];
        folloingNodeComponent.node = this.node[+key];
      } else {
        const key = this.nodeKey[this.messageCount];
        folloingNodeComponent.node = this.node[+key];
        this.messageCount ++;
        if (this.messageCount >= this.nodeKey.length) {
          this.messageCount = 0;
        }
      }
    }

    // プレイヤーの方を向く
    const player = this.get(FieldEntityComponent)!.player;
    const pos = new Vector(player.pos.x - this.pos.x, player.pos.y - this.pos.y);
    if (Math.abs(pos.x) > Math.abs(pos.y)) {
      pos.y = 0;
    } else {
      pos.x = 0;
    }
    this.characterAnimation.updateAnimation(this.graphics, pos);
    this.vel = vec(0, 0);
  }

  private hideMessage(): void {
    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    folloingNodeComponent.node = null;
  }

  public *storyMoveByHasArea(destPos: Vector, speed: number, areaStartPosition: Vector, areaEndPosition: Vector): StoryGenerator {
    const targetPosition = new Vector(this.pos.x + destPos.x, this.pos.y + destPos.y);
    this.vel = targetPosition.sub(this.pos).normalize().scale(speed);

    while (this.vel.x !== 0 || this.vel.y !== 0) {
      if ((this.vel.x > 0 && this.pos.x > areaEndPosition?.x) ||
      (this.vel.x < 0 && this.pos.x < areaStartPosition?.x) ||
      (this.vel.y > 0 && this.pos.y > areaEndPosition?.y) ||
      (this.vel.y < 0 && this.pos.y < areaStartPosition?.y)
      ) {
        this.vel = vec(0, 0);
        break;
      }

      const delta = yield;

      if (targetPosition.distance(this.pos) <= speed * delta / 1000) {
        this.vel = vec(0, 0);
        this.pos = targetPosition.clone();
        break;
      }
    }
  }
}
