import {DeckMemberType, GroupMemberType, UserStatus} from "src/types";


export const getGroupMemberTypeString = (memberType: GroupMemberType): RemovePrefix<GroupMemberType, "MemberType"> => {
  return getEnumString(memberType, "MemberType");
};

export const getDeckMemberTypeString = (memberType: DeckMemberType): RemovePrefix<DeckMemberType, "DeckMemberType"> => {
  return getEnumString(memberType, "DeckMemberType");
};

export const getUserStatusString = (status: UserStatus): RemovePrefix<UserStatus, "UserStatus"> => {
  return getEnumString(status, "UserStatus");
};

export type RemovePrefix<K extends string | number | symbol, P extends string> = K extends `${P}${infer R}` ? Lowercase<R> : K;

export const getEnumString = <E extends string, P extends string>(value: E, prefix: P): RemovePrefix<E, P> => {
  const regexp = new RegExp(`^${prefix}`);
  return value.replace(regexp, "").toLowerCase() as any;
};