import {css} from "@emotion/react";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import mousetrap, {ExtendedKeyboardEvent} from "mousetrap";
import {ButtonHTMLAttributes, ReactNode, forwardRef, useEffect} from "react";
import {MouseEvent} from "react";
import {alpha, borderWidth, boxShadow, color, size, smartphone} from "src/components/constants/constants";
import {HotkeyBadge} from "./hotkeyBadge";


export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "css" | "type" | "onClick"> {
  /**
   * ボタンのカラースキーム。
   * @defaultValue `"primary"`
   */
  colorScheme?: "primary";
  /**
   * ボタンのスタイル。
   * - `"solid"` — ボタンの背景がカラースキームの色で塗りつぶされる
   * - `"outline"` — ボタンの背景が白
   * @defaultValue `"solid"`
   */
  variant?: "solid" | "outline";
  /**
   * ボタンのサイズ。
   * 文字のサイズは変わらず、余白の大きさが変わります。
   * @defaultValue `"md"`
   */
  size?: "md" | "lg";
  /**
   * テキストの左の表示するアイコン。
   */
  iconNode?: ReactNode;
  /**
   * ボタンの種類。
   * HTML と違ってデフォルト値が `"button"` になっているので、フォームの送信ボタンとして使う場合は明示的に `"submit"` を指定してください。
   * @defaultValue `"button"`
   */
  type?: "submit" | "reset" | "button";
  /**
   * ローディング中かどうか。
   * これが `true` の間は、ボタンがローディング表示され無効になります。
   */
  isLoading?: boolean;
  /**
   * ボタンを発火するショートカットキー。
   * Mousetrap のキー設定が利用できます。
   */
  hotkeys?: string | Array<string>;
  /** */
  onClick?: (event: MouseEvent<HTMLButtonElement> | ExtendedKeyboardEvent) => void;
  /** */
  className?: string;
}

const styles = {
  root: (colorScheme: NonNullable<ButtonProps["colorScheme"]>) => css`
    box-shadow: ${boxShadow(alpha(color(colorScheme, 5), 0.2), 0.5)};
    border-radius: 100em;
    line-height: 1;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    align-items: center;
    position: relative;
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color(colorScheme, 5), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
    &:disabled {
      box-shadow: ${boxShadow(alpha(color(colorScheme, 5), 0), 0.5)};
      opacity: 0.5;
      cursor: inherit;
    }
    &[data-variant="solid"] {
      color: ${color("white")};
      background-color: ${color(colorScheme, 5)};
      &:hover:not(:disabled) {
        background-color: ${color(colorScheme, 4)};
      }
    }
    &[data-variant="outline"] {
      color: ${color(colorScheme, 5)};
      background-color: ${color("white")};
      &:hover:not(:disabled) {
        background-color: ${color(colorScheme, 0)};
      }
    }
  `,
  content: css`
    flex-grow: 1;
    flex-shrink: 1;
    &[data-size="md"] {
      padding-block: 0.5em;
      padding-inline: 1em;
      &[data-hotkeys="true"] {
        padding-inline-end: 0.5em;
        ${smartphone()} {
          padding-inline-end: 1em;
        }
      }
    }
    &[data-size="lg"] {
      padding-block: 0.75em;
      padding-inline: 1.5em;
      &[data-hotkeys="true"] {
        padding-inline-end: 0.75em;
        ${smartphone()} {
          padding-inline-end: 1.5em;
        }
      }
    }
  `,
  hotkey: css`
    flex-grow: 0;
    flex-shrink: 0;
    ${smartphone()} {
      display: none;
    }
  `,
  icon: css`
    margin-inline-end: 0.4em;
    display: inline;
  `,
  loading: (colorScheme: NonNullable<ButtonProps["colorScheme"]>) => css`
    inset: ${size(0)};
    background-color: ${color(colorScheme, 5)};
    border-radius: 100em;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    &[data-loading="true"] {
      display: flex;
    }
  `
};

/**
 * フォーム要素としてのボタンです。
 * @group React Components
 * @category Common Component
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  colorScheme = "primary",
  variant = "solid",
  size = "md",
  iconNode,
  type = "button",
  disabled,
  isLoading = false,
  hotkeys,
  onClick,
  className,
  children,
  ...props
}, ref) => {
  useEffect(() => {
    if (hotkeys != null) {
      mousetrap.bind(hotkeys, (event) => {
        onClick?.(event);
      });
      return () => {
        mousetrap.unbind(hotkeys);
      };
    } else {
      return undefined;
    }
  }, [hotkeys, onClick]);

  return (
    <button
      className={className}
      css={styles.root(colorScheme)}
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      ref={ref}
      data-variant={variant}
      data-loading={isLoading}
      {...props}
    >
      <div css={styles.content} data-size={size} data-hotkeys={hotkeys != null}>
        {iconNode && (
          <div css={styles.icon}>
            {iconNode}
          </div>
        )}
        {children}
      </div>
      {hotkeys != null && (
        <div css={styles.hotkey}>
          <HotkeyBadge hotkeys={hotkeys}/>
        </div>
      )}
      <div css={styles.loading(colorScheme)} data-loading={isLoading}>
        <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
      </div>
    </button>
  );
});