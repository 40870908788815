import {client} from "src/clients/client";
import {
  Job,
  JobType
} from "src/types";
import {toJob} from "src/types/conversion/job";
import {Id} from "src/types/modified/misc";
import {
  CreateJobReq,
  CreateJobRes,
  ListJobReq,
  ListJobRes
} from "src/types/raw/quizium/job_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category ジョブ
 */
export interface CopyDeckJobDataForCreate {
  fromDeckId: Id;
  groupId: Id;
}

/**
 * @group API 関数
 * @category ジョブ
 */
export const createCopyDraftDeckJob = createAsyncApi(async (data: CopyDeckJobDataForCreate): Promise<Id> => {
  const req: CreateJobReq = {type: JobType.CopyDeck, data: {copyDeck: {...data, toDeckId: ""}}};
  const res = await client.post<CreateJobRes>("/quizium/v3/job", req);
  return res.data.id;
}, "createCopyDraftDeckJob");

/**
 * @group 型
 * @category ジョブ
 */
export interface JobQuery {
  type?: JobType;
}

/**
 * @group API 関数
 * @category ジョブ
 */
export const listJobs = createAsyncApi(async (query: JobQuery): Promise<Array<Job>> => {
  const req: Partial<ListJobReq> = query;
  const res = await client.post<ListJobRes>("/quizium/v3/job/list", req);
  return res.data.jobs.map(toJob);
}, "listJobs");