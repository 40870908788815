import {client} from "src/clients/client";
import {
  Directory,
  PlayableDeck
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  CancelPlayableDeckReq,
  CancelPlayableDeckRes,
  CreatePlayableDeckReq,
  CreatePlayableDeckRes,
  GetCompetitionPlayableDeckReq,
  GetCompetitionPlayableDeckRes,
  GetPlayableDeckReq,
  GetPlayableDeckRes,
  ListDistinctPlayableDeckReq,
  ListDistinctPlayableDeckRes,
  ListDistinctPlayableNodeReq,
  ListDistinctPlayableNodeRes,
  ListPlayableDeckReq,
  ListPlayableDeckRes,
  ListPlayableNodeReq,
  ListPlayableNodeRes,
  ListPublicPlayableDeckReq,
  ListPublicPlayableDeckRes
} from "src/types/raw/quizium/deck_service";
import {createAsyncApi} from "src/utils/api";
import {DeepPartial} from "ts-essentials";


/**
 * @group 型
 * @category 配信済みデッキ
 */
export interface CreatePlayableDeckConfig {
  /**
   * 配信コメント。
   */
  comment: string;
  /**
   * 全体公開にするかどうか。
   * 全体公開にする場合は `true` とし、限定公開にする場合は `false` とします。
   */
  isPublic: boolean;
}

/**
 * 下書きデッキを配信します。
 * @param id 下書きデッキ ID
 * @param config 配信設定
 * @group API 関数
 * @category 配信済みデッキ
 */
export const createPlayableDeck = createAsyncApi(async (id: Id, config: CreatePlayableDeckConfig): Promise<Id> => {
  const req: CreatePlayableDeckReq = {draftDeckId: id, ...config};
  const res = await client.post<CreatePlayableDeckRes>("/quizium/v3/playable/create", req);
  return res.data.id;
}, "createPlayableDeck");

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const cancelPlayableDeck = createAsyncApi(async (id: Id): Promise<void> => {
  const req: CancelPlayableDeckReq = {playableDeckId: id};
  const res = await client.post<CancelPlayableDeckRes>("/quizium/v3/playable/cancel", req);
}, "cancelPlayableDeck");

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const getPlayableDeck = createAsyncApi(async (id: Id): Promise<PlayableDeck> => {
  const req: GetPlayableDeckReq = {playableDeckId: id};
  const res = await client.post<GetPlayableDeckRes>("/quizium/v3/playable/get", req);
  return res.data.playableDeck!;
}, "getPlayableDeck");

/**
 * イベント参加者の権限で配信済みデッキを取得します。
 * この関数では、`competitionId` に指定されたイベントに参加したユーザーのみが、デッキデータを取得できます。
 * デッキに対して通常の閲覧権限をもっていても、イベントに参加していなければエラーになります。
 * @group API 関数
 * @category 配信済みデッキ
 */
export const getPlayableDeckByCompetition = createAsyncApi(async (id: Id, competitionId: Id): Promise<PlayableDeck> => {
  const req: GetCompetitionPlayableDeckReq = {playableDeckId: id, competitionId};
  const res = await client.post<GetCompetitionPlayableDeckRes>("/quizium/v3/playable/competition", req);
  return res.data.deck!;
}, "getPlayableDeckByCompetition");

/**
 * @group 型
 * @category 配信済みデッキ
 */
export interface PlayableDeckQuery {
  /** */
  name?: string;
  /** */
  tag?: string;
  /** */
  originId?: Id;
  /** */
  authorId?: Id;
  /** */
  groupId?: Id;
  /**
   * 検索を始めるディレクトリの ID。
   * これを指定すると、指定されたディレクトリ以下の全ての階層が検索の対象になります。
   * このディレクトリ直下のみが検索の対象になるわけではありません。
   */
  directoryId?: Id;
  /** */
  appId?: string;
  /** */
  isCancel?: boolean;
  /** */
  includePublic?: boolean;
  /** */
  limit?: number;
  /** */
  skip?: number;
}

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listPlayableDecks = createAsyncApi(async (query: PlayableDeckQuery): Promise<[Array<PlayableDeck>, number]> => {
  const req: DeepPartial<ListPlayableDeckReq> = {query};
  const res = await client.post<ListPlayableDeckRes>("/quizium/v3/playable/list", req);
  return [res.data.playableDecks, res.data.total];
}, "listPlayableDecks");

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listDistinctPlayableDecks = createAsyncApi(async (query: PlayableDeckQuery): Promise<[Array<PlayableDeck>, number]> => {
  const req: DeepPartial<ListDistinctPlayableDeckReq> = {query};
  const res = await client.post<ListDistinctPlayableDeckRes>("/quizium/v3/playable/list/distinct", req);
  return [res.data.playableDecks, res.data.total];
}, "listDistinctPlayableNodes");

/**
 * @group 型
 * @category 配信済みデッキ
 */
export interface PublicPlayableDeckQuery {
  /** */
  name?: string;
  /** */
  tag?: string;
  /** */
  originId?: Id;
  /** */
  authorId?: Id;
  /** */
  groupId?: Id;
  /**
   * 検索を始めるディレクトリの ID。
   * これを指定すると、指定されたディレクトリ以下の全ての階層が検索の対象になります。
   * このディレクトリ直下のみが検索の対象になるわけではありません。
   */
  directoryId?: Id;
  /** */
  appId?: string;
  /** */
  limit?: number;
  /** */
  skip?: number;
}

/**
 * 全体公開の配信済みデッキからのみ検索します。
 * @param query 検索クエリ
 * @returns ヒットしたデッキの配列と累計ヒット数
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listPublicPlayableDecks = createAsyncApi(async (query: PublicPlayableDeckQuery): Promise<[Array<PlayableDeck>, number]> => {
  const req: DeepPartial<ListPublicPlayableDeckReq> = {query};
  const res = await client.post<ListPublicPlayableDeckRes>("/quizium/v3/playable/list/public", req);
  return [res.data.deck, res.data.total];
}, "listPublicPlayableDecks");

/**
 * @group 型
 * @category 配信済みデッキ
 */
export interface PlayableNodeQuery {
  /** */
  groupId?: Id;
  /**
   * 検索の対象とするディレクトリの ID。
   * 指定されたディレクトリ直下のみが検索の対象になります。
   * ルートディレクトリを指定する場合は、`""` か `"root"` を入れてください。
   */
  directoryId: Id | "" | "root";
  /**
   * 検索結果に含めるデッキの個数。
   * この値に関わらず、ディレクトリは常に全て返されます。
   */
  limit?: number;
  /**
   * 検索結果から飛ばすデッキの数。
   * この値に関わらず、ディレクトリは常に全て返されます。
   */
  skip?: number;
};

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listPlayableNodes = createAsyncApi(async (query: PlayableNodeQuery): Promise<{directories: Array<Directory>, decks: Array<PlayableDeck>}> => {
  const req: Partial<ListPlayableNodeReq> = query;
  const res = await client.post<ListPlayableNodeRes>("/quizium/v3/playable/node", req);
  return {directories: res.data.directories, decks: res.data.playableDecks};
}, "listPlayableNodes");

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listDistinctPlayableNodes = createAsyncApi(async (query: PlayableNodeQuery): Promise<[{directories: Array<Directory>, decks: Array<PlayableDeck>}, number]> => {
  const req: DeepPartial<ListDistinctPlayableNodeReq> = query;
  const res = await client.post<ListDistinctPlayableNodeRes>("/quizium/v3/playable/list/distinct", req);
  return [{directories: res.data.directories, decks: res.data.playableDecks}, res.data.total];
}, "listDistinctPlayableNodes");

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listPlayableNodesOrDecks = createAsyncApi(async (nodeQuery: PlayableNodeQuery, deckQuery: PlayableDeckQuery, showNode: boolean): Promise<{directories: Array<Directory>, decks: Array<PlayableDeck>}> => {
  if (showNode) {
    const nodes = await listPlayableNodes(nodeQuery);
    return nodes;
  } else {
    const [decks] = await listPlayableDecks(deckQuery);
    const nodes = {directories: [], decks};
    return nodes;
  }
}, "listPlayableNodesOrDecks");

/**
 * @group API 関数
 * @category 配信済みデッキ
 */
export const listDistinctPlayableNodesOrDecks = createAsyncApi(async (nodeQuery: PlayableNodeQuery, deckQuery: PlayableDeckQuery, showNode: boolean): Promise<{directories: Array<Directory>, decks: Array<PlayableDeck>}> => {
  if (showNode) {
    const [nodes] = await listDistinctPlayableNodes(nodeQuery);
    return nodes;
  } else {
    const [decks] = await listDistinctPlayableDecks(deckQuery);
    const nodes = {directories: [], decks};
    return nodes;
  }
}, "listDistinctPlayableNodesOrDecks");