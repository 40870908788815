/**
 * MongoDB で用いられる 12 バイトの ID 文字列。
 * @group 型
 * @category その他
 */
export type Id = string;

/**
 * 秒単位の UNIX 時刻。
 * 日時の操作に Day.js を用いている場合は、`date.unix().toString()` の形で取得できます。
 * @group 型
 * @category その他
 */
export type DateString = string;

/* -------------------------------------------------------------------------- */

/** @ignore */
export namespace Id {
  export const EMPTY = "0" as const;
  export const DEFAULT = "" as const;
};

/** @ignore */
export namespace DateString {
  export const EMPTY = "0" as const;
  export const DEFAULT = "0" as const;
};