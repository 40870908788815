import {client} from "src/clients/client";
import {
  Directory
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  CreateDirectoryReq,
  CreateDirectoryRes,
  DeleteDirectoryReq,
  DeleteDirectoryRes,
  GetDirectoryReq,
  GetDirectoryRes,
  ListAncestorDirectoryByDeckReq,
  ListAncestorDirectoryByDeckRes,
  ListAncestorDirectoryReq,
  ListAncestorDirectoryRes,
  UpdateDirectoryReq,
  UpdateDirectoryRes
} from "src/types/raw/quizium/deck_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category ディレクトリ
 */
export interface DirectoryForCreate {
  name: string;
  parentId: Id;
  groupId: Id;
};

/**
 * @group API 関数
 * @category ディレクトリ
 */
export const createDirectory = createAsyncApi(async (directory: DirectoryForCreate): Promise<Id> => {
  const req: CreateDirectoryReq = {directory};
  const res = await client.post<CreateDirectoryRes>("/quizium/v3/directory", req);
  return res.data.id;
}, "createDirectory");

/**
 * @group API 関数
 * @category ディレクトリ
 */
export const updateDirectory = createAsyncApi(async (directory: Directory): Promise<void> => {
  const req: UpdateDirectoryReq = {directory};
  const res = await client.post<UpdateDirectoryRes>("/quizium/v3/directory/update", req);
}, "updateDirectory");

/**
 * ディレクトリを削除します。
 * ディレクトリ内が空 (直下にディレクトリとデッキがとも存在しない) である必要があります。
 * @group API 関数
 * @category ディレクトリ
 */
export const deleteDirectory = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteDirectoryReq = {directoryId: id};
  const res = await client.post<DeleteDirectoryRes>("/quizium/v3/directory/delete", req);
}, "deleteDirectory");

/**
 * ID からディレクトリを取得します。
 * ID に `""` か `"root"` が指定された場合は、空のディレクトリデータ (`id` が `""` のデータ) を返します。
 * @group API 関数
 * @category ディレクトリ
 */
export const getDirectory = createAsyncApi(async (id: Id | "" | "root"): Promise<Directory> => {
  if (id !== "" && id !== "root") {
    const req: GetDirectoryReq = {directoryId: id};
    const res = await client.post<GetDirectoryRes>("/quizium/v3/directory/get", req);
    return res.data.directory!;
  } else {
    return {...Directory.EMPTY, id: ""};
  }
}, "getDirectory");

/**
 * 指定されたディレクトリの先祖となるディレクトリを全て取得します。
 * 返される配列は、指定されたディレクトリから近い順に先祖ディレクトリが格納され、さらに指定されたディレクトリ自身を含みます。
 * 自分自身を含むという点で、`Directory.path` とは仕様が異なります。
 *
 * ディレクトリ構造は最大で 6 階層までしか許されていないので、返される配列の長さは最大で 6 です。
 *
 * ディレクトリ ID には `""` か `"root"` も指定でき、その場合は空の配列を返します。
 * @param id ディレクトリ ID
 * @returns 先祖ディレクトリの配列
 * @group API 関数
 * @category ディレクトリ
 */
export const listAncestorDirectories = createAsyncApi(async (id: Id | "" | "root"): Promise<Array<Directory>> => {
  if (id !== "" && id !== "root") {
    const req: ListAncestorDirectoryReq = {directoryId: id};
    const res = await client.post<ListAncestorDirectoryRes>("/quizium/v3/directory/ancestor", req);
    return res.data.directories;
  } else {
    return [];
  }
}, "listAncestorDirectories");

/**
 * 指定されたデッキを直接含むディレクトリから始め、その先祖となるディレクトリを全て取得します。
 * 返される配列は、指定されたデッキを直接含むディレクトリを先頭の要素とし、そこから親ディレクトリが順に格納されています。
 *
 * ディレクトリ構造は最大で 6 階層までしか許されていないので、返される配列の長さは最大で 6 です。
 * @param id 下書きデッキ ID
 * @returns 先祖ディレクトリの配列
 * @group API 関数
 * @category ディレクトリ
 */
export const listAncestorDirectoriesByDeck = createAsyncApi(async (id: Id): Promise<Array<Directory>> => {
  const req: ListAncestorDirectoryByDeckReq = {draftDeckId: id};
  const res = await client.post<ListAncestorDirectoryByDeckRes>("/quizium/v3/directory/ancestor/deck", req);
  return res.data.directories;
}, "listAncestorDirectoriesByDeck");
