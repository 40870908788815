import axios from "axios";
import {QuiziumError} from "src/types";


/**
 * 与えられたエラーが、何らかの理由でデータにアクセスできなかったことが原因のものかどうかを判定します。
 * エラーの原因が以下であった場合に `true` を返します。
 * - アクセスしようとしたデータが存在しない
 * - アクセスしようとしたデータを閲覧する権限がない
 */
export const isInaccessibleError = (error: unknown): boolean => {
  if (error instanceof QuiziumError) {
    const cause = error.cause;
    return cause != null && axios.isAxiosError(cause) && (cause.response?.status === 404 || cause.response?.status === 403 || cause.response?.status === 401);
  } else {
    return false;
  }
};

/**
 * 与えられたエラーが、データが存在しなかったことが原因のものかどうかを判定します。
 */
export const isNotFoundError = (error: unknown): boolean => {
  if (error instanceof QuiziumError) {
    const cause = error.cause;
    return cause != null && axios.isAxiosError(cause) && cause.response?.status === 404;
  } else {
    return false;
  }
};