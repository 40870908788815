import {Job, JobType} from "src/types";
import * as raw from "../raw/quizium/job";


export const toJob = (raw: raw.Job): Job => {
  if (raw.type === JobType.CopyDeck) {
    return {...raw, type: raw.type, data: raw.data!.copyDeck!};
  } else {
    throw new Error("cannot happen");
  }
};