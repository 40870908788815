import {client} from "src/clients/client";
import {getGroupsByIds} from "src/repositories/library/group";
import {getUsersByIds} from "src/repositories/library/user";
import {
  Group,
  GroupMemberType,
  GroupUser,
  Id,
  PublicUser
} from "src/types";
import {
  CreateGroupUserReq,
  CreateGroupUserRes,
  DeleteGroupUserReq,
  DeleteGroupUserRes,
  ListGroupUserReq,
  ListGroupUserRes,
  UpdateGroupUserReq,
  UpdateGroupUserRes
} from "src/types/raw/quizium/user_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API 関数
 * @category グループメンバー
 */
export const createGroupUser = createAsyncApi(async (groupHandle: string, userHandle: string, memberType: GroupMemberType): Promise<void> => {
  const req: CreateGroupUserReq = {groupHandle, userHandle, memberType};
  const res = await client.post<CreateGroupUserRes>("/quizium/v3/groupuser", req);
}, "createGroupUser");

/**
 * @group API 関数
 * @category グループメンバー
 */
export const updateGroupUser = createAsyncApi(async (groupUser: GroupUser): Promise<void> => {
  const req: UpdateGroupUserReq = {groupUser};
  const res = await client.post<UpdateGroupUserRes>("/quizium/v3/groupuser/update", req);
}, "updateGroupUser");

/**
 * グループメンバーの紐づけを削除します。
 * 引数に指定する ID は、グループやメンバーの ID ではなく、紐づけオブジェクト (`DeckMember` オブジェクト) の ID です。
 * @param id 紐づけオブジェクト ID
 * @group API 関数
 * @category グループメンバー
 */
export const deleteGroupUser = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteGroupUserReq = {id};
  const res = await client.post<DeleteGroupUserRes>("/quizium/v3/groupuser/delete");
}, "deleteGroupUser");

/**
 * @group 型
 * @category グループメンバー
 */
export interface GroupUserQuery {
  userHandle?: string;
  groupHandle?: string;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category グループメンバー
 */
export const listGroupUsers = createAsyncApi(async (query: GroupUserQuery): Promise<Array<GroupUser>> => {
  const req: Partial<ListGroupUserReq> = query;
  const res = await client.post<ListGroupUserRes>("/quizium/v3/groupuser/list", req);
  return res.data.groupUsers;
}, "listGroupUsers");

/**
 * ユーザーのグループ内権限を取得します。
 * 指定されたユーザーが指定されたグループにそもそも属していない場合は `null` を返します。
 *
 * 引数の `userHandle` には `null` を渡すことができ、その場合この関数は常に `null` を返します。
 * @group API 関数
 * @category グループメンバー
 */
export const getMemberTypeInGroup = createAsyncApi(async (userHandle: string | null, groupHandle: string): Promise<GroupMemberType | null> => {
  if (userHandle != null) {
    const groupUsers = await listGroupUsers({userHandle, groupHandle});
    if (groupUsers.length > 0) {
      return groupUsers[0].memberType;
    } else {
      return null;
    }
  } else {
    return null;
  }
}, "getMemberTypeInGroup");

/**
 * @group 型
 * @category グループメンバー
 * @interface
 */
export type PublicUserInGroup = PublicUser & {groupUser: GroupUser};

/**
 * グループに所属している全ユーザーを取得します。
 * @group API 関数
 * @category グループメンバー
 */
export const listUsersInGroup = createAsyncApi(async (handle: string): Promise<Array<PublicUserInGroup>> => {
  const groupUsers = await listGroupUsers({groupHandle: handle});
  const users = await getUsersByIds(groupUsers.map((groupUser) => groupUser.userId));
  const augedUsers = groupUsers.flatMap((groupUser) => {
    const user = users.find((candUser) => candUser.id === groupUser.userId);
    if (user != null) {
      return [{...user, groupUser}];
    } else {
      return [];
    }
  });
  return augedUsers;
}, "listUsersInGroup");

/**
 * @group 型
 * @category グループメンバー
 * @interface
 */
export type GroupOfUser = Group & {groupUser: GroupUser};

/**
 * ユーザーが所属している全グループを取得します。
 * 返されるグループには個人グループを含みます。
 * @group API 関数
 * @category グループメンバー
 */
export const listGroupsOfUser = createAsyncApi(async (handle: string): Promise<Array<GroupOfUser>> => {
  const groupUsers = await listGroupUsers({userHandle: handle});
  const groups = await getGroupsByIds(groupUsers.map((groupUser) => groupUser.groupId));
  const augedGroups = groupUsers.flatMap((groupUser) => {
    const group = groups.find((candGroup) => candGroup.id === groupUser.groupId);
    if (group != null) {
      return [{...group, groupUser}];
    } else {
      return [];
    }
  });
  return augedGroups;
}, "listGroupsOfUser");
