import {client} from "src/clients/client";
import {AggregationFormat, AggregationTagSpec, AggregationUnit} from "src/types";
import {
  AggregateMonthlyUserEncounterRes,
  CreateAggregationSettingRes,
  ListAggregationSettingReq,
  ListAggregationSettingRes
} from "src/types/raw/admin/stats_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API 関数
 * @category 統計
 */
export const aggregateMonthlyResult = createAsyncApi(async (): Promise<void> => {
  const res = await client.get<AggregateMonthlyUserEncounterRes>("/admin/v2/aggregate/user/monthly");
}, "aggregateMonthlyResult");

/**
 * @group 型
 * @category 統計
 */
export interface AggregationTagSpecForCreate {
  tag: string;
  unit: AggregationUnit;
  fractionDigits: number;
  format: AggregationFormat;
  order: number;
}

/**
 * @group API 関数
 * @category 統計
 */
export const createAggregationTagSpec = createAsyncApi(async (spec: AggregationTagSpecForCreate): Promise<string> => {
  const res = await client.post<CreateAggregationSettingRes>("/admin/v2/aggregation", spec);
  return res.data.id;
}, "createAggregationTagSpec");

/**
 * @group 型
 * @category 統計
 */
export interface AggregationTagSpecQuery {
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category 統計
 */
export const listAggregationTagSpecs = createAsyncApi(async (query: AggregationTagSpecQuery): Promise<Array<AggregationTagSpec>> => {
  const params: Partial<ListAggregationSettingReq> = query;
  const res = await client.get<ListAggregationSettingRes>("/admin/v2/aggregation", {params});
  return res.data.aggregationSettings as any;
}, "listAggregationTagSpecs");
