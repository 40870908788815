import {client} from "src/clients/client";
import {getPlayableDeck, getPlayableDeckByCompetition} from "src/repositories/library/playableDeck";
import {
  CensoredQuiz,
  Choice,
  DeletedQuiz,
  Media,
  Quiz,
  QuizExtension
} from "src/types";
import {toQuizOrCensoredQuiz} from "src/types/conversion/quiz";
import {DateString, Id} from "src/types/modified/misc";
import {
  CreateQuizReq,
  CreateQuizRes,
  DeleteQuizReq,
  DeleteQuizRes,
  GetCompetitionQuizReq,
  GetCompetitionQuizRes,
  GetQuizByOriginReq,
  GetQuizByOriginRes,
  GetQuizReq,
  GetQuizRes,
  ListPublicQuizReq,
  ListPublicQuizRes,
  ListQuizHistoryReq,
  ListQuizHistoryRes,
  ListQuizReq,
  ListQuizRes,
  MultiCreateQuizReq,
  MultiCreateQuizRes,
  MultiGetCompetitionQuizReq,
  MultiGetCompetitionQuizRes,
  MultiGetQuizByOriginReq,
  MultiGetQuizByOriginRes,
  MultiGetQuizReq,
  MultiGetQuizRes,
  MultiUpdateQuizReq,
  MultiUpdateQuizRes,
  UpdateQuizReq,
  UpdateQuizRes
} from "src/types/raw/quizium/quiz_service";
import {createAsyncApi} from "src/utils/api";
import {DeepNonNullable, DeepPartial} from "ts-essentials";


/**
 * @group 型
 * @category クイズ
 */
export interface QuizForCreate {
  question: Media;
  answer: Array<Choice>;
  remark: Media;
  sources: Array<string>;
  genreId: Id;
  tags: Array<string>;
  quizExtension: QuizExtension;
  groupId: Id;
  isPrivate: boolean;
  appIds: Array<string>;
  approveAt: DateString;
  writeAt: DateString;
}

/**
 * @group API 関数
 * @category クイズ
 */
export const createQuiz = createAsyncApi(async (quiz: QuizForCreate): Promise<Id> => {
  const req: CreateQuizReq = {quiz};
  const res = await client.post<CreateQuizRes>("/quizium/v3/quiz", req);
  return res.data.id;
}, "createQuiz");

/**
 * 複数個のクイズを一括で作成します。
 *
 * この処理には時間がかかるため、この関数だけタイムアウトを 60 秒に設定してあります (他の関数は 5 秒)。
 * @group API 関数
 * @category クイズ
 */
export const createQuizzes = createAsyncApi(async (quizzes: Array<QuizForCreate>, groupId: Id): Promise<Array<Id>> => {
  const req: MultiCreateQuizReq = {quizzes, groupId};
  const res = await client.post<MultiCreateQuizRes>("/quizium/v3/quiz/multi", req, {timeout: 60000});
  return res.data.ids;
}, "createQuizzes");

/**
 * @group 型
 * @category クイズ
 */
export interface QuizForUpdate {
  /**
   * 更新するクイズの ID。
   * 最新バージョンの ID を指定してください。
   */
  id: Id;
  /** */
  question: Media;
  /** */
  answer: Array<Choice>;
  /** */
  remark: Media;
  /** */
  sources: Array<string>;
  /** */
  genreId: Id;
  /** */
  tags: Array<string>;
  /** */
  quizExtension: QuizExtension;
  /** */
  isPrivate: boolean;
  /** */
  appIds: Array<string>;
  /** */
  approveAt: DateString;
  /** */
  writeAt: DateString;
}

/**
 * クイズを更新します。
 * 実際には、更新前のクイズはデータベース上でそのままにされ、更新後のクイズが新しくデータベースに登録されます。
 * 新しく登録されたクイズの ID を返します。
 * @param quiz クイズ
 * @returns 新しく作られたクイズ ID
 * @group API 関数
 * @category クイズ
 */
export const updateQuiz = createAsyncApi(async (quiz: QuizForUpdate): Promise<Id> => {
  const req: UpdateQuizReq = {quiz};
  const res = await client.post<UpdateQuizRes>("/quizium/v3/quiz/update", req);
  return res.data.id;
}, "updateQuiz");

export const updateQuizzes = createAsyncApi(async (quizzes: Array<QuizForUpdate>): Promise<Array<Id>> => {
  const req: MultiUpdateQuizReq = {quizzes};
  const res = await client.post<MultiUpdateQuizRes>("/quizium/v3/quiz/MultiUpdate", req);
  return res.data.ids;
}, "updateQuizzes");

/**
 * @group API 関数
 * @category クイズ
 */
export const deleteQuiz = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteQuizReq = {id};
  const res = await client.post<DeleteQuizRes>("/quizium/v3/quiz/delete", req);
}, "deleteQuiz");

/**
 * @group API 関数
 * @category クイズ
 */
export const getQuiz = createAsyncApi(async (id: Id): Promise<Quiz | CensoredQuiz> => {
  const req: GetQuizReq = {id};
  const res = await client.post<DeepNonNullable<GetQuizRes>>("/quizium/v3/quiz/get", req);
  return toQuizOrCensoredQuiz(res.data.quiz);
}, "getQuiz");

/**
 * 初期バージョンのクイズの ID から最新バージョンのクイズを取得します。
 * @param originId 初期バージョンのクイズ ID
 * @returns 最新バージョンのクイズ
 * @group API 関数
 * @category クイズ
 */
export const getQuizByOriginId = createAsyncApi(async (originId: Id): Promise<Quiz | CensoredQuiz> => {
  const req: GetQuizByOriginReq = {originQuizId: originId};
  const res = await client.post<DeepNonNullable<GetQuizByOriginRes>>("/quizium/v3/quiz/origin", req);
  return toQuizOrCensoredQuiz(res.data.quiz);
}, "getQuizByOriginId");

/**
 * イベント参加者の権限でクイズを取得します。
 * この関数では、`competitionId` に指定されたイベントに参加したユーザーのみが、クイズデータを取得できます。
 * クイズに対して通常の閲覧権限をもっていても、イベントに参加していなければエラーになります。
 * @group API 関数
 * @category クイズ
 */
export const getQuizByCompetition = createAsyncApi(async (id: Id, competitionId: Id): Promise<Quiz | CensoredQuiz> => {
  const req: GetCompetitionQuizReq = {quizId: id, competitionId};
  const res = await client.post<DeepNonNullable<GetCompetitionQuizRes>>("/quizium/v3/quiz/competition", req);
  return toQuizOrCensoredQuiz(res.data.quiz);
}, "getQuizByCompetition");

/**
 * ID からクイズを一括で取得します。
 * 指定された ID に存在しないものが含まれていた場合、それは単に無視されエラーにはなりません。
 * そのため、返り値の配列の長さは、`ids` に指定された配列の長さより小さい場合があります。
 * @group API 関数
 * @category クイズ
 */
export const getQuizzes = createAsyncApi(async (ids: Array<Id>): Promise<Array<Quiz | CensoredQuiz>> => {
  if (ids.length > 0) {
    const req: MultiGetQuizReq = {ids};
    const res = await client.post<DeepNonNullable<MultiGetQuizRes>>("/quizium/v3/quiz/MultiGet", req);
    return res.data.quizzes.map(toQuizOrCensoredQuiz);
  } else {
    return [];
  }
}, "getQuizzes");

/**
 * 初期バージョンの ID から最新のバージョンのクイズを一括で取得します。
 * 指定された ID に存在しないものが含まれていた場合、それは単に無視されエラーにはなりません。
 * そのため、返り値の配列の長さは、`ids` に指定された配列の長さより小さい場合があります。
 * @group API 関数
 * @category クイズ
 */
export const getQuizzesByOriginIds = createAsyncApi(async (ids: Array<Id>): Promise<Array<Quiz | CensoredQuiz>> => {
  if (ids.length > 0) {
    const req: MultiGetQuizByOriginReq = {originQuizIds: ids};
    const res = await client.post<DeepNonNullable<MultiGetQuizByOriginRes>>("/quizium/v3/quiz/origin/MultiGet", req);
    return res.data.quizzes.map(toQuizOrCensoredQuiz);
  } else {
    return [];
  }
}, "getQuizzesByOriginIds");

/**
 * ID からクイズを一括で取得します。
 * `getQuizzes` 関数とは異なり、指定された ID に存在しないものが含まれていた場合、`DeletedQuiz` 型のデータで埋められます。
 * そのため、返り値の配列の長さは、`ids` に指定された配列の長さと同じになります。
 * @group API 関数
 * @category クイズ
 */
export const getFullQuizzes = createAsyncApi(async (ids: Array<Id>): Promise<Array<Quiz | CensoredQuiz | DeletedQuiz>> => {
  if (ids.length > 0) {
    const req: MultiGetQuizReq = {ids};
    const res = await client.post<DeepNonNullable<MultiGetQuizRes>>("/quizium/v3/quiz/MultiGet", req);
    const quizMap = new Map(res.data.quizzes.map((quiz) => [quiz.id, quiz]));
    const quizzes = ids.map((id) => {
      const quiz = quizMap.get(id);
      if (quiz != null) {
        return toQuizOrCensoredQuiz(quiz);
      } else {
        return {id, originId: id, authorId: ""} as const;
      }
    });
    return quizzes;
  } else {
    return [];
  }
}, "getFullQuizzes");

/**
 * 初期バージョンの ID から最新のバージョンのクイズを一括で取得します。
 * `getQuizzes` 関数とは異なり、指定された ID に存在しないものが含まれていた場合、`DeletedQuiz` 型のデータで埋められます。
 * そのため、返り値の配列の長さは、`ids` に指定された配列の長さと同じになります。
 * @group API 関数
 * @category クイズ
 */
export const getFullQuizzesByOriginIds = createAsyncApi(async (originIds: Array<Id>): Promise<Array<Quiz | CensoredQuiz | DeletedQuiz>> => {
  if (originIds.length > 0) {
    const req: MultiGetQuizByOriginReq = {originQuizIds: originIds};
    const res = await client.post<DeepNonNullable<MultiGetQuizByOriginRes>>("/quizium/v3/quiz/origin/MultiGet", req);
    const quizMap = new Map(res.data.quizzes.map((quiz) => [quiz.originId, quiz]));
    const quizzes = originIds.map((originId) => {
      const quiz = quizMap.get(originId);
      if (quiz != null) {
        return toQuizOrCensoredQuiz(quiz);
      } else {
        return {id: originId, originId, authorId: ""} as const;
      }
    });
    return quizzes;
  } else {
    return [];
  }
}, "getFullQuizzesByOriginIds");

/**
 * @group API 関数
 * @category クイズ
 */
export const getQuizzesByCompetition = createAsyncApi(async (ids: Array<Id>, competitionId: Id): Promise<Array<Quiz | CensoredQuiz>> => {
  if (ids.length > 0) {
    const req: MultiGetCompetitionQuizReq = {quizIds: ids, competitionId};
    const res = await client.post<DeepNonNullable<MultiGetCompetitionQuizRes>>("/quizium/v3/quiz/competition/MultiGet", req);
    return res.data.quizzes.map(toQuizOrCensoredQuiz);
  } else {
    return [];
  }
}, "getQuizzesByCompetition");

/**
 * @group 型
 * @category クイズ
 * @interface
 */
export type QuizWithDeckId = Quiz & {deckId: string};

/**
 * @group 型
 * @category クイズ
 * @interface
 */
export type CensoredQuizWithDeckId = CensoredQuiz & {deckId: string};

/**
 * @group API 関数
 * @category クイズ
 */
export const getQuizzesInPlayableDecks = createAsyncApi(async (deckIds: Array<Id>): Promise<Array<QuizWithDeckId | CensoredQuizWithDeckId>> => {
  const decks = await Promise.all(deckIds.map((deckId) => getPlayableDeck(deckId)));
  const quizzes = await Promise.all(decks.map(async (deck) => {
    const quizzes = await getQuizzes(deck.quizIds);
    const augedQuizzes = quizzes.map((quiz) => ({...quiz, deckId: deck.id}));
    return augedQuizzes;
  })).then((quizzes) => quizzes.flat());
  return quizzes;
}, "getQuizzesInPlayableDecks");

/**
 * @group API 関数
 * @category クイズ
 */
export const getQuizzesInPlayableDecksByCompetition = createAsyncApi(async (deckIds: Array<Id>, competitionId: Id): Promise<Array<QuizWithDeckId | CensoredQuizWithDeckId>> => {
  const decks = await Promise.all(deckIds.map((deckId) => getPlayableDeckByCompetition(deckId, competitionId)));
  const quizzes = await Promise.all(decks.map(async (deck) => {
    const quizzes = await getQuizzesByCompetition(deck.quizIds, competitionId);
    const augedQuizzes = quizzes.map((quiz) => ({...quiz, deckId: deck.id}));
    return augedQuizzes;
  })).then((quizzes) => quizzes.flat());
  return quizzes;
}, "getQuizzesInPlayableDecksByCompetition");

/**
 * @group 型
 * @category クイズ
 */
export interface QuizQuery {
  genreId?: string;
  tag?: string;
  authorId?: Id;
  groupId?: Id;
  includePublic?: boolean;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category クイズ
 */
export const listQuizzes = createAsyncApi(async (query: QuizQuery): Promise<[Array<Quiz | CensoredQuiz>, number]> => {
  const req: DeepPartial<ListQuizReq> = {query};
  const res = await client.post<DeepNonNullable<ListQuizRes>>("/quizium/v3/quiz/list", req);
  return [res.data.quizzes.map(toQuizOrCensoredQuiz), res.data.total];
}, "listQuizzes");

/**
 * @group 型
 * @category クイズ
 */
export interface PublicQuizQuery {
  genreId?: string;
  tag?: string;
  authorId?: Id;
  groupId?: Id;
  limit?: number;
  skip?: number;
}

/**
 * 全体公開のクイズからのみ検索します。
 * @param query 検索クエリ
 * @returns ヒットしたクイズの配列と累計ヒット数
 * @group API 関数
 * @category 配信済みデッキ
 * @group API 関数
 * @category クイズ
 */
export const listPublicQuizzes = createAsyncApi(async (query: PublicQuizQuery): Promise<[Array<Quiz | CensoredQuiz>, number]> => {
  const req: DeepPartial<ListPublicQuizReq> = {query};
  const res = await client.post<DeepNonNullable<ListPublicQuizRes>>("/quizium/v3/quiz/public/list", req);
  return [res.data.quizzes.map(toQuizOrCensoredQuiz), res.data.total];
}, "listPublicQuizzes");

/**
 * @group 型
 * @category クイズ
 */
export interface OldQuizQuery {
  originId: Id;
  limit?: number;
  skip?: number;
}

/**
 * クイズの過去のバージョンを全て返します。
 * @param originId 初期バージョンのクイズ ID
 * @returns 過去のバージョンのクイズの配列
 * @group API 関数
 * @category クイズ
 */
export const listOldQuizzes = createAsyncApi(async (query: OldQuizQuery): Promise<Array<Quiz | CensoredQuiz>> => {
  const req: Partial<ListQuizHistoryReq> = {originQuizId: query.originId, ...query};
  const res = await client.post<DeepNonNullable<ListQuizHistoryRes>>("/quizium/v3/quiz/history", req);
  return res.data.history.map(toQuizOrCensoredQuiz);
}, "listOldQuizzes");
