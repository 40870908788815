import {DateString, Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category ジョブ
 */
export const JobType = {
  /**
   * デッキを他のグループにコピーするジョブ。
   */
  CopyDeck: "JobTypeCopyDeck",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type JobType = typeof JobType[keyof typeof JobType];

/**
 * @group 列挙型
 * @category ジョブ
 */
export const JobStatus = {
  Waiting: "JobStatusWaiting",
  Executing: "JobStatusExecuting",
  Finished: "JobStatusFinished",
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type JobStatus = typeof JobStatus[keyof typeof JobStatus];

/**
 * @group 列挙型
 * @category ジョブ
 */
export const JobResultType = {
  Success: "ResultTypeSuccess",
  Error: "ResultTypeError",
  Failure: "ResultTypeFailure",
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type JobResultType = typeof JobResultType[keyof typeof JobResultType];

/**
 * @group 型
 * @category ジョブ
 */
export interface JobBase {
  /**
   * ID。
   */
  id: Id;
  /** */
  message: Array<string>;
  /** */
  status: JobStatus;
  /** */
  resultType: JobResultType;
  /**
   * ジョブを実行したユーザーの ID。
   */
  userId: Id;
  /** */
  startAt: DateString;
  /** */
  updateAt: DateString;
  /** */
  createAt: DateString;
}

/**
 * @group 型
 * @category ジョブ
 */
export type Job = ({
  type: typeof JobType.CopyDeck,
  data: CopyDeckJobData
}) & JobBase;

/**
 * @group 型
 * @category ジョブ
 */
export interface CopyDeckJobData { // CopyDeck
  fromDeckId: Id;
  toDeckId: Id;
  groupId: Id;
}
