/**
 * アプリ ID の一覧。
 * `APP_IDS.(アプリハンドル)` の形で ID が取得できます。
 *
 * アプリ ID やアプリハンドルについては [Notion のドキュメント](https://www.notion.so/baton8/c41b6a3f18eb4475b8712cdb964d67ca)を参照してください。
 */
export const APP_IDS = {
  /**
   * Quizium Library。
   */
  library: "official.library",
  /**
   * QROUD 全般。
   */
  qroud: "official.qroud",
  /**
   * QROUD / 得点山分け択一クイズ。
   */
  qroudSharing: "official.qroud.sharing",
  /**
   * QROUD / スピード得点択一クイズ。
   */
  qroudSpeed: "official.qroud.speed",
  /**
   * QROUD / 一発アウト脱落択一クイズ。
   */
  qroudDropout: "official.qroud.dropout",
  /**
   * QROUD / じわじわ脱落択一クイズ。
   */
  qroudLife: "official.qroud.life",
  /**
   * QROUD / 択一それ正解。
   */
  qroudVote: "official.qroud.vote",
  /**
   * QROUD / 近似値クイズ。
   */
  qroudApprox: "official.qroud.approx",
  /**
   * QROUD / みんなでそれ正解。
   */
  qroudDiscussion: "official.qroud.discussion",
  /**
   * Newmonic。
   */
  newmonic: "official.newmonic",
  /** */
  typing: "official.typing",
  /**
   * Quizium Drill。
   */
  paperTest: "official.paper-test",
  /**
   * Quizium Pitcher。
   */
  pitcher: "official.pitcher"
} as const;

const APP_HANDLES = Object.fromEntries(Object.entries(APP_IDS).map(([handle, id]) => [id, handle]));

/**
 * アプリ ID からアプリハンドルを取得します。
 * 指定されたアプリ ID のアプリが存在しない場合は、`undefined` を返します。
 * @param id アプリ ID
 * @returns アプリハンドル
 */
export const getAppHandle = (id: string): string | undefined => {
  return APP_HANDLES[id];
};
