import axios, {Axios} from "axios";
import applyConverters from "axios-case-converter";
import qs from "qs";
import {API_URLS} from "src/admin";
import {auth} from "src/clients/firebase";
import {writeLogCollapsed} from "src/modules";
import {TokenManager} from "./tokenManager";


export const client = createClient();

function createClient(): Axios {
  const instance = axios.create({
    baseURL: API_URLS.library,
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: "repeat"});
    },
    timeout: 5000
  });

  instance.interceptors.request.use(async (config) => {
    const tokenResult = await auth.currentUser?.getIdTokenResult();
    const token = tokenResult?.token;
    writeLogCollapsed("Axios", "token", tokenResult);
    if (token != null) {
      TokenManager.instance.set(token);
      const headers = config.headers ?? {};
      headers["Authorization"] = `Bearer ${token}`;
      config.headers = headers;
    }
    return config;
  });
  return applyConverters(instance);
}