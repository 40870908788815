import {CensoredQuiz, DeletedQuiz, Quiz} from "src/types";


export const isExistingQuiz = (quiz: Quiz | DeletedQuiz | CensoredQuiz): quiz is Quiz => {
  return "question" in quiz;
};

export const isCensoredQuiz = (quiz: Quiz | DeletedQuiz | CensoredQuiz): quiz is CensoredQuiz => {
  return "censorshipReason" in quiz;
};

export const isDeletedQuiz = (quiz: Quiz | DeletedQuiz | CensoredQuiz): quiz is DeletedQuiz => {
  return !("question" in quiz) && !("censorshipReason" in quiz);
};
