import {QueryClient} from "react-query";
import {isInaccessibleError} from "src/modules";


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (isInaccessibleError(error)) {
          return false;
        } else if (failureCount >= 4) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
});