import {client} from "src/clients/client";
import {
  GetQuizGenreReq,
  GetQuizGenreRes,
  GetWrongAnswersListReq,
  GetWrongAnswersListRes
} from "src/types/raw/quizium/quiz_service";
import {createAsyncApi} from "src/utils/api";


/**
 * 与えられた問題に対して、誤答としてありそうな選択肢を生成します。
 *
 * 一般的に OpenAI の呼び出しには時間がかかるため、この関数だけタイムアウトを 10 秒に設定してあります (他の関数は 5 秒)。
 * @param question 問題文
 * @param correctChoice 正答選択肢
 * @param limit 生成する誤答選択肢の数
 * @returns 誤答選択肢
 * @group API 関数
 * @category OpenAI
 */
export const getIncorrectChoices = createAsyncApi(async (question: string, correctChoice: string, limit: number): Promise<Array<string>> => {
  const req: GetWrongAnswersListReq = {question, answer: correctChoice, limit};
  const res = await client.post<GetWrongAnswersListRes>("/quizium/v3/quiz/openai/wrong_answers", req, {timeout: 10000});
  return res.data.wrongAnswers;
}, "getIncorrectChoices");

/**
 * 与えられた問題に対して、そのジャンルを自動判定して返します。
 *
 * 一般的に OpenAI の呼び出しには時間がかかるため、この関数だけタイムアウトを 10 秒に設定してあります (他の関数は 5 秒)。
 * @param question 問題文
 * @param correctChoice 正答選択肢
 * @returns ジャンル
 * @group API 関数
 * @category OpenAI
 * @beta
 */
export const getQuizGenre = createAsyncApi(async (question: string, correctChoice: string): Promise<string | null> => {
  const req: GetQuizGenreReq = {question, answer: correctChoice};
  const res = await client.post<GetQuizGenreRes>("/quizium/v3/quiz/openai/quiz_genre", req, {timeout: 10000});
  return res.data.genre || null;
}, "getQuizGenre");