import {client} from "src/clients/client";
import {getUsersByIds} from "src/repositories/library/user";
import {
  AdminUser,
  PublicUser
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  CreateAdminUserReq,
  CreateAdminUserRes,
  DeleteAdminUserRes,
  GetAdminUserRes,
  ListAdminUserReq,
  ListAdminUserRes,
  MultiGetAdminUserReq,
  MultiGetAdminUserRes
} from "src/types/raw/admin/admin_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API 関数
 * @category 管理者ユーザー
 */
export const createAdminUser = createAsyncApi(async (userHandle: string): Promise<Id> => {
  const req: CreateAdminUserReq = {userHandle};
  const res = await client.post<CreateAdminUserRes>("/admin/v2/adminuser", req);
  return res.data.id;
}, "createAdminUser");

/**
 * @group API 関数
 * @category 管理者ユーザー
 */
export const deleteAdminUser = createAsyncApi(async (id: Id): Promise<void> => {
  const res = await client.delete<DeleteAdminUserRes>(`/admin/v2/adminuser/${id}`);
}, "deleteAdminUser");

/**
 * @group API 関数
 * @category 管理者ユーザー
 */
export const getAdminUser = createAsyncApi(async (userId: Id): Promise<AdminUser> => {
  const res = await client.get<GetAdminUserRes>(`/admin/v2/adminuser/${userId}`);
  return res.data.adminUser!;
}, "getAdminUser");

/**
 * @group API 関数
 * @category 管理者ユーザー
 */
export const getAdminUsers = createAsyncApi(async (userIds: Array<Id>): Promise<Array<AdminUser>> => {
  if (userIds.length > 0) {
    const req: MultiGetAdminUserReq = {userIds};
    const res = await client.post<MultiGetAdminUserRes>("/admin/v2/adminuser/MultiGet", req);
    return res.data.adminUsers;
  } else {
    return [];
  }
}, "getAdminUsers");

/**
 * @group 型
 * @category 管理者ユーザー
 */
export interface AdminUserQuery {
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category 管理者ユーザー
 */
export const listAdminUsers = createAsyncApi(async (query: AdminUserQuery): Promise<Array<AdminUser>> => {
  const params: Partial<ListAdminUserReq> = query;
  const res = await client.get<ListAdminUserRes>("/admin/v2/adminuser", {params});
  return res.data.adminUsers;
}, "listAdminUsers");

/**
 * @group API 関数
 * @category 管理者ユーザー
 */
export const listUsersOfAdmin = createAsyncApi(async (query: AdminUserQuery): Promise<Array<PublicUser>> => {
  const adminUsers = await listAdminUsers(query);
  const users = await getUsersByIds(adminUsers.map((adminUser) => adminUser.userId));
  const augedUsers = adminUsers.flatMap((adminUser) => {
    const user = users.find((candUser) => candUser.id === adminUser.userId);
    if (user != null) {
      return [{...user, adminUser}];
    } else {
      return [];
    }
  });
  return augedUsers;
}, "listUsersOfAdmin");