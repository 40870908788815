/**
 * コンソールにフォーマットされたログを出力します。
 * `label` と `sublabel` がまず出力され、`objects` の内容がインデントされた状態で出力されます。
 * @param label ラベル
 * @param sublabel サブラベル
 * @param objects 出力する内容
 */
export const writeLog = (label: string, sublabel: string, ...objects: Array<any>): void => {
  writeLogInternal(label, sublabel, false, ...objects);
};

/**
 * コンソールにフォーマットされたログを出力します。
 * `label` と `sublabel` がまず出力され、`objects` の内容がインデントされた状態で出力されます。
 * `objects` の内容はデフォルトで折りたたまれた状態で表示されます。
 * @param label ラベル
 * @param sublabel サブラベル
 * @param objects 出力する内容
 */
export const writeLogCollapsed = (label: string, sublabel: string, ...objects: Array<any>): void => {
  writeLogInternal(label, sublabel, true, ...objects);
};

const writeLogInternal = (label: string, sublabel: string, collapsed: boolean, ...objects: Array<any>): void => {
  const date = new Date();
  const dateString = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}.${date.getMilliseconds().toString().padStart(3, "0")}`;
  (collapsed ? console.groupCollapsed : console.group)(
    `%c${dateString}\n%c${label}%c${sublabel}`,
    "font-size: 80%; font-weight: normal; display: block;",
    "font-size: 110%; font-weight: bold; color: white; background-color: #407525; border-radius: 0.3em; padding: 0em 0.3em; margin: 0.2em 0.5em 0em 0em; display: inline-block;",
    "font-size: 110%; font-weight: bold; text-decoration: underline; margin: 0em 0.5em 0em 0em;"
  );
  if (objects.length > 0) {
    console.log(...objects);
  }
  console.groupEnd();
};