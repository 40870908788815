import type {Genre} from "src/types";


export const GENRES = {
  kids: [
    {
      id: "64ab88f75d49b65eddcb5edf",
      meaning: "幼児の語彙-会話、反対言葉"
    },
    {
      id: "64ab89035d49b65eddcb5ee0",
      meaning: "幼児の語彙-食べ物、乗り物、生き物、植物"
    },
    {
      id: "64ab89145d49b65eddcb5ee1",
      meaning: "幼児の語彙-社会、芸術"
    },
    {
      id: "64ab891d5d49b65eddcb5ee2",
      meaning: "幼児の語彙-数、形、時間"
    },
    {
      id: "64ab892b5d49b65eddcb5ee3",
      meaning: "幼児の語彙-その他"
    },
    {
      id: "64ab89385d49b65eddcb5ee4",
      meaning: "幼児の算数"
    },
    {
      id: "64ab89425d49b65eddcb5ee5",
      meaning: "幼児の英語"
    },
    {
      id: "64ab894d5d49b65eddcb5ee6",
      meaning: "その他の幼児教育"
    }
  ] satisfies Array<Genre>,
  elementarySchool: [
    {
      id: "64ab896d5d49b65eddcb5ee7",
      meaning: "小学国語"
    },
    {
      id: "64ab89765d49b65eddcb5ee8",
      meaning: "小学社会"
    },
    {
      id: "64ab898a5d49b65eddcb5ee9",
      meaning: "小学算数"
    },
    {
      id: "64ab89985d49b65eddcb5eea",
      meaning: "小学理科"
    },
    {
      id: "64ab89a85d49b65eddcb5eeb",
      meaning: "小学音楽"
    },
    {
      id: "64ab89be5d49b65eddcb5eec",
      meaning: "小学図工"
    },
    {
      id: "64ab89e55d49b65eddcb5eed",
      meaning: "小学技術・家庭"
    },
    {
      id: "64ab8a035d49b65eddcb5eee",
      meaning: "小学保健・体育"
    },
    {
      id: "64ab8a585d49b65eddcb5eef",
      meaning: "小学英語"
    },
    {
      id: "64ab8a725d49b65eddcb5ef0",
      meaning: "小学道徳・宗教"
    },
    {
      id: "64ab8a875d49b65eddcb5ef1",
      meaning: "小学総合的な学習"
    },
    {
      id: "64ab8a9d5d49b65eddcb5ef2",
      meaning: "小学情報・IT・プログラミング"
    },
    {
      id: "64ab8abd5d49b65eddcb5ef3",
      meaning: "その他の小学学習"
    }
  ] satisfies Array<Genre>,
  juniorHighSchool: [
    {
      id: "64ab8ae45d49b65eddcb5ef4",
      meaning: "中学国語"
    },
    {
      id: "64ab8aef5d49b65eddcb5ef5",
      meaning: "中学地理"
    },
    {
      id: "64ab8af95d49b65eddcb5ef6",
      meaning: "中学歴史"
    },
    {
      id: "64ab8b0a5d49b65eddcb5ef7",
      meaning: "中学公民"
    },
    {
      id: "64ab8b1b5d49b65eddcb5ef8",
      meaning: "中学数学"
    },
    {
      id: "64ab8b255d49b65eddcb5ef9",
      meaning: "中学理科"
    },
    {
      id: "64ab8b345d49b65eddcb5efa",
      meaning: "中学音楽"
    },
    {
      id: "64ab8b525d49b65eddcb5efb",
      meaning: "中学美術"
    },
    {
      id: "64ab8b615d49b65eddcb5efc",
      meaning: "中学技術・家庭"
    },
    {
      id: "64ab8b775d49b65eddcb5efd",
      meaning: "中学保健・体育"
    },
    {
      id: "64ab8b855d49b65eddcb5efe",
      meaning: "中学英語"
    },
    {
      id: "64ab8c315d49b65eddcb5eff",
      meaning: "中学道徳・宗教"
    },
    {
      id: "64ab8c695d49b65eddcb5f00",
      meaning: "中学総合的な学習"
    },
    {
      id: "64ab8c965d49b65eddcb5f01",
      meaning: "中学情報・IT・プログラミング"
    },
    {
      id: "64ab8d2e5d49b65eddcb5f02",
      meaning: "その他の中学学習"
    }
  ] satisfies Array<Genre>,
  highSchool: [
    {
      id: "64ab8da45d49b65eddcb5f03",
      meaning: "高校国語"
    },
    {
      id: "64ab8dea5d49b65eddcb5f04",
      meaning: "高校古典"
    },
    {
      id: "64ab8dfd5d49b65eddcb5f05",
      meaning: "高校日本史"
    },
    {
      id: "64ab8e0e5d49b65eddcb5f06",
      meaning: "高校世界史"
    },
    {
      id: "64ab8e255d49b65eddcb5f07",
      meaning: "高校地理"
    },
    {
      id: "64ab8e3f5d49b65eddcb5f08",
      meaning: "高校公共"
    },
    {
      id: "64ab8e565d49b65eddcb5f09",
      meaning: "高校政治・経済"
    },
    {
      id: "64ab8e655d49b65eddcb5f0a",
      meaning: "高校数学"
    },
    {
      id: "64ab8e8d5d49b65eddcb5f0b",
      meaning: "高校地学"
    },
    {
      id: "64ab8ed05d49b65eddcb5f0c",
      meaning: "高校物理"
    },
    {
      id: "64ab8ee35d49b65eddcb5f0d",
      meaning: "高校化学"
    },
    {
      id: "64ab8eef5d49b65eddcb5f0e",
      meaning: "高校生物"
    },
    {
      id: "64ab8efb5d49b65eddcb5f0f",
      meaning: "高校音楽"
    },
    {
      id: "64ab8f0d5d49b65eddcb5f10",
      meaning: "高校美術"
    },
    {
      id: "64ab8f215d49b65eddcb5f11",
      meaning: "高校保健・体育"
    },
    {
      id: "64ab8f2c5d49b65eddcb5f12",
      meaning: "高校英語"
    },
    {
      id: "64ab8f445d49b65eddcb5f13",
      meaning: "高校倫理・宗教"
    },
    {
      id: "64ab8f535d49b65eddcb5f14",
      meaning: "高校総合的な学習"
    },
    {
      id: "64ab8f625d49b65eddcb5f15",
      meaning: "高校情報・IT・プログラミング"
    },
    {
      id: "64ab8f725d49b65eddcb5f16",
      meaning: "その他の高校学習"
    }
  ] satisfies Array<Genre>,
  general: [
    {
      id: "64ab8faf5d49b65eddcb5f17",
      meaning: "一般理学"
    },
    {
      id: "64ab8fba5d49b65eddcb5f18",
      meaning: "一般工学"
    },
    {
      id: "64ab8fc15d49b65eddcb5f19",
      meaning: "一般文学"
    },
    {
      id: "64ab8fc95d49b65eddcb5f1a",
      meaning: "一般言葉"
    },
    {
      id: "64ab8fd05d49b65eddcb5f1b",
      meaning: "一般日本史"
    },
    {
      id: "64ab8fe35d49b65eddcb5f1c",
      meaning: "一般世界史"
    },
    {
      id: "64ab8feb5d49b65eddcb5f1d",
      meaning: "一般地理"
    },
    {
      id: "64ab8ff65d49b65eddcb5f1e",
      meaning: "一般公民"
    },
    {
      id: "64ab90015d49b65eddcb5f1f",
      meaning: "一般芸術"
    },
    {
      id: "64ab900f5d49b65eddcb5f20",
      meaning: "一般漫画・アニメ・ゲーム"
    },
    {
      id: "64ab90185d49b65eddcb5f21",
      meaning: "一般生活"
    },
    {
      id: "64ab90215d49b65eddcb5f22",
      meaning: "一般スポーツ"
    },
    {
      id: "64ab90295d49b65eddcb5f23",
      meaning: "一般芸能"
    },
    {
      id: "64ab903a5d49b65eddcb5f24",
      meaning: "その他一般"
    }
  ] satisfies Array<Genre>,
  business: [
    {
      id: "64ab904b5d49b65eddcb5f25",
      meaning: "マーケティング・新規事業"
    },
    {
      id: "64ab90555d49b65eddcb5f26",
      meaning: "会計・ファイナンス"
    },
    {
      id: "64ab90625d49b65eddcb5f27",
      meaning: "経営・マネジメント"
    },
    {
      id: "64ab906d5d49b65eddcb5f28",
      meaning: "人材育成・キャリア開発"
    },
    {
      id: "64ab90765d49b65eddcb5f29",
      meaning: "その他ビジネス・キャリア"
    }
  ] satisfies Array<Genre>,
  other: [
    {
      id: "64a771f25d49b65eddcb5ede",
      meaning: "未分類"
    }
  ] satisfies Array<Genre>
};