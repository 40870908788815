import {MonthlyPlayableDeckStat, MonthlyStat, TotalStat} from "src/types";
import * as raw from "../raw/quizium/stats";


export const toTotalStat = (raw: NonNullable<raw.Stats>): TotalStat => {
  return {
    appId: raw.appId,
    encounterCount: raw.totalPlayQuiz,
    encounteredCount: raw.totalPlayMyQuiz
  };
};

export const toMonthlyStat = (raw: NonNullable<raw.MonthlyStats>): MonthlyStat => {
  return {
    appId: raw.appId,
    month: raw.month,
    encounterCount: raw.totalPlayQuiz,
    encounteredCount: raw.totalPlayMyQuiz
  };
};

export const toMonthlyPlayableDeckStat = (raw: NonNullable<raw.PlayableDeckStats>): MonthlyPlayableDeckStat => {
  return {
    ...raw,
    encounteredCount: raw.totalPlayCount
  };
};
