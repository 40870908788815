import {client} from "src/clients/client";
import {Id, PublicUser, User, UserStatus} from "src/types";
import {UpdateUsersStatusReq, UpdateUsersStatusRes} from "src/types/raw/admin/user_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API 関数
 * @category ユーザー
 */
export const getUserByHandleForAdmin = createAsyncApi(async (handle: string): Promise<User> => {
  const res = await client.get(`/admin/v2/user/${handle}`);
  return res.data.user!;
}, "getUserByHandleForAdmin");

/**
 * @group 型
 * @category ユーザー
 */
export interface UserQueryForAdmin {
  handle?: string;
  nickname?: string;
  campaignCode?: string;
  status?: UserStatus;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category ユーザー
 */
export const listUsersForAdmin = createAsyncApi(async (query: UserQueryForAdmin): Promise<[Array<PublicUser>, number]> => {
  const res = await client.get("/admin/v2/user", {params: query});
  return [res.data.users, res.data.total];
}, "listUsersForAdmin");

/**
 * @group API 関数
 * @category ユーザー
 */
export const updateUserStatus = createAsyncApi(async (id: Id, status: UserStatus): Promise<void> => {
  const req: UpdateUsersStatusReq = {status: status as any, ids: [id]};
  const res = await client.post<UpdateUsersStatusRes>("/admin/v2/users/status", req);
}, "updateUserStatus");