import {client} from "src/clients/client";
import {
  GroupJoin
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  AcceptGroupJoinReq,
  AcceptGroupJoinRes,
  CreateGroupJoinReq,
  CreateGroupJoinRes,
  DeleteGroupJoinReq,
  ListGroupJoinReq,
  ListGroupJoinRes
} from "src/types/raw/quizium/groupjoin_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API 関数
 * @category グループ加入申請
 */
export const createGroupJoin = createAsyncApi(async (groupId: Id, comment: string): Promise<Id> => {
  const req: CreateGroupJoinReq = {groupId, comment};
  const res = await client.post<CreateGroupJoinRes>("/quizium/v3/groupjoin", req);
  return res.data.id;
}, "createGroupJoin");

/**
 * @group API 関数
 * @category グループ加入申請
 */
export const deleteGroupJoin = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteGroupJoinReq = {id};
  const res = await client.post<CreateGroupJoinRes>("/quizium/v3/groupjoin/delete", req);
}, "deleteGroupJoin");

/**
 * @group API 関数
 * @category グループ加入申請
 */
export const acceptGroupJoin = createAsyncApi(async (id: Id): Promise<void> => {
  const req: AcceptGroupJoinReq = {id};
  const res = await client.post<AcceptGroupJoinRes>("/quizium/v3/groupjoin/accept", req);
}, "acceptGroupJoin");

/**
 * @group 型
 * @category グループ加入申請
 */
export interface GroupJoinQuery {
  userId?: Id;
  groupId?: Id;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category グループ加入申請
 */
export const listGroupJoins = createAsyncApi(async (query: GroupJoinQuery): Promise<Array<GroupJoin>> => {
  const req: Partial<ListGroupJoinReq> = query;
  const res = await client.post<ListGroupJoinRes>("/quizium/v3/groupjoin/list", req);
  return res.data.groupJoins;
}, "listGroupJoins");