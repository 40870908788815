import {CensoredQuiz, CensorshipReason, Quiz} from "src/types";
import {DeepNonNullable} from "ts-essentials";
import * as raw from "../raw/quizium/quiz";


export const toQuizOrCensoredQuiz = (raw: DeepNonNullable<raw.Quiz>): Quiz | CensoredQuiz => {
  if (raw.censorshipReason === CensorshipReason.None) {
    const {censorshipReason, ...rawRest} = raw;
    return rawRest;
  } else {
    return {
      id: raw.id,
      genreId: raw.genreId,
      version: raw.version,
      originId: raw.originId,
      isPrivate: raw.isPrivate,
      censorshipReason: raw.censorshipReason,
      appIds: raw.appIds,
      isOld: raw.isOld,
      authorId: raw.authorId,
      committerId: raw.committerId,
      groupId: raw.groupId,
      approveAt: raw.approveAt,
      writeAt: raw.writeAt,
      createAt: raw.createAt
    };
  }
};