import {client} from "src/clients/client";
import {
  GroupInvite
} from "src/types";
import {
  CreateGroupInviteReq,
  CreateGroupInviteRes,
  DeleteGroupInviteReq,
  DeleteGroupInviteRes,
  GetGroupInviteReq,
  GetGroupInviteRes,
  JoinGorupByInviteReq,
  JoinGorupByInviteRes
} from "src/types/raw/quizium/user_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API 関数
 * @category グループ招待
 */
export const createGroupInvite = createAsyncApi(async (handle: string): Promise<GroupInvite> => {
  const req: CreateGroupInviteReq = {handle};
  const res = await client.post<CreateGroupInviteRes>("/quizium/v3/group/invite", req);
  return res.data.invite!;
}, "createGroupInvite");

/**
 * @group API 関数
 * @category グループ招待
 */
export const deleteGroupInvite = createAsyncApi(async (handle: string): Promise<void> => {
  const req: DeleteGroupInviteReq = {handle};
  const res = await client.post<DeleteGroupInviteRes>("/quizium/v3/group/invite/dellete", req);
}, "deleteGroupInvit");

/**
 * @group API 関数
 * @category グループ招待
 */
export const getGroupInvite = createAsyncApi(async (handle: string): Promise<GroupInvite> => {
  const req: GetGroupInviteReq = {handle};
  const res = await client.post<GetGroupInviteRes>("/quizium/v3/group/invite/get", req);
  return res.data.invite!;
}, "getGroupInvite");

/**
 * @group API 関数
 * @category グループ招待
 */
export const joinGroupByInvite = createAsyncApi(async (handle: string, code: string): Promise<void> => {
  const req: JoinGorupByInviteReq = {handle, code};
  const res = await client.post<JoinGorupByInviteRes>("/quizium/v3/group/join", req);
}, "joinGroupByInvite");
