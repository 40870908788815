import {client} from "src/clients/client";
import {Campaign} from "src/types";
import {DateString, Id} from "src/types/modified/misc";
import {
  CreateCampaignReq,
  CreateCampaignRes,
  GetCampaignRes,
  ListCampaignsReq,
  ListCampaignsRes,
  UpdateCampaignReq,
  UpdateCampaignRes
} from "src/types/raw/admin/user_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category キャンペーン
 */
export interface CampaignForCreate {
  code: string;
  title: string;
  startAt: DateString;
  endAt: DateString;
}

/**
 * @group API 関数
 * @category キャンペーン
 */
export const createCampaign = createAsyncApi(async (campaign: CampaignForCreate): Promise<Id> => {
  const req: CreateCampaignReq = campaign;
  const res = await client.post<CreateCampaignRes>("/admin/v2/campaign", req);
  return res.data.id;
}, "createCampaign");

/**
 * @group 型
 * @category キャンペーン
 */
export interface CampaignForUpdate {
  code: string;
  title: string;
  startAt: DateString;
  endAt: DateString;
}

/**
 * @group API 関数
 * @category キャンペーン
 */
export const updateCampaign = createAsyncApi(async (campaign: CampaignForUpdate): Promise<void> => {
  const req: UpdateCampaignReq = campaign;
  const res = await client.post<UpdateCampaignRes>(`/admin/v2/campaign/${campaign.code}`, req);
}, "updateCampaign");

/**
 * @group API 関数
 * @category キャンペーン
 */
export const getCampaign = createAsyncApi(async (code: string): Promise<Campaign> => {
  const res = await client.get<GetCampaignRes>(`/admin/v2/campaign/${code}`);
  return res.data.campaign!;
}, "getCampaign");

/**
 * @group 型
 * @category キャンペーン
 */
export interface CampaignQuery {
  code?: string;
  title?: string;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category キャンペーン
 */
export const listCampaigns = createAsyncApi(async (query: CampaignQuery): Promise<Array<Campaign>> => {
  const params: Partial<ListCampaignsReq> = query;
  const res = await client.get<ListCampaignsRes>("/admin/v2/campaign", {params});
  return res.data.campaigns;
}, "listCampaigns");