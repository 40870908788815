import {client} from "src/clients/client";
import {
  AggregationSetting,
  AggregationTags,
  Competition,
  CompetitionAnswerCount,
  CompetitionQuizResult,
  CompetitionUserQuizResult,
  CompetitionUserResult,
  CompetitionWholeResult,
  EntryRequirement,
  GameData,
  ListedCompetition,
  OpenPeriod
} from "src/types";
import {
  toCompetition,
  toCompetitionQuizResult,
  toCompetitionResult,
  toCompetitionUserQuizResult,
  toCompetitionUserResult,
  toListedCompetition
} from "src/types/conversion/competition";
import {Id} from "src/types/modified/misc";
import * as raw from "src/types/raw/quizium/competition";
import {
  AggregateCompetitionResultReq,
  AggregateCompetitionResultRes,
  CloseCompetitionReq,
  CloseCompetitionRes,
  CreateCompetitionReq,
  CreateCompetitionRes,
  DeleteCompetitionReq,
  DeleteCompetitionRes,
  EntryCompetitionReq,
  EntryCompetitionRes,
  GetCompetitionQuizAnswersReq,
  GetCompetitionQuizAnswersRes,
  GetCompetitionQuizResultReq,
  GetCompetitionQuizResultRes,
  GetCompetitionReq,
  GetCompetitionRes,
  GetCompetitionResultReq,
  GetCompetitionResultRes,
  GetCompetitionUserQuizResultReq,
  GetCompetitionUserQuizResultRes,
  GetCompetitionUserResultReq,
  GetCompetitionUserResultRes,
  HoldCompetitionReq,
  HoldCompetitionRes,
  ListCompetitionReq,
  ListCompetitionRes,
  ListUserCompetitionsReq,
  ListUserCompetitionsRes,
  UpdateCompetitionReq,
  UpdateCompetitionRes
} from "src/types/raw/quizium/competition_service";
import {createAsyncApi} from "src/utils/api";
import {DeepNonNullable, DeepPartial} from "ts-essentials";


/**
 * @group 型
 * @category イベント
 */
export interface CompetitionForCreate {
  title: string;
  deckIds: Array<Id>;
  quizIds: Array<Id>;
  appId: string;
  aggregationSettings: Array<AggregationSetting>;
  userTags: Array<string>;
  gameTags: Array<string>;
  entryRequirement: EntryRequirement;
  viewRequirement: EntryRequirement;
  openPeriod: OpenPeriod;
  gameData: GameData;
  ownerGroupId: Id;
}

const fromCompetitionForCreate = (raw: CompetitionForCreate): raw.CompetitionForCreate => {
  return {
    ...raw,
    aggregationSettings: raw.aggregationSettings.map((setting) => ({name: setting.name, firstTag: setting.tags[0]!, secondTag: setting.tags[1]!, thirdTag: setting.tags[2]!}))
  };
};

/**
 * @group API 関数
 * @category イベント
 */
export const createCompetition = createAsyncApi(async (competition: CompetitionForCreate): Promise<Id> => {
  const req: CreateCompetitionReq = {competition: fromCompetitionForCreate(competition)};
  const res = await client.post<CreateCompetitionRes>("/quizium/v3/competition/create", req);
  return res.data.id;
}, "createCompetition");

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionForUpdate {
  id: Id;
  title: string;
  deckIds: Array<Id>;
  quizIds: Array<Id>;
  appId: string;
  aggregationSettings: Array<AggregationSetting>;
  userTags: Array<string>;
  gameTags: Array<string>;
  entryRequirement: EntryRequirement;
  viewRequirement: EntryRequirement;
  openPeriod: OpenPeriod;
}

const fromCompetitionForUpdate = (raw: CompetitionForUpdate): raw.CompetitionForUpdate => {
  return {
    ...raw,
    aggregationSettings: raw.aggregationSettings.map((setting) => ({name: setting.name, firstTag: setting.tags[0]!, secondTag: setting.tags[1]!, thirdTag: setting.tags[2]!}))
  };
};

/**
 * @group API 関数
 * @category イベント
 */
export const updateCompetition = createAsyncApi(async (competition: CompetitionForUpdate): Promise<void> => {
  const req: Omit<UpdateCompetitionReq, "id"> = {competition: fromCompetitionForUpdate(competition)};
  const res = await client.post<UpdateCompetitionRes>("/quizium/v3/competition/create", req);
}, "updateCompetition");

/**
 * @group API 関数
 * @category イベント
 */
export const deleteCompetition = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteCompetitionReq = {id};
  const res = await client.post<DeleteCompetitionRes>("/quizium/v3/competition/delete", req);
}, "deleteCompetition");

/**
 * @group API 関数
 * @category イベント
 */
export const getCompetition = createAsyncApi(async (id: Id): Promise<Competition> => {
  const req: GetCompetitionReq = {id};
  const res = await client.post<DeepNonNullable<GetCompetitionRes>>("/quizium/v3/competition/get", req);
  return toCompetition(res.data.competition);
}, "getCompetition");

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionQuery {
  appId?: string;
  groupId?: Id;
  onlyOpen?: boolean;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category イベント
 */
export const listCompetitions = createAsyncApi(async (query: CompetitionQuery): Promise<Array<ListedCompetition>> => {
  const req: DeepPartial<ListCompetitionReq> = {query};
  const res = await client.post<DeepNonNullable<ListCompetitionRes>>("/quizium/v3/competition/list", req);
  return res.data.competitions.map(toListedCompetition);
}, "listCompetitions");

/**
 * @group 型
 * @category イベント
 */
export interface ParticipatedCompetitionQuery {
  limit?: number;
  skip?: number;
}

/**
 * 自分が参加したイベントの一覧を取得します。
 * @group API 関数
 * @category イベント
 */
export const listParticipatedCompetitions = createAsyncApi(async (query: ParticipatedCompetitionQuery): Promise<Array<ListedCompetition>> => {
  const req: DeepPartial<ListUserCompetitionsReq> = {...query};
  const res = await client.post<DeepNonNullable<ListUserCompetitionsRes>>("/quizium/v3/competition/user/participated", req);
  return res.data.competitions.map(toListedCompetition);
}, "listParticipatedCompetitions");

/**
 * イベントを開催します。
 * ゲーム開始時に代表者が 1 回だけ呼んでください。
 * @param id イベント ID
 * @param settings 集計に用いるタグの配列
 * @param roomId
 * @group API 関数
 * @category イベント
 */
export const holdCompetition = createAsyncApi(async (id: Id, settings: Array<AggregationSetting>, roomId?: string): Promise<void> => {
  const aggregationSettings = settings.map((setting) => ({name: setting.name, firstTag: setting.tags[0], secondTag: setting.tags[1] ?? "", thirdTag: setting.tags[2] ?? ""}));
  const req: HoldCompetitionReq = {id, aggregationSettings, roomId: roomId ?? ""};
  const res = await client.post<HoldCompetitionRes>("/quizium/v3/competition/hold", req);
}, "holdCompetition");

/**
 * @group API 関数
 * @category イベント
 */
export const closeCompetition = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeepPartial<CloseCompetitionReq> = {id};
  const res = await client.post<DeepNonNullable<CloseCompetitionRes>>("/quizium/v3/competition/list", req);
}, "closeCompetition");

/**
 * イベントに参加します。
 * イベントに参加したときに必ず呼んでください。
 *
 * イベントに参加したユーザーは、グループの所属状況に関わらず、イベントに登録されたクイズを閲覧する権限が付与されます。
 * この追加の権限は、`getPlayableDeckByCompetition` や `getQuizzesByCompetition` などの関数でのみ有効になります。
 * @param id イベント ID
 * @param password パスワード (イベントにパスワード制限がかかっている場合)
 * @group API 関数
 * @category イベント
 */
export const participateCompetition = createAsyncApi(async (id: Id, password?: string): Promise<void> => {
  const req: EntryCompetitionReq = {id, password: password ?? ""};
  const res = await client.post<EntryCompetitionRes>("/quizium/v3/competition/entry", req);
}, "participateCompetition");

/**
 * イベントの結果を集計します。
 * これが呼ばれて初めて、`getCompetitionWholeResult` や `getCompetitionAnswerCounts` などのイベントの結果を返す API 関数の返り値が更新されます。
 * これが呼ばれないと、イベント結果を返す API 関数は古い集計結果を返します。
 * @group API 関数
 * @category イベント
 */
export const aggregateCompetition = createAsyncApi(async (id: Id, tags: AggregationTags): Promise<void> => {
  const req: AggregateCompetitionResultReq = {query: {competitionId: id, firstTag: tags[0], secondTag: tags[1] ?? "", thirdTag: tags[2] ?? ""}};
  const res = await client.post<AggregateCompetitionResultRes>("/quizium/v3/competition/result", req);
}, "aggregateCompetitionResult");

/**
 * イベントに参加した全プレイヤーのイベント全体の成績を取得します。
 * @param id イベント ID
 * @param tags 集計タグ
 * @returns 全プレイヤーのイベント全体での成績
 * @group API 関数
 * @category イベント
 */
export const getCompetitionWholeResult = createAsyncApi(async (id: Id, tags: AggregationTags): Promise<CompetitionWholeResult> => {
  const req: GetCompetitionResultReq = {query: {competitionId: id, userId: "", firstTag: tags[0], secondTag: tags[1] ?? "", thirdTag: tags[2] ?? ""}};
  const res = await client.post<GetCompetitionResultRes>("/quizium/v3/competition/result/get", req);
  return toCompetitionResult(res.data.result!);
}, "getCompetitionWholeResult");

/**
 * イベントで出題された特定のクイズに関する全プレイヤーの成績を取得します。
 * @param id イベント ID
 * @param quizId クイズ ID
 * @param tags 集計タグ
 * @returns 全プレイヤーの特定クイズでの成績
 * @group API 関数
 * @category イベント
 */
export const getCompetitionQuizResult = createAsyncApi(async (id: Id, quizId: Id, tags: AggregationTags): Promise<CompetitionQuizResult> => {
  const req: GetCompetitionQuizResultReq = {query: {competitionId: id, quizId, firstTag: tags[0], secondTag: tags[1] ?? "", thirdTag: tags[2] ?? ""}};
  const res = await client.post<GetCompetitionQuizResultRes>("/quizium/v3/competition/ranking/quiz/get", req);
  return toCompetitionQuizResult(res.data.result);
}, "getCompetitionQuizResult");

/**
 * イベントに参加した特定のユーザーに関する特定のクイズの成績を取得します。
 * @param id イベント ID
 * @param userId ユーザー ID
 * @param quizId クイズ ID
 * @param tags 集計タグ
 * @returns 特定プレイヤーのイベント全体での成績
 * @group API 関数
 * @category イベント
 */
export const getCompetitionUserQuizResult = createAsyncApi(async (id: Id, userId: Id, quizId: Id, tags: AggregationTags): Promise<CompetitionUserQuizResult> => {
  const req: GetCompetitionUserQuizResultReq = {query: {competitionId: id, userId, quizId, firstTag: tags[0], secondTag: tags[1] ?? "", thirdTag: tags[2] ?? ""}};
  const res = await client.post<GetCompetitionUserQuizResultRes>("/quizium/v3/competition/result/user/quiz/get", req);
  return toCompetitionUserQuizResult(res.data.result!);
}, "getCompetitionUserResult");

/**
 * イベントに参加した特定のユーザーに関するイベント全体の成績を取得します。
 * 合計得点の他、正答誤答の数や 1 問ごとの回答履歴などが取得できます。
 * @param id イベント ID
 * @param userId ユーザー ID
 * @param tags 集計タグ
 * @returns 特定プレイヤーのイベント全体での成績
 * @group API 関数
 * @category イベント
 */
export const getCompetitionUserResult = createAsyncApi(async (id: Id, userId: Id, tags: AggregationTags): Promise<CompetitionUserResult> => {
  const req: GetCompetitionUserResultReq = {query: {competitionId: id, userId, firstTag: tags[0], secondTag: tags[1] ?? "", thirdTag: tags[2] ?? ""}};
  const res = await client.post<GetCompetitionUserResultRes>("/quizium/v3/competition/result/user/get", req);
  return toCompetitionUserResult(res.data.result!);
}, "getCompetitionUserResult");

/**
 * イベントで出題された各クイズに対するプレイヤーの回答の一覧を返します。
 * 回答データは、回答者が多い順にソートされます (回答者が最も多いものが先頭)。
 * @param id イベント ID
 * @param quizId クイズ ID
 * @returns 回答データの配列
 * @group API 関数
 * @category イベント
 */
export const getCompetitionAnswerCounts = createAsyncApi(async (id: Id, quizId: Id): Promise<Array<CompetitionAnswerCount>> => {
  const req: GetCompetitionQuizAnswersReq = {competitionId: id, quizId};
  const res = await client.post<GetCompetitionQuizAnswersRes>("/quizium/v3/competition/result/quiz/get", req);
  const answers = res.data.answers;
  answers.sort((firstAnswer, secondAnswer) => secondAnswer.count - firstAnswer.count);
  return answers;
}, "getCompetitionAnswerCounts");
