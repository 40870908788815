import axios, {AxiosError} from "axios";
import {FirebaseError} from "firebase/app";
import {Api, AsyncApi, AsyncRawApi, RawApi} from "src/modules";
import {QuiziumBackendErrorCode, QuiziumError} from "src/types";


export const createAsyncApi = <F extends AsyncRawApi, N extends string>(rawApi: F, apiName: N): AsyncApi<F, N> => {
  const api = Object.assign((async (...args) => {
    try {
      return await rawApi(...args);
    } catch (error) {
      if (error instanceof QuiziumError) {
        throw error;
      } else if (axios.isAxiosError(error)) {
        throw createQuiziumErrorFromAxios(error);
      } else if (error instanceof FirebaseError) {
        throw createQuiziumErrorFromAuth(error);
      } else {
        throw new QuiziumError("unknown", undefined, error);
      }
    }
  }) as F, {
    apiName
  });
  return api;
};

export const createApi = <F extends RawApi, N extends string>(rawApi: F, apiName: N): Api<F, N> => {
  const api = Object.assign(((...args) => {
    try {
      return rawApi(...args);
    } catch (error) {
      if (error instanceof QuiziumError) {
        throw error;
      } else if (axios.isAxiosError(error)) {
        throw createQuiziumErrorFromAxios(error);
      } else if (error instanceof FirebaseError) {
        throw createQuiziumErrorFromAuth(error);
      } else {
        throw new QuiziumError("unknown", undefined, error);
      }
    }
  }) as F, {
    apiName
  });
  return api;
};

const createQuiziumErrorFromAxios = (cause: AxiosError): QuiziumError => {
  const code = (() => {
    const data = cause.response?.data as any;
    if (data != null && typeof data === "object" && typeof data.message === "string") {
      const fullCode = data.message as string;
      if (fullCode.startsWith("quizium:")) {
        const code = fullCode.replace(/^quizium:/, "quizium") as QuiziumBackendErrorCode;
        return code;
      }
    } else if (cause.response?.status === 504) {
      return "quiziumTimeout";
    }
    return "unknown";
  })();
  const status = cause.response?.status;
  const error = new QuiziumError(code, status, cause);
  return error;
};

const createQuiziumErrorFromAuth = (cause: FirebaseError): QuiziumError => {
  const code = (() => {
    const authCode = cause.code;
    switch (authCode) {
    // createUserWithEmailAndPassword, signInWithEmailAndPassword, updateEmail で発生
    // the email address is not valid
    case "auth/invalid-email":
      return "authInvalidEmail";
    // signInWithEmailAndPassword で発生
    // the user corresponding to the given email has been disabled
    case "auth/user-disabled":
      return "authUserDisabled";
    // signInWithEmailAndPassword で発生
    // there is no user corresponding to the given email
    case "auth/user-not-found":
      return "authUserNotFound";
    // signInWithEmailAndPassword で発生
    // the password is invalid for the given email or the account corresponding to the email does not have a password set
    case "auth/wrong-password":
      return "authWrongPassword";
    // createUserWithEmailAndPassword, updateEmail で発生
    // the email is already used by another user
    case "auth/email-already-in-use":
      return "authEmailAlreadyInUse";
    // updateEmail, updatePassword で発生
    // the user's last sign-in time does not meet the security threshold
    case "auth/requires-recent-login":
      return "authRequiresRecentLogin";
    // createUserWithEmailAndPassword, updatePassword で発生
    // the password is not strong enough
    case "auth/weak-password":
      return "authWeakPassword";
    default:
      return "unknown";
    }
  })();
  const error = new QuiziumError(code, undefined, cause);
  return error;
};
