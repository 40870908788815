export type Env = "production" | "staging" | "development";

/**
 * 環境を返します。
 * 基本的には `process.env.ENV` の値をそのまま返しますが、以下の場合は `"development"` を返します。
 * - `process.env.ENV` の取得時にエラーが生じる (環境変数が設定されていない場合など)
 * - `process.env.ENV` の値が `"production"`, `"staging"`, `"development"` のいずれでもない
 *
 * このライブラリが提供する API ラッパー関数の接続先は、この関数が返す環境に従います。
 * 例えば、この関数が `"production"` を返す場合は、本番環境の API サーバーに接続します。
 * @returns 環境
 */
export const getEnv = (): Env => {
  try {
    const rawEnv = process.env.ENV;
    if (rawEnv === "production") {
      return "production";
    } else if (rawEnv === "staging") {
      return "staging";
    } else {
      return "development";
    }
  } catch (error) {
    return "development";
  }
};

export const switchEnv = <T>(object: Record<Env, T>): T => {
  return object[getEnv()];
};

export type FirebaseAppName = "library" | "qroud";

/**
 * Firebase のアプリ名を返します。
 * 基本的には `process.env.FIREBASE_APP_NAME` の値をそのまま返しますが、以下の場合はデフォルト値として `"library"` を返します。
 * - `process.env.FIREBASE_APP_NAME` の取得時にエラーが生じる (環境変数が設定されていない場合など)
 * - `process.env.FIREBASE_APP_NAME` の値が正しいアプリ名ではない
 *
 * このライブラリが提供する API ラッパー関数の接続先は、この関数が返す環境に従います。
 * 例えば、この関数が `"production"` を返す場合は、本番環境の API サーバーに接続します。
 * @returns Firebase のアプリ名
 */
export const getFirebaseAppName = (): FirebaseAppName => {
  try {
    const rawName = process.env.FIREBASE_APP_NAME;
    if (rawName === "library") {
      return "library";
    } else if (rawName === "qroud") {
      return "qroud";
    } else {
      return "library";
    }
  } catch (error) {
    return "library";
  }
};

export const switchFirebaseAppName = <T>(object: Record<FirebaseAppName, T>): T => {
  return object[getFirebaseAppName()];
};

/**
 * デプロイしたものかどうかを返します。
 * `process.env.IS_DEPLOY` の値が `true` か `"true"` だった場合は `true` を返し、それ以外の場合は `false` を返します。
 *
 * この値に応じて、Cookie の有効ドメインの設定などが切り替わります。
 * @returns デプロイしたものかどうか
 */
export const isDeploy = (): boolean => {
  try {
    const rawIsDeploy = process.env.IS_DEPLOY as any;
    if (typeof rawIsDeploy === "boolean") {
      return rawIsDeploy;
    } else {
      return rawIsDeploy === "true";
    }
  } catch (error) {
    return false;
  }
};