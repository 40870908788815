import {UseQueryOptions, UseQueryResult, useQuery} from "react-query";
import {queryClient} from "src/hooks/queryClient";
import {AsyncApi, AsyncRawApi} from "src/modules";


/**
 * TanStack Query の `useQuery` をラップしたフックです。
 *
 * 基本的に以下の形で用います。
 * ```
 * const [data, error, rest] = use(api, args, config);
 * ```
 * 渡す引数は以下の通りです。
 * - `api` — このリポジトリが提供する API 関数
 * - `args` — `api` に渡す引数
 *   - 内部で `api(...args)` が呼ばれます
 * - `config` — TanStack Query の `useQuery` に渡すオプション
 *   - 省略可能
 *   - 基本的な用途では渡すことはありません
 *
 * 返り値は長さ 3 の配列で、以下の値が格納されます。
 * - `data` — API 関数を呼んだ返り値 (呼んでいる最中は `undefined`)
 * - `error` — API がエラーを返した場合はエラー内容 (エラーがない場合は `null`)
 * - `rest` — TanStack Query の `useQuery` が返す残りの情報
 *   - 基本的な用途では使いません
 *
 * 以下は、`getQuiz` 関数をこのフックを用いて呼び出し、結果を取り出している例です。
 * ```
 * const [quiz] = useApi(getQuiz, [id]);
 * ```
 * 多くの場合で、オプションに何かを渡す必要がなく、API 関数の返り値以外の情報は利用しません。
 * したがって、このような形で使用することがほとんどです。
 *
 * 第 2 引数 `args` には API 関数に渡す引数を渡しますが、代わりに `false`, `null`, `undefined` のいずれかの値も渡すことができます。
 * その場合、API は叩かずに、API の返り値として常に `undefined` を返します。
 *
 * これにより、特定の条件が満たされているときだけ API を呼ぶことができます。
 * 以下のように、`&&` の短絡評価を使うと便利です。
 * ```
 * const [quiz] = useApi(getQuiz, someCondition && [id]);
 * ```
 * @param api API 関数
 * @param args API 関数に渡す引数
 * @param config オプション
 * @returns API 関数の返り値などを格納した長さ 3 の配列
 */
export const useApi = <F extends AsyncRawApi, N extends string>(
  api: AsyncApi<F, N>,
  args: Parameters<F> | false | null | undefined,
  config?: UseQueryOptions<Awaited<ReturnType<F>>, unknown, Awaited<ReturnType<F>>, [N, ...Parameters<F>]>
): [
  data: Awaited<ReturnType<F>> | undefined,
  error: unknown | null,
  rest: Omit<UseQueryResult<Awaited<ReturnType<F>>, unknown>, "data" | "error">
] => {
  const enabled = !!args;
  const actualArgs = (enabled ? args : []) as any;
  const key = (enabled ? [api.apiName, ...actualArgs] : ["dummy"]) as any;
  const {data, error, ...rest} = useQuery(key, () => api(...actualArgs), {...config, enabled});
  return [data, error, rest];
};

/**
 * `useApi` フックを用いて得られた結果をインバリデートします。
 *
 * 以下のような形で使用します。
 * ```
 * await invalidateApi(api, predicate);
 * ```
 * 渡す引数は以下の通りです。
 * - `api` — インバリデートしたい API 関数本体
 * - `predicate` — 関数を渡すことでそれが `true` を返したもののみインバリデートされる
 *
 * 例えば、 `getQuiz` 関数に `"someId"` という引数を渡した結果のみインバリデートしたい場合は、以下のようにします。
 * ```
 * await invalidateApi(getQuiz, ([id]) => id === "someId");
 * ```
 * @param api API 関数
 * @param predicate API 関数
 */
export const invalidateApi = async <F extends AsyncRawApi, N extends string>(
  api: AsyncApi<F, N>,
  predicate?: (args: Awaited<Parameters<F>>) => boolean
): Promise<void> => {
  await queryClient.invalidateQueries({predicate: (query) => {
    const [keyName, ...keyArgs] = query.queryKey as [string, ...Awaited<Parameters<F>>];
    return (keyName === api.apiName || keyName.startsWith(`${api.apiName}.`)) && (predicate ? predicate(keyArgs) : true);
  }});
};
