import {DateString, Id} from "src/types";

/**
 * @group 列挙型
 * @category 通報
 */
export const ReportReasonType = {
  /** */
  Unspecified: "ReportReasonTypeUnspecified",
  /** */
  User: "ReportReasonTypeUser",
  /** */
  Piracy: "ReportReasonTypePiracy",
  /** */
  Inappropriate: "ReportReasonTypeInappropriate",
  /** */
  Incorrect: "ReportReasonTypeIncorrect",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type ReportReasonType = typeof ReportReasonType[keyof typeof ReportReasonType];

/**
 * @group 列挙型
 * @category 通報
 */
export const ReportTargetType = {
  /** */
  Unspecified: "ReportTargetTypeUnspecified",
  /** */
  User: "ReportTargetTypeUser",
  /** */
  Group: "ReportTargetTypeGroup",
  /** */
  Quiz: "ReportTargetTypeQuiz",
  /** */
  PlayableDeck: "ReportTargetTypePlayableDeck",
  /** */
  Directory: "ReportTargetTypeDirectory",
  /** */
  Competition: "ReportTargetTypeCompetition",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type ReportTargetType = typeof ReportTargetType[keyof typeof ReportTargetType];

/**
 * @group 列挙型
 * @category 通報
 */
export const ReportStatus = {
  /**
   * 未対応。
   * 通報が最初になされたときは、この状態になります。
   */
  New: "ReportStatusNew",
  /**
   * 対応中。
   */
  InProgress: "ReportStatusInProgress",
  /**
   * 却下。
   * 通報内容が不適切だった場合や、通報に対して特に対応する必要がなかった場合に、この状態になります。
   */
  Rejected: "ReportStatusRejected",
  /**
   * 対応済み。
   */
  Completed: "ReportStatusCompleted",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type ReportStatus = typeof ReportStatus[keyof typeof ReportStatus];

/**
 * @group 型
 * @category 通報
 */
export interface ReportUpdate {
  /**
   * 変更後の対応状態。
   */
  status: ReportStatus;
  /**
   * 対応者のユーザー ID。
   */
  userId: Id;
  /**
   * 対応者のメモ。
   */
  memo: string;
  /** */
  updateAt: DateString;
}

/**
 * @group 型
 * @category 通報
 */
export interface Report {
  /**
   * ID。
   */
  id: Id;
  /**
   * 通報の対象。
   */
  targetType: ReportTargetType;
  /**
   * 通報の対象データの ID。
   */
  targetId: Id;
  /**
   * 通報の理由。
   */
  reasonType: ReportReasonType;
  /**
   * 通報の詳細。
   * 通報者が通報するときに自由入力します。
   */
  detail: string;
  /**
   * 現在の対応状態。
   */
  status: ReportStatus;
  /** */
  updates: Array<ReportUpdate>;
  /**
   * 通報ユーザーの ID。
   */
  userId: string;
  /** */
  appId: string;
  /**
   * 楽観的ロックのためのバージョン番号。
   */
  version: number;
  /** */
  createAt: DateString;
}