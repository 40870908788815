import {css} from "@emotion/react";
import {ReactElement, ReactNode} from "react";
import {color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";


export interface TabListProps extends CustomDataAttributes {
  className?: string;
  children: ReactNode;
  activeTabName: string;
  handleClick: (tabName: string) => void;
}

export type TabListComponent = (props: TabListProps) => ReactElement;

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    max-height: 100%;
    gap: ${size(1)};
  `,
  tabList: css`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    overflow-y: scroll;
    `,
  tab: css`
    min-width: ${size(16)};
    width: ${size(32)};
    border: 1px solid ${color("blackText")};
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-size: ${size(6)};
    padding-block: ${size(2)};
    &[data-active="true"] {
      background-color: ${color("primary", 5)};
      color: #fff;
    }
  `
};

export const TabList: TabListComponent = ({children, activeTabName, handleClick}) => {
  const {trans} = useTranslation("avatarDrawer");

  return (
    <div css={styles.root}>
      <div css={styles.tabList}>
        <div css={styles.tab} data-active={activeTabName === "hair"} onClick={() => handleClick("hair")}>{trans("category.hair")}</div>
        <div css={styles.tab} data-active={activeTabName === "outfit"} onClick={() => handleClick("outfit")}>{trans("category.outfit")}</div>
        <div css={styles.tab} data-active={activeTabName === "body"} onClick={() => handleClick("body")}>{trans("category.body")}</div>
      </div>
      {children}
    </div>
  );
};
