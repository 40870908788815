import {client} from "src/clients/client";
import {createAsyncApi} from "src/utils/api";


/**
 * API サーバーのバージョンを取得します。
 * @returns バージョン
 * @group API 関数
 * @category その他
 */
export const getVersion = createAsyncApi(async (): Promise<string> => {
  const res = await client.post("/quizium/v3/group/get", {}, {validateStatus: () => true});
  return res.headers.xQuiziumApiVersion ?? "";
}, "getVersion");

/**
 * API サーバーがメンテナンス中かを取得します。
 * API サーバーのメンテナンス中は、全ての API 関数が 503 ステータスコードでエラーを発生させるので、メンテナンス中であることが分かるような表示をして、他の操作ができなくなるようにしてください。
 * @returns メンテナンス中の場合 `true`
 * @group API 関数
 * @category その他
 */
export const checkMaintenance = createAsyncApi(async (): Promise<boolean> => {
  const res = await client.post("/quizium/v3/group/get", {}, {validateStatus: () => true});
  const data = res.data;
  return res.status === 503 && data.message === "quizium:Maintenance";
}, "checkMaintenance");