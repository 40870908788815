/**
 * @group 列挙型
 * @category エラー
 */
export const QuiziumBackendErrorCode = {
  InvalidToken: "quiziumInvalidToken",
  ExpiredToken: "quiziumExpiredToken",
  InvalidResetToken: "quiziumInvalidResetToken",
  ExpiredResetToken: "quiziumExpiredResetToken",
  Unauthenticated: "quiziumUnauthenticated",
  InvalidArgument: "quiziumInvalidArgument",
  Unknown: "quiziumUnknown",
  SystemError: "quiziumSystemError",
  AlreadyExists: "quiziumAlreadyExists",
  HandleAlreadyExists: "quiziumHandleAlreadyExists",
  NotFound: "quiziumNotFound",
  ImageNotFound: "quiziumImageNotFound",
  UserNotFound: "quiziumUserNotFound",
  GroupNotFound: "quiziumGroupNotFound",
  PermissionDenied: "quiziumPermissionDenied",
  EditingPermissionDenied: "quiziumEditingPermissionDenied",
  CreatingPermissionDenied: "quiziumCreatingPermissionDenied",
  DeletionPermissionDenied: "quiziumDeletionPermissionDenied",
  ImagePermissionDenied: "quiziumImagePermissionDenied",
  InvitePermissionDenied: "quiziumInvitePermissionDenied",
  ReadingPermissionDenied: "quiziumReadingPermissionDenied",
  DeletionProhibited: "quiziumDeletionProhibited",
  DeckRemains: "quiziumDeckRemains",
  QuizRemains: "quiziumQuizRemains",
  PackRemains: "quiziumPackRemains",
  PlayableDeckRemains: "quiziumPlayableDeckRemains",
  DeckOrDirectoryRemains: "quiziumDeckOrDirectoryRemains",
  CannotChangeLastOwner: "quiziumCannotChangeLastOwner",
  UserAdditionProhibition: "quiziumUserAdditionProhibition",
  TooManyQuizzes: "quiziumTooManyQuizzes",
  NotTheLatestQuiz: "quiziumNotTheLatestQuiz",
  QuizIsNotPublic: "quiziumQuizIsNotPublic",
  TooManyDirectory: "quiziumTooManyDirectory",
  TooManyHierarchy: "quiziumTooManyHierarchy",
  InvalidDirectoryDestination: "quiziumInvalidDirectoryDestination",
  MimeTypeNotAllowed: "quiziumMimeTypeNotAllowed",
  AlreadyCreated: "quiziumAlreadyCreated",
  AlreadyDeleted: "quiziumAlreadyDeleted",
  IsNotPersonalGroup: "quiziumIsNotPersonalGroup",
  OverCapacity: "quiziumOverCapacity",
  OutOfPeriod: "quiziumOutOfPeriod",
  EntryPermissionDenied: "quiziumEntryPermissionDenied",
  CampaignCodeNotFound: "quiziumCampaignCodeNotFound",
  ExpiredCampaignCode: "quiziumExpiredCampaignCode",
  UserStatusLocked: "quiziumUserStatusLocked",
  UserStatusExpired: "quiziumUserStatusExpired",
  UserStatusBanned: "quiziumUserStatusBanned",
  LoginFailed: "quiziumLoginFailed",
  /**
   * API サーバーへのアクセスにタイムアウトしました。
   * タイムアウトのデフォルトは 5 秒です。
   */
  Timeout: "quiziumTimeout"
} as const;
/** @ignore */
export type QuiziumBackendErrorCode = typeof QuiziumBackendErrorCode[keyof typeof QuiziumBackendErrorCode];

/**
 * @group 列挙型
 * @category エラー
 */
export const QuiziumAuthErrorCode = {
  /**
   * このユーザーが Firebase 上で無効にされています。
   */
  UserDisabled: "authUserDisabled",
  /**
   * メールアドレスが正しくありません。
   */
  InvalidEmail: "authInvalidEmail",
  /**
   * 与えられたメールアドレスが他のユーザーにすでに使用されています。
   * メールアドレスは一意でなければなりません。
   */
  EmailAlreadyInUse: "authEmailAlreadyInUse",
  /**
   * 与えられたメールアドレスに合致するユーザーが存在しません。
   */
  UserNotFound: "authUserNotFound",
  /**
   * パスワードが弱いです。
   */
  WeakPassword: "authWeakPassword",
  /**
   * パスワードが異なります。
   */
  WrongPassword: "authWrongPassword",
  /**
   * 最後にログインした時刻が十分最近ではありません。
   * メールアドレスやパスワードの変更には、最後のログインが最近でなければなりません。
   * Firebase Auth の `reauthenticateWithCredential` 関数を呼ぶことで解消できますが、現状未対応です。
   */
  RequiresRecentLogin: "authRequiresRecentLogin"
} as const;
/** @ignore */
export type QuiziumAuthErrorCode = typeof QuiziumAuthErrorCode[keyof typeof QuiziumAuthErrorCode];

/**
 * @group 列挙型
 * @category エラー
 */
export const QuiziumOtherErrorCode = {
  Unknown: "unknown"
} as const;
/** @ignore */
export type QuiziumOtherErrorCode = typeof QuiziumOtherErrorCode[keyof typeof QuiziumOtherErrorCode];

/**
 * @group 列挙型
 * @category エラー
 */
export type QuiziumErrorCode = QuiziumBackendErrorCode | QuiziumAuthErrorCode | QuiziumOtherErrorCode;

/**
 * Quizium API の共通エラークラス。
 * このパッケージが提供する API ラッパー関数で発生したエラーは、全てこのエラークラスでラップされます。
 * @group 型
 * @category エラー
 */
export class QuiziumError extends Error {
  /**
   * エラーコード。
   */
  public readonly code: QuiziumErrorCode;
  /**
   * HTTP ステータスコード。
   * Quizium API サーバーへのアクセスに由来しないエラーの場合は `undefined` になります。
   */
  public readonly status?: number;
  /** */
  public override readonly cause?: unknown;

  static {
    this.prototype.name = "QuiziumError";
  }
  public constructor(code: QuiziumErrorCode, status: number | undefined, cause?: unknown) {
    const message = `QuiziumError: ${code}`;
    super(message, {cause});

    this.code = code;
    this.status = status;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
