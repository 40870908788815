import {Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category グループメンバー
 */
export const GroupMemberType = { // MemberType
  /**
   * 一般ユーザー。
   * 基本的にデータの閲覧のみが可能で、編集や削除はできません。
   */
  Normal: "MemberTypeNomal",
  /**
   * 投稿者ユーザー。
   * データの閲覧に加えて、新しいデータの作成と自分が作ったデータの編集ができますが、他のユーザーが作ったデータの編集はできません。
   * グループ自身の設定の変更はできません。
   */
  Editor: "MemberTypeEditor",
  /**
   * 編集者ユーザー。
   * データの閲覧や編集や削除が自由にできます。
   * グループ自身の設定の変更はできません。
   */
  Director: "MemberTypeDirector",
  /**
   * 管理者ユーザー。
   * 全ての権限を持っています。
   */
  Owner: "MemberTypeOwner",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type GroupMemberType = typeof GroupMemberType[keyof typeof GroupMemberType];

/**
 * グループとそれに所属しているユーザーとを紐付けるオブジェクト。
 * @group 型
 * @category グループメンバー
 */
export interface GroupUser {
  /**
   * このオブジェクトの ID。
   * 紐づけの対象となるユーザーやグループの ID ではありません。
   */
  id: Id;
  /**
   * このオブジェクトが紐付けるユーザーの ID。
   */
  userId: Id;
  /**
   * このオブジェクトが紐付けるグループの ID。
   */
  groupId: Id;
  /**
   * 該当ユーザーの該当グループにおける権限。
   */
  memberType: GroupMemberType;
  /** */
  isLocked: boolean;
}
