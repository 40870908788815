import {
  EntityConstructor,
  FieldId,
  Session,
  SessionId,
  global,
  listenConnected,
  listenDisconnected,
  socket
} from "@baton8/qroud-lib-repositories";
import {
  AvatarDrawer,
  CreateSessionDrawer,
  DisconnectedCover,
  FieldEntitySystem,
  FollowingNodeSystem,
  LoadingCover,
  MainScene as MainSceneInterface,
  PlayerTriggerSystem,
  SelectSessionDrawer,
  SpawnPoint,
  StoriesSystem
} from "@baton8/qroud-lib-resources";
import {
  BodyComponent,
  ColliderComponent,
  Engine,
  Entity,
  Random,
  Scene,
  SceneActivationContext,
  Vector,
  vec
} from "excalibur";
import {filter} from "rxjs";
import {CustomLoader} from "src/game/core/loader";
import {Field} from "src/game/entities/field";
import {FieldGroup} from "src/game/entities/fieldGroup";
import {GhostGroup} from "src/game/entities/ghostGroup";
import {Player} from "src/game/entities/player";
import {setUrlParam} from "src/utils/url";


type MainSceneData = {
  session: Session,
  fieldId: FieldId,
  objectId?: number,
  x?: number,
  y?: number,
  isFirst?: boolean
};

type PendingMoveProps = {
  fieldId: FieldId | null,
  castX?: number,
  castY?: number
};

export class MainScene extends Scene<MainSceneData> implements MainSceneInterface {
  private readonly entityConstructors: Array<EntityConstructor>;

  public session!: Session;

  public fieldGroup!: FieldGroup;
  public field!: Field;
  public player!: Player;
  public ghostGroup!: GhostGroup;

  /**
   * メインシーンで常に存在するエンティティ (Player, GhostGroup, FieldGroup の 3 つ) が追加されたかどうか。
   * 重複して追加するのを防ぐために利用されます。
   */
  private isCommonAdded: boolean;
  /**
   * プレイヤーが新しいセッションに入ったことをバックエンドに伝え終わったかどうか。
   * セッションに入る処理がバックエンド側で終わってから `movePlayer` イベントを送り始めるように、`Player` で参照されています。
   */
  public isSessionReady: boolean;

  private pendingMoveProps: PendingMoveProps | null;

  private prevSessionId: SessionId | undefined;
  private prevFieldId: FieldId | undefined;

  public constructor(entityConstructors: Array<EntityConstructor>) {
    super();
    this.entityConstructors = entityConstructors;
    this.isCommonAdded = false;
    this.isSessionReady = false;
    this.pendingMoveProps = null;
    this.prevSessionId = undefined;
    this.prevFieldId = undefined;
    this.initializeSystems();
  }

  public override onInitialize(engine: Engine): void {
    this.setupSocket();
    this.setupOverlays(engine);
    this.subscribeLoadingProp();
  }

  public override async onActivate({engine, data}: SceneActivationContext<MainSceneData>): Promise<void> {
    if (this.prevFieldId !== data?.fieldId) {
      if (this.field != null) {
        this.killEntities();
        this.finializePlayer();
      }
    }

    if (data != null) {
      this.setupFromData(data);
      this.addCommonEntities(engine);
      await this.addField(engine, data);
      await this.enterSession();
      this.movePlayerToSpawnPoint(data);
    } else {
      throw new Error("No data specified to MainScene");
    }
  }

  public override onDeactivate({engine}: SceneActivationContext<undefined>): void {
    this.prevSessionId = this.session?.id;
    this.prevFieldId = this.field?.id;
  }

  private initializeSystems(): void {
    this.world.add(new FieldEntitySystem());
    this.world.add(new FollowingNodeSystem());
    this.world.add(new PlayerTriggerSystem());
    this.world.add(new StoriesSystem());
  }

  private setupSocket(): void {
    socket.on("roomSettingsUpdated", (settings) => {
      // TODO: ここで this.session.settings を書き換えていた
      global.sessionSubject.next({...this.session});
    });
    listenDisconnected(() => {
      DisconnectedCover.propsSubject.next({isVisible: true});
      this.player.disallowMove();
    });
    listenConnected(() => {
      DisconnectedCover.propsSubject.next({isVisible: false});
      this.player.allowMove();
    });
  }

  private setupOverlays(engine: Engine): void {
    SelectSessionDrawer.propsSubject.update({
      onSessionChange: (session, fieldId) => this.changeSessionTo(session, fieldId)
    });
    CreateSessionDrawer.propsSubject.update({
      onSessionChange: (session) => this.changeSessionTo(session)
    });
    AvatarDrawer.propsSubject.update({
      onAvatarNameChanged: (avatarName) => this.player.changeAvatar(avatarName)
    });
    // TODO: マップ表示ドロワーができたらここでコールバックを設定する
  }

  private setupFromData(data: MainSceneData): void {
    this.session = data.session;
    global.sessionSubject.next(data.session);
    setUrlParam("session", this.session.id);
  }

  private addCommonEntities(engine: Engine): void {
    if (!this.isCommonAdded) {
      const player = new Player(global.user!.avatar);
      const ghostGroup = new GhostGroup();
      const fieldGroup = new FieldGroup();
      this.player = player;
      this.ghostGroup = ghostGroup;
      this.fieldGroup = fieldGroup;
      this.add(player);
      this.add(ghostGroup);
      this.add(fieldGroup);
      this.isCommonAdded = true;
    }
  }

  private async addField(engine: Engine, data: MainSceneData): Promise<void> {
    const reuseField = this.prevFieldId === data.fieldId;
    const field = reuseField ? this.field : new Field(data.fieldId, this.entityConstructors);
    this.camera.strategy.lockToActor(this.player);
    this.field = field;
    this.ghostGroup.field = field;
    const loader = new CustomLoader([field]);
    await engine.load(loader);
    const redirectFieldId = field.getRedirectFieldId();
    if (data.isFirst && redirectFieldId) {
      this.fieldGroup.reset();
      this.moveTo(redirectFieldId);
    } else {

      if (!this.prevFieldId || this.prevFieldId !== field.id) {
        field.initialize(this, {player: this.player, ghostGroup: this.ghostGroup});
      } else {
        field.reuse(this, {player: this.player, ghostGroup: this.ghostGroup});
      }

      global.fieldSubject.next(field);
      setUrlParam("map", field.id);
    }
  }

  private async enterSession(): Promise<void> {
    if (!this.isSessionReady) {
      this.changePlayerSession();
    } else {
      this.ghostGroup.resetGhosts(this.field.id);
    }
  }

  private movePlayerToSpawnPoint(data: MainSceneData): void {
    if (data.objectId != null) {
      const targetObject = this.field.getEntityFromObjectId(data.objectId);
      const targetPos = targetObject != null ? getRandomPos(targetObject) : undefined;
      if (targetPos != null) {
        this.player.pos = targetPos;
      } else {
        const spawnPoint = this.field.getFirstEntity(SpawnPoint);
        if (spawnPoint != null) {
          this.player.pos = getRandomPos(spawnPoint) ?? vec(0, 0);
        } else {
          this.player.pos = vec(0, 0);
        }
      }
    } else {
      const spawnPos = data.x != null && data.y != null ? vec(data.x, data.y) : undefined;
      if (spawnPos != null) {
        this.player.pos = spawnPos;
      } else {
        const spawnPoint = this.field.getFirstEntity(SpawnPoint);
        if (spawnPoint != null) {
          this.player.pos = getRandomPos(spawnPoint) ?? vec(0, 0);
        } else {
          this.player.pos = vec(0, 0);
        }
      }
    }
    this.player.graphics.visible = true;
    this.player.isTransferring = false;
  }

  private killEntities(): void {
    this.field.finalize(this);
  }

  private finializePlayer(): void {
    this.player.graphics.visible = false;
    this.player.isTransferring = true;
  }

  private changePlayerSession(): void {
    const user = global.user;
    if (user != null) {
      socket.emit("changePlayerSession", user.id, this.session.id, this.field.id, this.field.fieldGroupId, "", () => {
        this.ghostGroup.resetGhosts(this.field.id);
        this.isSessionReady = true;
        this.player.setupAvatar(user.avatar);
      });
    } else {
      throw new Error("Not signed in");
    }
  }

  public changeSessionTo(session: Session, fieldId?: FieldId): void {
    this.fieldGroup.reset();
    this.isSessionReady = false;
    this.engine.goToScene("main", {
      session,
      fieldId: fieldId ?? this.field.id
    });
  }

  public changeFieldTo(fieldId: FieldId): void {
    this.engine.goToScene("main", {
      session: this.session,
      fieldId
    });
  }

  public moveToObject(fieldId: FieldId | null, objectId?: number): void {
    if (fieldId == null || fieldId === this.field.id) {
      const entity = objectId != null ? this.field.getEntityFromObjectId(objectId) : undefined;
      const pos = entity != null ? getRandomPos(entity) : undefined;
      this.moveLocalTo(pos?.x, pos?.y);
    } else {
      this.moveForeignToObject(fieldId, objectId);
    }
  }

  public moveTo(fieldId: FieldId | null, pos?: Vector): void;
  public moveTo(fieldId: FieldId | null, x?: number, y?: number): void;
  public moveTo(fieldId: FieldId | null, posOrX?: Vector | number, y?: number): void {
    const castX = typeof posOrX === "number" ? posOrX : posOrX?.x;
    const castY = typeof posOrX === "number" ? y : posOrX?.y;
    const props = LoadingCover.propsSubject;

    if (props.value.isVisible) {
      this.pendingMoveProps = {fieldId, castX, castY};
    } else {
      if (fieldId == null || fieldId === this.field.id) {
        this.moveLocalTo(castX, castY);
      } else {
        this.moveForeignTo(fieldId, castX, castY);
      }
    }
  }

  private subscribeLoadingProp(): void {
    const loadingObservable = LoadingCover.propsObservable;

    loadingObservable.pipe(filter(([, props]) => !props.isVisible)).subscribe(() => {
      if (this.pendingMoveProps) {
        const {fieldId, castX, castY} = this.pendingMoveProps;
        this.pendingMoveProps = null;
        if (fieldId == null || fieldId === this.field.id) {
          this.moveLocalTo(castX, castY);
        } else {
          this.moveForeignTo(fieldId, castX, castY);
        }
      }
    });
  }

  private moveLocalTo(x?: number, y?: number): void {
    if (x != null && y != null) {
      this.player.moveTo(vec(x, y));
    } else {
      const spawnPoint = this.field.getFirstEntity(SpawnPoint);
      if (spawnPoint != null) {
        this.player.moveTo(spawnPoint.getRandomPos());
      } else {
        this.player.moveTo(vec(0, 0));
      }
    }
  }

  private moveForeignTo(fieldId: FieldId, x?: number, y?: number): void {
    const session = this.session;
    LoadingCover.propsSubject.next({isVisible: true});
    this.engine.goToScene("main", {session, fieldId, x, y});
  }

  private moveForeignToObject(fieldId: FieldId, objectId?: number): void {
    const session = this.session;
    LoadingCover.propsSubject.next({isVisible: true});
    this.engine.goToScene("main", {session, fieldId, objectId});
  }

  public confirmMoveField(): void {
    LoadingCover.propsSubject.next({isVisible: false});
  };
}

const random = new Random();

const getRandomPos = (entity: Entity): Vector | undefined => {
  const body = entity.get(BodyComponent);
  const collider = entity.get(ColliderComponent);
  if (body != null && collider != null) {
    const x = random.floating(body.pos.x - collider.localBounds.width / 2, body.pos.x + collider.localBounds.width / 2);
    const y = random.floating(body.pos.y - collider.localBounds.height / 2, body.pos.y + collider.localBounds.height / 2);
    return vec(x, y);
  } else {
    return undefined;
  }
};