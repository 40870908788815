import {GENRES} from "src/modules/genre";
import {CensorshipReason} from "src/types";
import {DateString, Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category クイズ
 */
export const AnswerType = {
  /**
   * 正答。
   */
  Correct: "AnswerTypeCorrect",
  /**
   * 誤答。
   */
  Incorrect: "AnswerTypeIncorrect",
  /**
   * 正答とも誤答とも言えない場合。
   * 例えば、アンケート形式のクイズの選択肢などではこれを指定します。
   */
  Neutral: "AnswerTypeNeutral",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type AnswerType = typeof AnswerType[keyof typeof AnswerType];

/**
 * @group 列挙型
 * @category クイズ
 */
export const MediumType = {
  /**
   * テキスト。
   * `content` にはテキストの本文を格納します。
   */
  Text: "MediumTypeText",
  /**
   * 画像。
   * `content` には画像の ID を格納します。
   */
  Image: "MediumTypeImage",
  /**
   * 音声。
   * 現状対応していません。
   * @beta
   */
  Audio: "MediumTypeAudio",
  /**
   * 動画。
   * 現状対応していません。
   * @beta
   */
  Video: "MediumTypeVideo",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type MediumType = typeof MediumType[keyof typeof MediumType];

/**
 * @group 型
 * @category クイズ
 */
export interface MediumExtension {
  furigana: string;
}

/**
 * @group 型
 * @category クイズ
 */
export interface QuizExtension {
}

/**
 * @group 型
 * @category クイズ
 */
export interface Medium {
  type: MediumType;
  content: string;
  mediumExtension: MediumExtension;
}

/**
 * @group 型
 * @category クイズ
 */
export type Media = Array<Medium>;

/**
 * @group 型
 * @category クイズ
 */
export interface Choice {
  /**
   * この選択肢が正答かどうか。
   */
  answerType: AnswerType;
  /**
   * この選択肢の内容。
   */
  media: Media;
}

/**
 * @group 型
 * @category クイズ
 */
export interface Genre {
  /**
   * ID。
   */
  id: Id;
  /**
   * このジャンルの説明。
   * これは表示用のテキストではなく、このジャンルが何を表しているのかをエンジニアに分かりやすくするためのものです。
   * ジャンルを表示する際は、別途表示用のテキストを用意してください。
   */
  meaning: string;
}

/**
 * @group 型
 * @category クイズ
 */
export interface Quiz {
  /**
   * ID。
   */
  id: Id;
  /**
   * クイズの問題文。
   */
  question: Media;
  /**
   * クイズの回答選択肢。
   * クイズの種類に応じて以下のように指定します。
   *
   * 一問一答クイズの場合、`answerType` を `Correct` にした `Choice` オブジェクトを指定します。
   * 複数個指定することで、表記揺れなどに対応することができます。
   *
   * 択一クイズの場合、正答選択肢か誤答選択肢かに応じて `answerType` が設定された `Choice` オブジェクトを複数個指定してください。
   *
   * アンケートクイズの場合、`answerType` を `Neutral` にした `Choice` オブジェクトを複数個指定してください。
   */
  answer: Array<Choice>;
  /**
   * クイズの解説や補足。
   */
  remark: Media;
  /**
   * クイズの出典となる URL の配列。
   */
  sources: Array<string>;
  /**
   * クイズのジャンルの ID。
   */
  genreId: Id;
  /**
   * クイズのタグの配列。
   */
  tags: Array<string>;
  /** */
  quizExtension: QuizExtension;
  /**
   * バージョン。
   * 編集が行われるたびに増加します。
   * 初期バージョンでの値は `1` です。
   */
  version: number;
  /**
   * 初期バージョンの ID。
   */
  originId: Id;
  /**
   * 限定公開かどうか。
   * 限定公開の場合は `true` となり、全体公開の場合は `false` となります。
   */
  isPrivate: boolean;
  /** */
  appIds: Array<string>;
  /**
   * このクイズの後に編集が行われたかどうか。
   * 最新のバージョンである場合は `false` です。
   * このバージョンが作られた後に編集されていて、より新しいバージョンが存在する場合は `true` です。
   */
  isOld: boolean;
  /**
   * このクイズの初期バージョンを作成したユーザーの ID。
   */
  authorId: Id;
  /**
   * このバージョンを作成したユーザーの ID。
   * 初期バージョンを作成したユーザー以外のユーザーでも後から編集を加えられるため、これは `autorId` とは異なる場合があります。
   */
  committerId: Id;
  /**
   * このクイズを所有しているグループの ID。
   */
  groupId: Id;
  /** */
  approveAt: DateString;
  /**
   * クイズの作問日時 (通常は日付のみ, 時刻は 00:00:00 で固定)。
   * 過去に作ったクイズを Quizium に登録した場合など、このクイズデータ自身が作られた日時とは異なる場合があります。
   */
  writeAt: DateString;
  /** */
  createAt: DateString;
}

/**
 * @group 型
 * @category クイズ
 */
export interface CensoredQuiz {
  /**
   * ID。
   */
  id: Id;
  /**
   * クイズのジャンルの ID。
   */
  genreId: Id;
  /**
   * バージョン。
   * 編集が行われるたびに増加します。
   * 初期バージョンでの値は `1` です。
   */
  version: number;
  /**
   * 初期バージョンの ID。
   */
  originId: Id;
  /**
   * 限定公開かどうか。
   * 限定公開の場合は `true` となり、全体公開の場合は `false` となります。
   */
  isPrivate: boolean;
  /**
   * 検閲理由。
   */
  censorshipReason: CensorshipReason;
  /** */
  appIds: Array<string>;
  /**
   * このクイズの後に編集が行われたかどうか。
   * 最新のバージョンである場合は `false` です。
   * このバージョンが作られた後に編集されていて、より新しいバージョンが存在する場合は `true` です。
   */
  isOld: boolean;
  /**
   * このクイズの初期バージョンを作成したユーザーの ID。
   */
  authorId: Id;
  /**
   * このバージョンを作成したユーザーの ID。
   * 初期バージョンを作成したユーザー以外のユーザーでも後から編集を加えられるため、これは `autorId` とは異なる場合があります。
   */
  committerId: Id;
  /**
   * このクイズを所有しているグループの ID。
   */
  groupId: Id;
  /** */
  approveAt: DateString;
  /**
   * クイズの作問日時 (通常は日付のみ, 時刻は 00:00:00 で固定)。
   * 過去に作ったクイズを Quizium に登録した場合など、このクイズデータ自身が作られた日時とは異なる場合があります。
   */
  writeAt: DateString;
  /** */
  createAt: DateString;
};

/**
 * @group 型
 * @category クイズ
 */
export interface DeletedQuiz {
  id: Id;
  originId: Id;
  authorId: "";
};

/* -------------------------------------------------------------------------- */

/** @ignore */
export namespace MediumExtension {
  export const EMPTY = {
    furigana: ""
  } satisfies MediumExtension;
  export const DEFAULT = {
    furigana: ""
  } satisfies MediumExtension;
};

/** @ignore */
export namespace QuizExtension {
  export const EMPTY = {
  } satisfies QuizExtension;
  export const DEFAULT = {
  } satisfies QuizExtension;
};

/** @ignore */
export namespace Medium {
  export const EMPTY = {
    type: MediumType.Text,
    content: "",
    mediumExtension: MediumExtension.EMPTY
  } satisfies Medium;
  export const DEFAULT = {
    type: MediumType.Text,
    content: "",
    mediumExtension: MediumExtension.EMPTY
  } satisfies Medium;
};

/** @ignore */
export namespace Choice {
  export const EMPTY = {
    answerType: AnswerType.Correct,
    media: []
  } satisfies Choice;
  export const DEFAULT = {
    answerType: AnswerType.Correct,
    media: []
  } satisfies Choice;
};

/** @ignore */
export namespace Quiz {
  export const EMPTY = {
    id: Id.EMPTY,
    question: [],
    answer: [],
    remark: [],
    sources: [],
    genreId: GENRES.other[0].id,
    tags: [],
    quizExtension: QuizExtension.EMPTY,
    version: 0,
    originId: Id.EMPTY,
    isPrivate: false,
    appIds: [],
    isOld: false,
    authorId: Id.EMPTY,
    committerId: Id.EMPTY,
    groupId: Id.EMPTY,
    approveAt: DateString.EMPTY,
    writeAt: DateString.EMPTY,
    createAt: DateString.EMPTY
  } satisfies Quiz;
  export const DEFAULT = {
    id: Id.DEFAULT,
    question: [],
    answer: [],
    remark: [],
    sources: [],
    genreId: Id.DEFAULT,
    tags: [],
    quizExtension: QuizExtension.DEFAULT,
    version: 0,
    originId: Id.DEFAULT,
    isPrivate: false,
    appIds: [],
    isOld: false,
    authorId: Id.DEFAULT,
    committerId: Id.DEFAULT,
    groupId: Id.DEFAULT,
    approveAt: DateString.DEFAULT,
    writeAt: DateString.DEFAULT,
    createAt: DateString.DEFAULT
  } satisfies Quiz;
};