import {getMe, listWearable, updateMyAvatar, useApi} from "@baton8/quizium-lib-repositories";
import {Wearable} from "@baton8/quizium-lib-repositories/dist/types/raw/quizium/avatar";
import {WearableSet} from "@baton8/quizium-lib-repositories/dist/types/raw/quizium/user";
import {css} from "@emotion/react";
import {faCog, faUser} from "@fortawesome/pro-regular-svg-icons";
import {useCallback, useEffect, useState} from "react";
import {Button, Drawer, DrawerAddon, DrawerAddonButton, DrawerBody, DrawerCloseButton, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, Heading, ScrollList, ScrollListMain, alpha, borderWidth, color, size} from "src/components";
import {TabList} from "src/components/common/tabList";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";
import {Menu} from "src/overlays/core/menu";
import {AvatarImage} from "./avatarImage";


export interface AvatarDrawerProps {
  /**
   * このオーバーレイの Z index。
   * @defaultValue `300`
   */
  zIndex?: number;
  /** */
  isOpen: boolean;
  /**
   * アバターの名前。
   */
  avatarName: string | null;
  /**
   * アバターの変更が行われたときに呼ばれるコールバック関数。
   */
  onAvatarNameChanged: (avatarName: string) => unknown;
};

const styles = {
  drawer: css`
  `,
  body: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  formContainer: css`
    gap: ${size(6)};
    display: flex;
    flex-direction: row;
  `,
  leftSection: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  heading: css`
    margin-block-end: ${size(4)};
    flex-grow: 0;
    flex-shrink: 0;
  `,
  currentAvatarCover: css`
    margin-inline: auto;
    max-height: 100%;
    aspect-ratio: 1 / 2;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
  `,
  currentAvatarImage: css`
    position: absolute;
    height: 400%;
    transform: translateY(-2%);
  `,
  selectSection: css`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
  `,
  listWrapper: css`
    flex-grow: 1;
    flex-shrink: 1;
    display:flex;
    flex-direction: column;
    max-height: 100%;
  `,
  list: css`
    gap: ${size(2)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    justify-content: start;
  `,
  avatarImageCover: css`
    border: solid ${borderWidth(2)} ${alpha(color("background"), 0.5)};
    cursor: pointer;
    background-color: ${alpha(color("primary", 3), 0.1)};
    &:hover {
      background-color: ${color("primary", 3)};
    }
    &[data-selected="true"] {
      border: solid ${borderWidth(2)} ${color("primary", 5)};
    }
  `
};

/**
 * - **Inner Props**: {@link AvatarDrawerProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const AvatarDrawer = createOverlay<AvatarDrawerProps>(({
  zIndex = 300,
  isOpen,
  onAvatarNameChanged
}) => {
  const {trans} = useTranslation("avatarDrawer");
  const [wearableAvatar] = useApi(listWearable, []);
  const [avatarImageTab, setAvatarImageTab] = useState<string>("hair");
  const [displayAvatar, setDisplayAvatar] = useState<Array<Wearable>>([]);

  const AVATAR_BASE_URL = "https://d14riej7e18ms7.cloudfront.net/Wearable";

  const handleClose = useCallback(() => {
    AvatarDrawer.propsSubject.update({isOpen: false});
  }, []);

  const showMenuDrawer = useCallback(() => {
    AvatarDrawer.propsSubject.update({isOpen: false});
    setTimeout(() => {
      Menu.propsSubject.update({isOpen: true});
    }, 300);
  }, []);
  const [selectedAvatar, setselectedAvatar] = useState<WearableSet>({body: "human-3", outfit: "tops-1", hair: "black-1V2"});

  const canChangeAvatar = selectedAvatar.body !== "";

  const handleChangeAvatar = useCallback(async () => {
    if (selectedAvatar.body) {
      const wearableSet = selectedAvatar;
      const avatarName = await updateMyAvatar(wearableSet);
      onAvatarNameChanged(avatarName);
      AvatarDrawer.propsSubject.update({avatarName, isOpen: false});
    }
  }, [onAvatarNameChanged, selectedAvatar]);

  const handleChangeTab = (handleName: string): void => {
    setAvatarImageTab(handleName);
  };

  const handleClickWearable = (avatarType: string, avatarName: string): void => {
    if (avatarType === "outfit") {
      setselectedAvatar({...selectedAvatar, [avatarType]: avatarName});
    } else {
      setselectedAvatar({...selectedAvatar, [avatarType]: avatarName});
    }
  };

  useEffect(() => {
    if (wearableAvatar != null) {
      const currentAvatarList = wearableAvatar.filter((wearable) => wearable.type === avatarImageTab);
      setDisplayAvatar(currentAvatarList);
    }
  }, [wearableAvatar, avatarImageTab, selectedAvatar]);

  useEffect(() => {
    getMe().then((me) => {
      if (me.avatar !== "") {
        const currentWearableList = me.avatar.split(".").map((item) => {
          const [type, name] = item.split("_");
          setselectedAvatar((prevState) => ({...prevState, [type]: name}));
          return {type, name};
        });
      }
    });
  }, []);


  return (
    <Drawer css={styles.drawer} zIndex={zIndex} isOpen={isOpen} onClose={handleClose}>
      <DrawerCloseButton/>
      <DrawerAddon>
        <DrawerAddonButton icon={faUser} highlight={true}/>
        <DrawerAddonButton icon={faCog} onClick={showMenuDrawer}/>
      </DrawerAddon>
      <DrawerHeader>
        <DrawerTitle>{trans("header.title")}</DrawerTitle>
        <DrawerDescription>{trans("header.description")}</DrawerDescription>
      </DrawerHeader>
      <DrawerBody css={styles.body}>
        <div css={styles.formContainer}>
          <section css={styles.leftSection}>
            <Heading css={styles.heading}>{trans("heading.currentAvatar")}</Heading>
            <div css={styles.currentAvatarCover}>
              {Object.entries(selectedAvatar).map(([key, value]) => (
                <img css={styles.currentAvatarImage} src={`${AVATAR_BASE_URL}/${key}/${value}.png`} alt={value} key={value}/>
              ))}
            </div>
          </section>
          <section css={styles.selectSection}>
            <Heading css={styles.heading}>{trans("heading.changeAvatar")}</Heading>
            <TabList activeTabName={avatarImageTab} handleClick={handleChangeTab}>
              <ScrollList css={styles.listWrapper} items={displayAvatar} insertMargin={true}>
                {/* <ScrollListLoading/> */}
                <ScrollListMain css={styles.list}>
                  {(avatar: Wearable, index) => {
                    return (
                      <AvatarImage
                        key={avatar.name}
                        avatarType={avatar.type}
                        avatarName={avatar.name}
                        onClick={() => handleClickWearable(avatar.type, avatar.name)}
                        css={styles.avatarImageCover}
                        data-selected={Object.values(selectedAvatar).some((i) => i === avatar.name)}
                      />
                    );
                  }}
                </ScrollListMain>
              </ScrollList>
            </TabList>
          </section>
        </div>
      </DrawerBody>
      <DrawerFooter>
        <Button size="lg" onClick={handleChangeAvatar} disabled={!canChangeAvatar}>
          {trans("submit")}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}, {
  isOpen: false,
  avatarName: null,
  onAvatarNameChanged: () => null
});