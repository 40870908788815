import {Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category デッキメンバー
 */
export const DeckMemberType = {
  /**
   * 一般ユーザー。
   * 基本的にデータの閲覧のみが可能で、編集や削除はできません。
   */
  Normal: "DeckMemberTypeNomal",
  /**
   * 投稿者ユーザー。
   * デッキ内のクイズの閲覧に加えて、デッキへのクイズの追加のみができます。
   */
  Editor: "DeckMemberTypeEditor",
  /**
   * 編集者ユーザー。
   * デッキ内のクイズの閲覧や編集や削除が自由にできます。
   */
  Director: "DeckMemberTypeDirector",
  /**
   * 管理者ユーザー。
   * 全ての権限を持っています。
   */
  Owner: "DeckMemberTypeOwner",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type DeckMemberType = typeof DeckMemberType[keyof typeof DeckMemberType];

/**
 * @group 型
 * @category デッキメンバー
 */
export interface DeckMember {
  /**
   * このオブジェクトの ID。
   * 紐づけの対象となるユーザーやデッキの ID ではありません。
   */
  id: Id;
  /**
   * このオブジェクトが紐付けるユーザーの ID。
   */
  userId: Id;
  /**
   * このオブジェクトが紐付ける下書きデッキの ID。
   */
  draftDeckId: Id;
  /**
   * 該当ユーザーの該当デッキにおける権限。
   */
  memberType: DeckMemberType;
}
