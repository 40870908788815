import {DateString, Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category イベント
 */
export const AnswerStatus = {
  /**
   * 正答。
   */
  Correct: "AnswerStatusCorrect",
  /**
   * 誤答。
   */
  Incorrect: "AnswerStatusIncorrect",
  /**
   * 正答とも誤答とも言えない場合。
   * 例えば、近似値クイズのような完全な正答 (や誤答) が考えにくい形式や、アンケートクイズのような正答が存在しない形式では、これが利用されます。
   */
  Neutral: "AnswerStatusNeutral",
  /**
   * 不参加。
   */
  Unqualified: "AnswerStatusUnqualified",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type AnswerStatus = typeof AnswerStatus[keyof typeof AnswerStatus];

/**
 * @group 型
 * @category イベント
 */
export interface EntryRequirement {
  /**
   * 全てのグループを許可するかどうか。
   * これが `true` の場合は、`groupIds` の設定は無視されます。
   */
  allowAllGroup: boolean;
  /**
   * 参加や観戦を許可するグループの ID の配列。
   * ここに指定されたグループに所属するユーザーのみが、イベントへの参加や観戦を許可されます。
   */
  groupIds: Array<Id>;
  /**
   * パスワード。
   * これが指定されていると、参加や観戦の際にパスワードの入力を要求されるようになります。
   */
  password: string;
  /**
   * 上限人数。
   * ここの指定された人数までしか参加や観戦ができなくなります。
   * `0` が指定された場合は、上限人数は設けられず何人でも参加や観戦ができます。
   */
  capacity: number;
}

/**
 * @group 型
 * @category イベント
 */
export interface OpenPeriod {
  /**
   * イベントの開始時刻。
   */
  startAt: DateString;
  /**
   * イベントの終了時刻。
   * `"0"` が指定された場合は、終了時刻がない (無期限のイベント) と解釈されます。
   */
  endAt: DateString;
}

/**
 * @group 型
 * @category イベント
 */
export interface GameData {
  roomId: string;
}

/**
 * @group 型
 * @category イベント
 */
export type AggregationTags = [primary: string, secondary?: string, tertiary?: string];

/**
 * @group 型
 * @category イベント
 */
export interface AggregationSetting {
  name: string;
  tags: AggregationTags;
}

/**
 * @group 型
 * @category イベント
 */
export interface Competition {
  /**
   * ID。
   */
  id: Id;
  /**
   * イベントのタイトル。
   */
  title: string;
  /**
   * イベントで用いる配信済みデッキの ID の配列。
   * ここで指定されたデッキに含まれるクイズ (と `quizIds` に指定されたクイズ) に限り、イベントの参加者と観戦者の全員に閲覧権限が付与されて、イベント中に見ることができるようになります。
   */
  deckIds: Array<Id>;
  /**
   * イベントで用いるクイズの ID の配列。
   * ここで指定されたクイズ (と `deckIds` に指定されたデッキに含まれるクイズ) に限り、イベントの参加者と観戦者の全員に閲覧権限が付与されて、イベント中に見ることができるようになります。
   * `deckIds` に指定したデッキに含まれるクイズを、ここで改めて指定する必要はありません。
   */
  quizIds: Array<Id>;
  /**
   * アプリ ID。
   */
  appId: string;
  /**
   * イベントで用いる集計タグ設定の配列。
   * ここで指定された集計タグ設定を用いてランキングなどが集計されます。
   */
  aggregationSettings: Array<AggregationSetting>;
  /**
   * イベントの主催者が設定するタグの配列。
   */
  userTags: Array<string>;
  /**
   * ゲームが設定するタグの配列。
   * イベントの主催者はこれを変更することはできません。
   */
  gameTags: Array<string>;
  /**
   * イベントへの参加条件。
   */
  entryRequirement: EntryRequirement;
  /**
   * イベントの観戦条件。
   */
  viewRequirement: EntryRequirement;
  /**
   * イベントの開催期間。
   */
  openPeriod: OpenPeriod;
  /** */
  gameData: GameData;
  /**
   * イベントに参加したユーザーの ID の配列。
   */
  entryUserIds: Array<Id>;
  /**
   * イベントを管理しているグループの ID。
   */
  ownerGroupId: Id;
  /**
   * このイベントが終了済みかどうか。
   * 開催期間が過ぎた場合に `true` になります。
   */
  isClosed: boolean;
  /** */
  createAt: DateString;
  /** */
  updateAt: DateString;
}

/**
 * 一覧系の API から返されるイベントデータ。
 * `Competition` と比べて `entryUserIds` プロパティが省略されています。
 * @group 型
 * @category イベント
 */
export interface ListedCompetition {
  /**
   * ID。
   */
  id: Id;
  /**
   * イベントのタイトル。
   */
  title: string;
  /**
   * イベントで用いる配信済みデッキの ID の配列。
   * ここで指定されたデッキに含まれるクイズ (と `quizIds` に指定されたクイズ) に限り、イベントの参加者と観戦者の全員に閲覧権限が付与されて、イベント中に見ることができるようになります。
   */
  deckIds: Array<Id>;
  /**
   * イベントで用いるクイズの ID の配列。
   * ここで指定されたクイズ (と `deckIds` に指定されたデッキに含まれるクイズ) に限り、イベントの参加者と観戦者の全員に閲覧権限が付与されて、イベント中に見ることができるようになります。
   * `deckIds` に指定したデッキに含まれるクイズを、ここで改めて指定する必要はありません。
   */
  quizIds: Array<Id>;
  /**
   * アプリ ID。
   */
  appId: string;
  /**
   * イベントで用いる集計タグ設定の配列。
   * ここで指定された集計タグ設定を用いてランキングなどが集計されます。
   */
  aggregationSettings: Array<AggregationSetting>;
  /**
   * イベントの主催者が設定するタグの配列。
   */
  entryRequirement: EntryRequirement;
  /**
   * イベントの観戦条件。
   */
  viewRequirement: EntryRequirement;
  /**
   * イベントの開催期間。
   */
  openPeriod: OpenPeriod;
  /** */
  gameData: GameData;
  /**
   * イベントを管理しているグループの ID。
   */
  ownerGroupId: Id;
  /**
   * このイベントが終了済みかどうか。
   * 開催期間が過ぎたり、`closeCompetition` 関数が呼ばれて手動でイベントが終了されたりすると、これが `true` になります。
   */
  isClosed: boolean;
  /** */
  createAt: string;
  /** */
  updateAt: string;
}

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionTeam {
  id: string;
  name: string;
  competitionId: Id;
  userId: Array<Id>;
}

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionWholeResult {
  /**
   * イベントの ID。
   */
  competitionId: Id;
  /**
   * ユーザーごとのランキング。
   */
  userResults: Array<CompetitionWholeResultPerUser>;
  /**
   * チームごとのランキング。
   * イベントに参加したユーザーの中にチームに所属していない人がいた場合、そのユーザーの結果は「無所属チーム」のものとして集計されます。
   */
  teamResults: Array<CompetitionWholeResultPerUser>;
  /** */
  updateAt: DateString;
}

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionWholeResultPerUser {
  /**
   * ユーザーもしくはチームの ID。
   * 無所属チームの結果の場合は空文字列が格納されます。
   */
  id: Id | "";
  /**
   * ユーザーもしくはチームの名前。
   * 無所属チームの結果の場合は空文字列が格納されます。
   */
  name: string;
  /**
   * 総得点。
   */
  points: [primary: number, secondary?: number, tertiary?: number];
  /**
   * 順位。
   * 1 から始まります。
   */
  rank: number;
}

/**
 * @group 型
 * @category イベント
 * @interface
 */
export type CompetitionQuizResult = Array<CompetitionQuizResultPerUser>;

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionQuizResultPerUser {
  /**
   * ユーザーの ID。
   */
  userId: Id;
  /**
   * このユーザーが所属しているチームの ID。
   * このユーザーがチームに所属していない場合は空文字列が格納されます。
   */
  teamId: Id | "";
  /**
   * 回答文字列。
   */
  answers: Array<string>;
  /**
   * この回答での正誤。
   */
  answerStatus: AnswerStatus;
  /**
   * この回答で獲得した得点。
   */
  points: [primary: number, secondary?: number, tertiary?: number];
  /**
   * 順位。
   * 1 から始まります。
   */
  rank: number;
}

/**
 * @group 型
 * @category イベント
 * @interface
 */
export type CompetitionUserQuizResult = CompetitionQuizResultPerUser;

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionUserResult {
  /**
   * 総得点。
   */
  points: [primary: number, secondary: number, tertiary: number];
  /**
   * 正誤ごとの回答数。
   */
  answerCounts: {
    correct: number,
    incorrect: number,
    neutral: number,
    unqualified: number
  };
  /**
   * 順位。
   * 最上位は 1 です。
   */
  rank: number;
  /**
   * 1 問ごとの結果の配列。
   */
  quizResults: Array<CompetitionUserResultPerQuiz>;
}

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionUserResultPerQuiz {
  /**
   * この回答をしたクイズの ID。
   */
  quizId: Id;
  /**
   * 回答文字列。
   */
  answer: string;
  /**
   * この回答での正誤。
   */
  status: AnswerStatus;
  /**
   * この回答で獲得した得点。
   */
  point: number;
}

/**
 * @group 型
 * @category イベント
 */
export interface CompetitionAnswerCount {
  /**
   * 回答文字列。
   */
  answer: string;
  /**
   * この回答の正誤。
   */
  status: AnswerStatus;
  /**
   * この回答をしたプレイヤーの人数。
   */
  count: number;
}

/* -------------------------------------------------------------------------- */

/** @ignore */
export namespace EntryRequirement {
  export const EMPTY = {
    allowAllGroup: true,
    groupIds: [],
    password: "",
    capacity: 0
  } satisfies EntryRequirement;
  export const DEFAULT = {
    allowAllGroup: false,
    groupIds: [],
    password: "",
    capacity: 0
  } satisfies EntryRequirement;
};

/** @ignore */
export namespace OpenPeriod {
  export const EMPTY = {
    startAt: DateString.EMPTY,
    endAt: DateString.EMPTY
  } satisfies OpenPeriod;
  export const DEFAULT = {
    startAt: DateString.DEFAULT,
    endAt: DateString.DEFAULT
  } satisfies OpenPeriod;
};

/** @ignore */
export namespace GameData {
  export const EMPTY = {
    roomId: ""
  } satisfies GameData;
  export const DEFAULT = {
    roomId: ""
  } satisfies GameData;
};

/** @ignore */
export namespace Competition {
  export const EMPTY = {
    id: Id.EMPTY,
    title: "",
    deckIds: [],
    quizIds: [],
    appId: "",
    aggregationSettings: [],
    userTags: [],
    gameTags: [],
    entryRequirement: EntryRequirement.EMPTY,
    viewRequirement: EntryRequirement.EMPTY,
    openPeriod: OpenPeriod.EMPTY,
    gameData: GameData.EMPTY,
    entryUserIds: [],
    ownerGroupId: Id.EMPTY,
    isClosed: false,
    createAt: DateString.EMPTY,
    updateAt: DateString.EMPTY
  } satisfies Competition;
  export const DEFAULT = {
    id: Id.DEFAULT,
    title: "",
    deckIds: [],
    quizIds: [],
    appId: "",
    aggregationSettings: [],
    userTags: [],
    gameTags: [],
    entryRequirement: EntryRequirement.DEFAULT,
    viewRequirement: EntryRequirement.DEFAULT,
    openPeriod: OpenPeriod.DEFAULT,
    gameData: GameData.DEFAULT,
    entryUserIds: [],
    ownerGroupId: Id.DEFAULT,
    isClosed: false,
    createAt: DateString.DEFAULT,
    updateAt: DateString.DEFAULT
  } satisfies Competition;
};
