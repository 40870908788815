import {switchEnv} from "src/modules";


export const PAGE_URLS = {
  library: switchEnv({
    production: "https://lib.quizium.io",
    staging: "https://lib-staging58.quizium.io",
    development: "https://lib-dev.quizium.io"
  }),
  qroud: switchEnv({
    production: "https://qroud.quizium.io",
    staging: "https://qroud-staging58.quizium.io",
    development: "https://qroud-dev.quizium.io"
  }),
  paperTest: switchEnv({
    production: "https://drill.quizium.io",
    staging: "https://drill-staging58.quizium.io",
    development: "https://drill-dev.quizium.io"
  }),
  pitcher: switchEnv({
    production: "https://pitcher.quizium.io",
    staging: "https://pitcher-staging58.quizium.io",
    development: "https://pitcher-dev.quizium.io"
  })
} as const;

export const API_URLS = {
  library: switchEnv({
    production: "https://api.quizium.io",
    staging: "https://api-staging58.quizium.io",
    development: "https://api-dev.quizium.io"
  })
} as const;

/** @deprecated */
export const LIBRARY_PAGE_URL = switchEnv({
  production: "https://lib.quizium.io",
  staging: "https://lib-staging58.quizium.io",
  development: "https://lib-dev.quizium.io"
});

/** @deprecated */
export const QROUD_PAGE_URL = switchEnv({
  production: "https://qroud.quizium.io",
  staging: "https://qroud-staging58.quizium.io",
  development: "https://qroud-dev.quizium.io"
});

/** @deprecated */
export const PAPER_TEST_PAGE_URL = switchEnv({
  production: "https://drill.quizium.io",
  staging: "https://drill-staging58.quizium.io",
  development: "https://drill-dev.quizium.io"
});
