import {Directory, DraftDeck, ListedDraftDeck, PlayableDeck} from "src/types";


export const isDirectory = <D extends DraftDeck | ListedDraftDeck | PlayableDeck>(node: Directory | D): node is Directory => {
  return "parentId" in node;
};

export const isDeck = <D extends DraftDeck | ListedDraftDeck | PlayableDeck>(node: Directory | D): node is D => {
  return !("parentId" in node);
};

export const isDraftDeck = <D extends DraftDeck | ListedDraftDeck>(deck: D | PlayableDeck): deck is D => {
  return "directoryId" in deck;
};

export const isPlayableDeck = <D extends DraftDeck | ListedDraftDeck>(deck: D | PlayableDeck): deck is PlayableDeck => {
  return !("directoryId" in deck);
};