import * as firebaseAuth from "firebase/auth";
import {client} from "src/clients/client";
import {auth} from "src/clients/firebase";
import {TokenManager} from "src/clients/tokenManager";
import {
  PublicUser,
  QuiziumError,
  User
} from "src/types";
import {Id} from "src/types/modified/misc";
import {WearableSet} from "src/types/raw/quizium/user";
import {
  CreateUserReq,
  CreateUserRes,
  DeleteUserReq,
  DeleteUserRes,
  GetUserByIdReq,
  GetUserByIdRes,
  GetUserReq,
  GetUserRes,
  ListUserReq,
  ListUserRes,
  MultiGetUserReq,
  MultiGetUserRes,
  UpdateUserAvatarReq,
  UpdateUserAvatarRes,
  UpdateUserLocaleReq,
  UpdateUserLocaleRes,
  UpdateUserReq,
  UpdateUserRes
} from "src/types/raw/quizium/user_service";
import {createApi, createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category ユーザー
 */
export interface UserForCreate {
  email: string;
  password: string;
  handle: string;
  nickname: string;
  campaignCode: string;
};

/**
 * 新しいユーザーを作成します。
 * ユーザーの作成に成功すると、自動的にそのユーザーでログインします。
 * @param user 作成するユーザー情報
 * @returns ユーザー ID
 * @group API 関数
 * @category ユーザー
 */
export const createUser = createAsyncApi(async (user: UserForCreate): Promise<Id> => {
  const {email, password, ...restUser} = user;
  const credential = await firebaseAuth.createUserWithEmailAndPassword(auth, email, password);
  const firebaseUid = credential.user.uid;

  try {
    const req: CreateUserReq = {user: {...restUser, firebaseUid, biography: "", locale: "ja"}};
    const res = await client.post<CreateUserRes>("/quizium/v3/user", req);

    const token = await credential.user.getIdToken(true);
    TokenManager.instance.set(token);

    return res.data.id;
  } catch (error) {
    if (auth.currentUser != null) {
      await firebaseAuth.deleteUser(credential.user);
    }
    throw error;
  }
}, "createUser");

/**
 * @group 型
 * @category ユーザー
 */
export interface UserForUpdate {
  handle: string;
  nickname: string;
  biography: string;
  icon: string;
  campaignCode: string;
  locale: string;
}

/**
 * @group API 関数
 * @category ユーザー
 */
export const updateUser = createAsyncApi(async (user: UserForUpdate): Promise<void> => {
  const req: UpdateUserReq = {user};
  const res = await client.post<UpdateUserRes>("/quizium/v3/user/update", req);
}, "updateUser");

/**
 * @group API 関数
 * @category ユーザー
 */
export const deleteMe = createAsyncApi(async (): Promise<void> => {
  if (auth.currentUser != null) {
    const req: DeleteUserReq = {handle: "me"};
    const res = await client.post<DeleteUserRes>("/quizium/v3/user/delete", req);
    await firebaseAuth.deleteUser(auth.currentUser);
  } else {
    throw new QuiziumError("quiziumUnauthenticated", undefined);
  }
}, "deleteMe");

/**
 * ログインします。
 * ログインに成功すると、内部で自動的にトークンが保持され、それ以降他の関数を呼んだときにそのトークンを送るようになります。
 * ユーザーが Cookie の利用に同意している場合は、トークンは Cookie にも保存され、ページを閉じても保持されます。
 * ユーザーが Cookie の利用を拒否している (もしくは Cookie の利用について回答していない) 場合は、トークンは保存されないため、ページを閉じると再度ログインが必要になります。
 * Cookie の利用可否は、`updateCookieAgreed` 関数で変更できます。
 *
 * この関数はトークンを返しますが、トークンは内部で自動的に保持されるので、基本的にこのパッケージの利用者側で保持しておく必要はありません。
 * @param email メールアドレス
 * @param password パスワード
 * @returns トークン
 * @group API 関数
 * @category ユーザー
 */
export const login = createAsyncApi(async (email: string, password: string): Promise<string> => {
  const credential = await firebaseAuth.signInWithEmailAndPassword(auth, email, password);
  const token = await credential.user.getIdToken(true);
  TokenManager.instance.set(token);
  return token;
}, "login");

/**
 * ログアウトします。
 * Cookie などに保存されているトークンが削除されます。
 * @group API 関数
 * @category ユーザー
 */
export const logout = createAsyncApi(async (): Promise<void> => {
  await firebaseAuth.signOut(auth);
  TokenManager.instance.clear();
}, "logout");

/**
 * @group API 関数
 * @category ユーザー
 */
export const getMe = createAsyncApi(async (): Promise<User> => {
  const req: GetUserReq = {handle: "me"};
  const res = await client.post<GetUserRes>("/quizium/v3/user/handle", req);
  return res.data.user!;
}, "getMe");

/**
 * @group API 関数
 * @category ユーザー
 */
export const getUserByHandle = createAsyncApi(async (handle: string): Promise<User> => {
  const req: GetUserReq = {handle};
  const res = await client.post<GetUserRes>("/quizium/v3/user/handle", req);
  return res.data.user!;
}, "getUserByHandle");

/**
 * @group API 関数
 * @category ユーザー
 */
export const getUserById = createAsyncApi(async (id: Id): Promise<PublicUser> => {
  const req: GetUserByIdReq = {userId: id};
  const res = await client.post<GetUserByIdRes>("/quizium/v3/user/id", req);
  return res.data.user!;
}, "getUserById");

/**
 * @group API 関数
 * @category ユーザー
 */
export const getUsersByHandles = createAsyncApi(async (handles: Array<string>): Promise<Array<PublicUser>> => {
  if (handles.length > 0) {
    const req: MultiGetUserReq = {handles, ids: []};
    const res = await client.post<MultiGetUserRes>("/quizium/v3/user/MultiGet", req);
    return res.data.user;
  } else {
    return [];
  }
}, "getUsersByHandles");

/**
 * @group API 関数
 * @category ユーザー
 */
export const getUsersByIds = createAsyncApi(async (ids: Array<Id>): Promise<Array<PublicUser>> => {
  if (ids.length > 0) {
    const req: MultiGetUserReq = {ids, handles: []};
    const res = await client.post<MultiGetUserRes>("/quizium/v3/user/MultiGet", req);
    return res.data.user;
  } else {
    return [];
  }
}, "getUsersByIds");

/**
 * @group 型
 * @category ユーザー
 */
export interface UserQuery {
  handle?: string;
  limit?: number;
  skip?: number;
}

/**
 * @group API 関数
 * @category ユーザー
 */
export const listUsers = createAsyncApi(async (query: UserQuery): Promise<[Array<PublicUser>, number]> => {
  const req: Partial<ListUserReq> = query;
  const res = await client.post<ListUserRes>("/quizium/v3/user/list", req);
  return [res.data.users, res.data.total];
}, "listUsers");

/**
 * 内部に保存されているトークンを返します。
 * @returns トークン (存在しない場合は `null`)
 */
export const getToken = createApi((): string | null => {
  return TokenManager.instance.get();
}, "getToken");

/**
 * @group API 関数
 * @category ユーザー
 */
export const updateMyEmail = createAsyncApi(async (email: string): Promise<void> => {
  if (auth.currentUser != null) {
    await firebaseAuth.updateEmail(auth.currentUser, email);
  } else {
    throw new QuiziumError("quiziumUnauthenticated", undefined);
  }
}, "updateMyEmail");

/**
 * @group API 関数
 * @category ユーザー
 */
export const updateMyPassword = createAsyncApi(async (password: string): Promise<void> => {
  if (auth.currentUser != null) {
    await firebaseAuth.updatePassword(auth.currentUser, password);
  } else {
    throw new QuiziumError("quiziumUnauthenticated", undefined);
  }
}, "updateMyPassword");

/**
 * @group API 関数
 * @category ユーザー
 */
export const updateMyLocale = createAsyncApi(async (locale: string): Promise<void> => {
  const req: UpdateUserLocaleReq = {locale};
  const res = await client.post<UpdateUserLocaleRes>("/quizium/v3/user/locale", req);
}, "updateMyLocale");

/**
 * @group API 関数
 * @category ユーザー
 */
export const updateMyAvatar = createAsyncApi(async (wearableSet: WearableSet): Promise<string> => {
  const req: UpdateUserAvatarReq = {wearableSet};
  const res = await client.post<UpdateUserAvatarRes>("/quizium/v3/user/avatar", req);
  return res.data.avatar;
}, "updateMyAvatar");