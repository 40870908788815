import {TokenManager} from "src/clients/tokenManager";
import {createApi} from "src/utils/api";


/**
 * Cookie の利用に同意するかどうかを設定します。
 * これに `false` を渡して実行すると、それ以降トークンなどが Cookie に保存されなくなります。
 * @param agreed 同意する場合 `true`, 同意しない場合 `false`
 * @group API 関数
 * @category Cookie
 */
export const updateCookieAgreed = createApi((agreed: boolean): void => {
  if (agreed) {
    TokenManager.instance.agree();
  } else {
    TokenManager.instance.refuse();
  }
}, "updateCookieAgreed");

/**
 * Cookie の利用に同意しているかどうかを取得します。
 * @returns 同意している場合 `true`, 同意していない場合 `false`
 * @group API 関数
 * @category Cookie
 */
export const getCookieAgreed = createApi((): boolean => {
  return TokenManager.instance.getAgreed();
}, "getCookieAgreed");

/**
 * Cookie の利用に同意するか回答済みかどうかを取得します。
 * @returns 回答済みの場合 `true`, 回答済みでない場合 `false`
 * @group API 関数
 * @category Cookie
 */
export const getCookieAgreedSet = createApi((): boolean => {
  return TokenManager.instance.getAnswered();
}, "getCookieAgreedSet");