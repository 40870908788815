import {DateString, Id} from "src/types";


/**
 * @group 列挙型
 * @category 統計
 */
export const AggregationUnit = {
  /**
   * 単位なしの数値。
   */
  Number: "UnitTypeNumber",
  /**
   * 単位「点」の数値。
   */
  NumberPoint: "UnitTypeNumberPoint",
  /**
   * 単位「秒」の時間。
   */
  TimeSecond: "UnitTypeTimeSecond",
  /**
   * 単位「分」の時間。
   */
  TimeMinute: "UnitTypeTimeMinute",
  /**
   * 単位「時間」の時間。
   */
  TimeHour: "UnitTypeTimeHour",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type AggregationUnit = typeof AggregationUnit[keyof typeof AggregationUnit];

/**
 * @group 列挙型
 * @category 統計
 */
export const AggregationFormat = {
  /**
   * 数値。
   */
  Number: "FormatTypeNumber",
  /**
   * 時間。
   */
  Time: "FormatTypeTime",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type AggregationFormat = typeof AggregationFormat[keyof typeof AggregationFormat];

/**
 * @group 型
 * @category 統計
 */
export interface TotalStat {
  /** */
  appId: string;
  /**
   * 該当ユーザーがクイズに出会った回数。
   * 自分が作成したクイズに出会った場合もカウントされます。
   */
  encounterCount: number;
  /**
   * 該当ユーザーが作成したクイズに他のプレイヤーが出会った回数。
   * 自分で出会った場合はカウントされません。
   */
  encounteredCount: number;
}

/**
 * @group 型
 * @category 統計
 */
export interface MonthlyStat {
  /** */
  appId: string;
  /**
   * 月 (YYYY-MM 形式)。
   */
  month: string;
  /**
   * 該当ユーザーがクイズに出会った回数。
   * 自分が作成したクイズに出会った場合もカウントされます。
   */
  encounterCount: number;
  /**
   * 該当ユーザーが作成したクイズに他のプレイヤーが出会った回数。
   * 自分で出会った場合はカウントされません。
   */
  encounteredCount: number;
}

/**
 * @group 型
 * @category 統計
 */
export interface MonthlyPlayableDeckStat {
  /** */
  playableDeckId: Id;
  /** */
  appId: string;
  /**
   * 月 (YYYY-MM 形式)。
   */
  month: string;
  /**
   * 該当デッキが出会われた回数。
   */
  encounteredCount: number;
  /** */
  name: string;
  /** */
  tags: Array<string>;
  /** */
  originId: Id;
  /** */
  groupId: Id;
  /** */
  authorId: Id;
}

/**
 * @group 型
 * @category 統計
 */
export interface AggregationTagSpec {
  /**
   * ID。
   */
  id: Id;
  /**
   * タグ名。
   */
  tag: string;
  /**
   * 単位。
   */
  unit: AggregationUnit;
  /**
   * 数値の種類。
   */
  format: AggregationFormat;
  /**
   * 小数点以下何位まで表示するか。
   * `0` の場合は整数で表示されます。
   */
  fractionDigits: number;
  /**
   * 順序。
   * `1` もしくは `-1` が入ります。
   * - `1` — 数値が小さいほどランキングで上位 (経過時間など)
   * - `-1` — 数値が大きいほどランキングで上位 (通常のポイントなど)
   */
  order: 1 | -1;
}

/**
 * @group 型
 * @category イベント
 */
export interface Encounter {
  /**
   * 出会ったクイズの ID。
   */
  quizId: Id;
  /**
   * 出会ったクイズが登録されていた配信済みデッキの ID。
   */
  playableDeckId: Id;
  /**
   * 該当クイズに出会ったときにプレイしていたアプリの ID。
   */
  appId: string;
  /**
   * 該当クイズを遊んだ時間 (ミリ秒単位)。
   */
  playTime: number;
  /**
   * 該当クイズを遊んだ日時。
   */
  playAt: DateString;
}

/**
 * @group 型
 * @category イベント
 */
export interface Score {
  /** */
  aggregationTag: string;
  /**
   * 獲得得点。
   */
  point: number;
  /**
   * ユーザーの回答。
   */
  answers: Array<string>;
  /**
   * この得点を獲得したときのイベントの ID。
   */
  competitionId: Id;
  /** */
  teamId: Id;
}
