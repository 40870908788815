import {DateString, Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category 画像
 */
export const ImageType = {
  /**
   * クイズ用の画像。
   */
  Quiz: "ImageTypeQuiz",
  /**
   * アイコン用の画像。
   */
  Icon: "ImageTypeIcon",
  /** */
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type ImageType = typeof ImageType[keyof typeof ImageType];

/**
 * @group 型
 * @category 画像
 */
export interface Image {
  /**
   * ID。
   */
  id: Id;
  /**
   * 名前。
   */
  name: string;
  /**
   * タグの配列。
   */
  tags: Array<string>;
  /**
   * 画像の種類。
   */
  imageType: ImageType;
  /**
   * 画像の MIME タイプ。
   */
  mime: string;
  /** */
  authorId: Id;
  /** */
  groupId: Id;
  /** */
  isPrivate: boolean;
  /** */
  createAt: DateString;
}