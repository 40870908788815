import {Id} from "src/types/modified/misc";


/**
 * @group 型
 * @category ディレクトリ
 */
export interface Directory {
  /**
   * ID。
   */
  id: Id;
  /**
   * 名前。
   */
  name: string;
  /**
   * 親ディレクトリの ID。
   * このディレクトリがルート直下に置かれている場合は空文字列が入ります。
   */
  parentId: Id | "";
  /**
   * 祖先ディレクトリの ID の配列。
   * ルート直下にあるディレクトリの ID が最初に置かれます。
   * 自分自身は含めません。
   * 特に、ルート直下に置かれているディレクトリに対しては空配列になります。
   *
   * 例えば、A → B → C というディレクトリ構造がある場合、各ディレクトリの `path` は以下のようになります。
   * - C の `path` — `[(A の ID), (B の ID)]`
   * - B の `path` — `[(A の ID)]`
   * - A の `path` — `[]` (空配列)
   *
   * ディレクトリ構造は最大で 6 階層までしか許されていないので、この配列の長さは最大で 5 です (自分自身を含めないため)。
   */
  path: Array<Id>;
  /**
   * このディレクトリを所有するグループの ID。
   */
  groupId: Id;
}

/* -------------------------------------------------------------------------- */

/** @ignore */
export namespace Directory {
  export const EMPTY = {
    id: Id.EMPTY,
    name: "",
    parentId: Id.EMPTY,
    path: [],
    groupId: Id.EMPTY
  } satisfies Directory;
  export const DEFAULT = {
    id: Id.DEFAULT,
    name: "",
    parentId: Id.DEFAULT,
    path: [],
    groupId: Id.DEFAULT
  } satisfies Directory;
};
