import dayjs from "dayjs";
import {client} from "src/clients/client";
import {APP_IDS} from "src/modules/app";
import {
  AggregationTagSpec,
  AnswerStatus,
  MonthlyPlayableDeckStat,
  MonthlyStat,
  TotalStat
} from "src/types";
import {toMonthlyPlayableDeckStat, toMonthlyStat, toTotalStat} from "src/types/conversion/stat";
import {DateString, Id} from "src/types/modified/misc";
import {
  CreateEncounterReq,
  CreateEncounterRes,
  CreateScoresReq,
  CreateScoresRes,
  GetAggregationTagSpecReq,
  GetAggregationTagSpecRes,
  GetStatsReq,
  GetStatsRes,
  ListAppStatsReq,
  ListAppStatsRes,
  ListMonthlyPlayableDeckStatsReq,
  ListMonthlyPlayableDeckStatsRes
} from "src/types/raw/quizium/stats_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category 統計
 */
export interface WholeTotalStats {
  all: TotalStat;
  app: Array<TotalStat>;
};

/**
 * 指定されたユーザーの全期間における総プレイ数情報を、全アプリについて一括で取得します。
 * @group API 関数
 * @category 統計
 */
export const getWholeTotalStats = createAsyncApi(async (userId: Id): Promise<WholeTotalStats> => {
  const req: GetStatsReq = {userId};
  const res = await client.post<GetStatsRes>("/quizium/v3/stats/user/total", req);
  return {all: {appId: "all", encounterCount: res.data.totalPlayQuiz, encounteredCount: res.data.totalPlayMyQuiz}, app: res.data.app.map(toTotalStat)};
}, "getWholeTotalStats");

/**
 * @group 型
 * @category 統計
 */
export interface MonthlyStatQuery {
  /**
   * アプリの ID。
   * `"all"` を指定すると、全アプリの総計を取得します。
   */
  appId: string;
  /**
   * 検索の始点となる月 (YYYY-MM 形式)。
   * 検索結果にはこの月のデータが含まれます。
   */
  fromMonth?: string;
  /**
   * 検索の終点となる月 (YYYY-MM 形式)。
   * 検索結果にはこの月のデータが含まれます。
   */
  toMonth?: string;
  /** */
  limit?: number;
  /** */
  skip?: number;
}

/**
 * 指定されたユーザーの月間プレイ数情報を、アプリごとに (もしくは全アプリの総計を) 取得します。
 * @group API 関数
 * @category 統計
 */
export const listMonthlyStats = createAsyncApi(async (userId: Id, query: MonthlyStatQuery): Promise<Array<MonthlyStat>> => {
  const req: Partial<ListAppStatsReq> = {...query, userId, appId: query.appId === "all" ? "" : query.appId};
  const res = await client.post<ListAppStatsRes>("/quizium/v3/stats/user/app", req);
  return res.data.app.map(toMonthlyStat);
}, "listMonthlyStats");

/**
 * @group 型
 * @category 統計
 */
export interface MonthlyPlayableDeckStatQuery {
  /** */
  groupId?: Id;
  /**
   * 月 (YYYY-MM 形式)。
   */
  month?: string;
  /** */
  limit?: number;
  /** */
  skip?: number;
}

/**
 * 指定されたグループの配信デッキの月間プレイ数情報を取得します。
 * @group API 関数
 * @category 統計
 */
export const listMonthlyPlayableDeckStats = createAsyncApi(async (query: MonthlyPlayableDeckStatQuery): Promise<Array<MonthlyPlayableDeckStat>> => {
  const req: Partial<ListMonthlyPlayableDeckStatsReq> = query;
  const res = await client.post<ListMonthlyPlayableDeckStatsRes>("/quizium/v3/stats/playable/group", req);
  return res.data.stats.map(toMonthlyPlayableDeckStat);
}, "listMonthlyPlayableDeckStats");

/**
 * @group 型
 * @category 統計
 */
export interface TotalAndMonthlyStats {
  total: TotalStat;
  monthly: Array<MonthlyStat>;
};

/**
 * @group 型
 * @category 統計
 */
export interface WholeTotalAndMonthlyStats {
  all: TotalAndMonthlyStats;
  app: Array<TotalAndMonthlyStats>;
};

/**
 * 指定されたユーザーの全期間における総プレイ数情報と月間プレイ数情報を、全アプリについて一括で取得します。
 * このとき、月間プレイ数情報は、`limit` に指定された月数の分だけ取得します。
 * データが存在しなかった場合は適宜 0 埋めされるので、返される月間プレイ数情報は必ず `limit` に指定された月数分になります。
 * @group API 関数
 * @category 統計
 */
export const getWholeTotalAndMonthlyStats = createAsyncApi(async (userId: Id, limit: number): Promise<WholeTotalAndMonthlyStats> => {
  const startDay = dayjs().startOf("month");
  const fromMonth = startDay.subtract(limit, "month").format("YYYY-MM");

  const totalStats = await getWholeTotalStats(userId);

  const [allStats, ...appStats] = await Promise.all([
    (async () => {
      const monthlyAllStats = await listMonthlyStats(userId, {appId: "all", fromMonth});
      const filledMonthlyAllStats = Array.from({length: limit}, (dummy, index) => {
        const month = startDay.subtract(index, "month").format("YYYY-MM");
        const monthlyAllStat = monthlyAllStats.find((stat) => stat.month === month) ?? {month, appId: "all", encounterCount: 0, encounteredCount: 0};
        return monthlyAllStat;
      });
      const totalAllStats = {...totalStats.all, appId: "all"};
      const allStats = {total: totalAllStats, monthly: filledMonthlyAllStats};
      return allStats;
    })(),
    ...Object.values(APP_IDS).map(async (appId) => {
      const monthlyAppStats = await listMonthlyStats(userId, {appId, fromMonth});
      const filledMonthlyAppStats = Array.from({length: limit}, (dummy, index) => {
        const month = startDay.subtract(index, "month").format("YYYY-MM");
        const monthlyAppStat = monthlyAppStats.find((stat) => stat.month === month) ?? {month, appId, encounterCount: 0, encounteredCount: 0};
        return monthlyAppStat;
      });
      const totalAppStats = totalStats.app.find((stat) => stat.appId === appId) ?? {appId, encounterCount: 0, encounteredCount: 0};
      const appStats = {total: totalAppStats, monthly: filledMonthlyAppStats};
      return appStats;
    })
  ]);
  return {all: allStats, app: appStats};
}, "getWholeTotalAndMonthlyStats");

/**
 * @group API 関数
 * @category 統計
 */
export const getAggregationTagSpec = createAsyncApi(async (tag: string): Promise<AggregationTagSpec> => {
  const req: GetAggregationTagSpecReq = {tag};
  const res = await client.post<GetAggregationTagSpecRes>("/quizium/v3/aggregation/tagspec/get", req);
  return res.data.tagSpec as any;
}, "getAggregationTagSpec");

/**
 * @group 型
 * @category イベント
 */
export interface EncounterForCreate {
  quizId: Id;
  playableDeckId: Id;
  appId: string;
  status: AnswerStatus;
  playTime: number;
  playAt: DateString;
}

/**
 * @group 型
 * @category イベント
 */
export interface ScoreForCreateWithEncounter {
  aggregationTag: string;
  point: number;
  answers: Array<string>;
  competitionId: Id;
  teamId?: Id;
}

/**
 * クイズとの出会いを作成します。
 * イベント中にクイズに出会った場合で、その出会いによってイベントの得点が発生した場合は、第 2 引数に得点情報を渡してください。
 * @group API 関数
 * @category イベント
 */
export const createEncounter = createAsyncApi(async (encounter: EncounterForCreate, scores?: Array<ScoreForCreateWithEncounter>): Promise<Id> => {
  const req: CreateEncounterReq = {
    encounter,
    status: {status: encounter.status},
    scores: scores?.map((score) => ({...score, status: encounter.status, teamId: score.teamId ?? ""})) ?? []
  };
  const res = await client.post<CreateEncounterRes>("/quizium/v3/encounter", req);
  return res.data.id;
}, "createEncounter");

/**
 * @group 型
 * @category イベント
 */
export interface ScoreForCreate {
  aggregationTag: string;
  point: number;
  competitionId: string;
  teamId?: string;
}

/**
 * イベントの得点を作成します。
 * イベント中にクイズに出会ったことが理由で得点が発生した場合は、代わりに `createEncounter` 関数を使用してください。
 * @group API 関数
 * @category イベント
 */
export const createScores = createAsyncApi(async (scores: Array<ScoreForCreate>): Promise<Array<Id>> => {
  const req: CreateScoresReq = {scores: scores.map((score) => ({...score, teamId: score.teamId ?? ""}))};
  const res = await client.post<CreateScoresRes>("/quizium/v3/score/create", req);
  return res.data.ids;
}, "createScores");