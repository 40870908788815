import {getAnalytics} from "firebase/analytics";
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {switchEnv, switchFirebaseAppName} from "src/modules";


export const FIREBASE_CONFIG = switchEnv({
  production: {
    apiKey: "AIzaSyA94_1gfBzFPZGShRuhYZ9O-ZXA4YfMSD4",
    authDomain: "quizium-e4edf.firebaseapp.com",
    projectId: "quizium-e4edf",
    storageBucket: "quizium-e4edf.appspot.com",
    messagingSenderId: "305694612464",
    appId: switchFirebaseAppName({
      library: "1:305694612464:web:9e0fe083814ddc0b385b35",
      qroud: "1:305694612464:web:87711e4d611f39c0385b35"
    }),
    measurementId: "G-341XH5G2WQ"
  },
  staging: {
    apiKey: "AIzaSyBzXfdZ3vN5f2tzKZxpcWJCDU06RE589iY",
    authDomain: "quizium-staging.firebaseapp.com",
    projectId: "quizium-staging",
    storageBucket: "quizium-staging.appspot.com",
    messagingSenderId: "196754358252",
    appId: switchFirebaseAppName({
      library: "1:196754358252:web:5c31c83c27afc57af00ac9",
      qroud: "1:196754358252:web:600bf5d4a73b94c9f00ac9"
    }),
    measurementId: "G-G8MGFCZ7BT"
  },
  development: {
    apiKey: "AIzaSyB2Z27b703RY50OAJgkKWiJ-eK46LCzlnE",
    authDomain: "quizium-dev-83d46.firebaseapp.com",
    projectId: "quizium-dev-83d46",
    storageBucket: "quizium-dev-83d46.appspot.com",
    messagingSenderId: "20279341526",
    appId: switchFirebaseAppName({
      library: "1:20279341526:web:b1960fe433e4da139d3477",
      qroud: "1:20279341526:web:9038cb3efed3a4ac9d3477"
    }),
    measurementId: "G-008T94EQZ5"
  }
});

export const firebase = initializeApp(FIREBASE_CONFIG);

export const auth = getAuth(firebase);
export const analytics = typeof window !== "undefined" ? getAnalytics(firebase) : null;