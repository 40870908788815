import {client} from "src/clients/client";
import {Id} from "src/types";
import {Report, ReportStatus} from "src/types/modified/report";
import {
  ListReportReq,
  ListReportRes,
  UpdateReportReq,
  UpdateReportRes
} from "src/types/raw/admin/inquiry_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category 通報
 */
export interface ReportForUpdate {
  /** */
  id: Id;
  /** */
  status: ReportStatus;
  /** */
  memo: string;
  /**
   * クライアント側で保持している変更前のバージョン番号と同じものを指定してください。
   */
  version: number;
}

/**
 * @group API 関数
 * @category 通報
 */
export const updateReport = createAsyncApi(async (report: ReportForUpdate): Promise<void> => {
  const req: UpdateReportReq = {report};
  const res = await client.post<UpdateReportRes>("/admin/v3/report/update", req);
}, "updateReport");

/**
 * @group 型
 * @category 通報
 */
export interface ReportQuery {
  statuses: Array<ReportStatus>;
  appId: string;
  limit: number;
  skip: number;
}

/**
 * @group API 関数
 * @category 通報
 */
export const listReports = createAsyncApi(async (query: ReportQuery): Promise<[Array<Report>, number]> => {
  const req: ListReportReq = {query};
  const res = await client.post<ListReportRes>("/admin/v3/report/list", req);
  return [res.data.reports, res.data.total];
}, "listReports");