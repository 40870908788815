import {DateString, Id} from "src/types/modified/misc";


/**
 * @group 列挙型
 * @category ユーザー
 */
export const UserStatus = {
  Active: "UserStatusActive",
  Guest: "UserStatusGuest",
  Locked: "UserStatusLocked",
  Expired: "UserStatusExpired",
  Banned: "UserStatusBaned",
  Unrecognized: "UNRECOGNIZED"
} as const;
/** @ignore */
export type UserStatus = typeof UserStatus[keyof typeof UserStatus];

/**
 * @group 型
 * @category ユーザー
 */
export interface User {
  /**
   * ID。
   */
  id: Id;
  /**
   * ハンドル。
   * ID と同じくグループを一意に識別する文字列ですが、ID とは以下の点で異なります。
   * - 人間に読みやすい形式である (半角英数小文字, ハイフン, アンダーバーで構成される)
   * - ユーザーに見える位置に表示される
   */
  handle: string;
  /**
   * 名前。
   */
  nickname: string;
  /**
   * アバターの画像 ID。
   */
  avatar: string;
  /**
   * ユーザーの説明文。
   */
  biography: string;
  /**
   * アイコンの画像 ID。
   */
  icon: Id;
  /**
   * このユーザーが登録されたときに利用された招待コード。
   */
  campaignCode: string;
  /**
   * ロケール。
   */
  locale: string;
  /** */
  status: UserStatus;
  /** */
  deleteAt: DateString;
}

/**
 * @group 型
 * @category ユーザー
 */
export interface PublicUser {
  /**
   * ID。
   */
  id: Id;
  /**
   * ハンドル。
   * ID と同じくグループを一意に識別する文字列ですが、ID とは以下の点で異なります。
   * - 人間に読みやすい形式である (半角英数小文字, ハイフン, アンダーバーで構成される)
   * - ユーザーに見える位置に表示される
   */
  handle: string;
  /**
   * 名前。
   */
  nickname: string;
  /**
   * ユーザーの説明文。
   */
  biography: string;
  /**
   * アイコンの画像 ID。
   */
  icon: Id;
  /**
   * このユーザーが登録されたときに利用された招待コード。
   */
  campaignCode: string;
  /** */
  status: UserStatus;
  /** */
  deleteAt: DateString;
}

/* -------------------------------------------------------------------------- */

/** @ignore */
export namespace User {
  export const EMPTY = {
    id: Id.EMPTY,
    handle: "",
    nickname: "",
    avatar: "",
    biography: "",
    icon: "",
    campaignCode: "",
    locale: "ja",
    status: UserStatus.Active,
    deleteAt: DateString.EMPTY
  } satisfies User;
  export const DEFAULT = {
    id: Id.DEFAULT,
    handle: "",
    nickname: "",
    avatar: "",
    biography: "",
    icon: "",
    campaignCode: "",
    locale: "",
    status: UserStatus.Active,
    deleteAt: DateString.DEFAULT
  } satisfies User;
};