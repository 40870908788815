import {client} from "src/clients/client";
import {
  Image,
  ImageType
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  CreateImageReq,
  CreateImageRes,
  DeleteImageReq,
  DeleteImageRes,
  GetCompetitionImageReq,
  GetCompetitionImageRes,
  GetImageReq,
  GetImageRes,
  ListImageReq,
  ListImageRes,
  UpdateImagePrivateReq,
  UpdateImagePrivateRes,
  UpdateImageReq,
  UpdateImageRes
} from "src/types/raw/quizium/image_service";
import {createAsyncApi} from "src/utils/api";
import {DeepPartial} from "ts-essentials";


/**
 * @group 型
 * @category 画像
 */
export interface ImageForCreate {
  name: string;
  data: string;
  tags: Array<string>;
  imageType: ImageType;
  groupId: Id;
  isPrivate: boolean;
};

/**
 * @group API 関数
 * @category 画像
 */
export const createImage = createAsyncApi(async (image: ImageForCreate): Promise<Id> => {
  const req: CreateImageReq = {image};
  const res = await client.post<CreateImageRes>("/quizium/v3/image", req);
  return res.data.id;
}, "createImage");

/**
 * @group 型
 * @category 画像
 */
export interface ImageForUpdate {
  id: Id;
  name: string;
  tags: Array<string>;
  isPrivate: boolean;
}

/**
 * @group API 関数
 * @category 画像
 */
export const updateImage = createAsyncApi(async (image: ImageForUpdate): Promise<void> => {
  const req: UpdateImageReq = {image};
  const res = await client.post<UpdateImageRes>("/quizium/v3/image/update", req);
}, "updateImage");

/**
 * 画像の公開設定のみを変更します。
 * @group API 関数
 * @category 画像
 */
export const updateImagePrivate = createAsyncApi(async (id: Id, isPrivate: boolean): Promise<void> => {
  const req: UpdateImagePrivateReq = {id, isPrivate};
  const res = await client.post<UpdateImagePrivateRes>("/quizium/v3/image/private", req);
}, "updateImagePrivate");

/**
 * @group API 関数
 * @category 画像
 */
export const deleteImage = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteImageReq = {id};
  const res = await client.post<DeleteImageRes>("/quizium/v3/image/delete", req);
}, "deleteImage");

/**
 * @group 型
 * @category 画像
 */
export type ImageWithUrl = Image & {url: string};

/**
 * @group API 関数
 * @category 画像
 */
export const getImage = createAsyncApi(async (id: Id): Promise<ImageWithUrl> => {
  const req: GetImageReq = {id};
  const res = await client.post<GetImageRes>("/quizium/v3/image/get", req);
  return {...res.data.info!, url: res.data.url};
}, "getImage");

/**
 * @group API 関数
 * @category 画像
 */
export const getImageByCompetition = createAsyncApi(async (id: Id, quizId: Id, competitionId: Id): Promise<ImageWithUrl> => {
  const req: GetCompetitionImageReq = {id, quizId, competitionId};
  const res = await client.post<GetCompetitionImageRes>("/quizium/v3/image/competition", req);
  return {...res.data.info!, url: res.data.url};
}, "getImageByCompetition");

/**
 * @group 型
 * @category 画像
 */
export interface ImageQuery {
  tag?: string;
  imageTypes?: Array<ImageType>;
  authorId?: Id;
  groupId?: Id;
  skip?: number;
  limit?: number;
}

/**
 * @group API 関数
 * @category 画像
 */
export const listImages = createAsyncApi(async (query: ImageQuery): Promise<[Array<Image>, number]> => {
  const req: DeepPartial<ListImageReq> = {query};
  const res = await client.post<ListImageRes>("/quizium/v3/image/group/list", req);
  return [res.data.images, res.data.total];
}, "listImages");
