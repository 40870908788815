import {client} from "src/clients/client";
import {
  Directory,
  DraftDeck,
  ListedDraftDeck
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  AddQuizIDsReq,
  AddQuizIDsRes,
  CreateDraftDeckReq,
  CreateDraftDeckRes,
  DeleteDraftDeckReq,
  DeleteDraftDeckRes,
  GetDraftDeckReq,
  GetDraftDeckRes,
  ListDraftDeckReq,
  ListDraftDeckRes,
  ListNodeReq,
  ListNodeRes,
  UpdateDraftDeckReq,
  UpdateDraftDeckRes,
  UpdateQuizIDsReq,
  UpdateQuizIDsRes
} from "src/types/raw/quizium/deck_service";
import {createAsyncApi} from "src/utils/api";
import {DeepPartial} from "ts-essentials";


/**
 * @group 型
 * @category 下書きデッキ
 */
export interface DraftDeckForCreate {
  quizIds: Array<Id>;
  name: string;
  tags: Array<string>;
  description: string;
  groupId: Id;
  directoryId: Id;
  isRestricted: boolean;
}

/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const createDraftDeck = createAsyncApi(async (deck: DraftDeckForCreate): Promise<Id> => {
  const req: CreateDraftDeckReq = {draftDeck: deck};
  const res = await client.post<CreateDraftDeckRes>("/quizium/v3/draftdeck/create", req);
  return res.data.id;
}, "createDraftDeck");

/**
 * @group 型
 * @category 下書きデッキ
 */
export interface DraftDeckForUpdate {
  id: Id;
  name: string;
  tags: Array<string>;
  description: string;
  directoryId: Id;
  isRestricted: boolean;
}

/**
 * 下書きデッキを編集します。
 *
 * この関数ではデッキに含まれているクイズは変更できません。
 * 代わりに `addQuizzesInDraftDeck`, `updateQuizzesInDraftDeck` 関数を利用してください。
 * @group API 関数
 * @category 下書きデッキ
 */
export const updateDraftDeck = createAsyncApi(async (deck: DraftDeckForUpdate): Promise<void> => {
  const req: UpdateDraftDeckReq = {draftDeck: {draftDeckId: deck.id, ...deck}};
  const res = await client.post<UpdateDraftDeckRes>("/quizium/v3/draftdeck/update", req);
}, "updateDraftDeck");

/**
 * 下書きデッキを削除します。
 * このデッキを配信した配信済みデッキが存在している場合、削除はできません。
 * @group API 関数
 * @category 下書きデッキ
 */
export const deleteDraftDeck = createAsyncApi(async (id: Id): Promise<void> => {
  const req: DeleteDraftDeckReq = {draftDeckId: id};
  const res = await client.post<DeleteDraftDeckRes>("/quizium/v3/draftdeck/delete", req);
}, "deleteDraftDeck");

/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const getDraftDeck = createAsyncApi(async (id: Id): Promise<DraftDeck> => {
  const req: GetDraftDeckReq = {draftDeckId: id};
  const res = await client.post<GetDraftDeckRes>("/quizium/v3/draftdeck/get", req);
  return res.data.deck!;
}, "getDraftDeck");

/**
 * @group 型
 * @category 下書きデッキ
 */
export interface DraftDeckQuery {
  /** */
  name?: string;
  /** */
  tag?: string;
  /** */
  authorId?: Id;
  /** */
  groupId?: Id;
  /**
   * 検索を始めるディレクトリの ID。
   * これを指定すると、指定されたディレクトリ以下の全ての階層が検索の対象になります。
   * このディレクトリ直下のみが検索の対象になるわけではありません。
   */
  directoryId?: Id | "" | "root";
  /** */
  limit?: number;
  /** */
  skip?: number;
}

/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const listDraftDecks = createAsyncApi(async (query: DraftDeckQuery): Promise<[Array<ListedDraftDeck>, number]> => {
  const req: DeepPartial<ListDraftDeckReq> = {query};
  const res = await client.post<ListDraftDeckRes>("/quizium/v3/draftdeck/list", req);
  return [res.data.draftDecks, res.data.total];
}, "listDraftDecks");

/**
 * @group 型
 * @category 下書きデッキ
 */
export interface DraftNodeQuery {
  /** */
  groupId?: Id;
  /**
   * 検索の対象とするディレクトリの ID。
   * 指定されたディレクトリ直下のみが検索の対象になります。
   * ルートディレクトリを指定する場合は、`""` か `"root"` を入れてください。
   */
  directoryId: Id | "" | "root";
  /**
   * 検索結果に含めるデッキの個数。
   * この値に関わらず、ディレクトリは常に全て返されます。
   */
  limit?: number;
  /**
   * 検索結果から飛ばすデッキの数。
   * この値に関わらず、ディレクトリは常に全て返されます。
   */
  skip?: number;
}


/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const listDraftNodes = createAsyncApi(async (query: DraftNodeQuery): Promise<{directories: Array<Directory>, decks: Array<ListedDraftDeck>}> => {
  const req: Partial<ListNodeReq> = query;
  const res = await client.post<ListNodeRes>("/quizium/v3/node/list", req);
  return {directories: res.data.directories, decks: res.data.draftDecks};
}, "listDraftNodes");

/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const listDraftNodesOrDecks = createAsyncApi(async (nodeQuery: DraftNodeQuery, deckQuery: DraftDeckQuery, showNode: boolean): Promise<{directories: Array<Directory>, decks: Array<ListedDraftDeck>}> => {
  if (showNode) {
    const nodes = await listDraftNodes(nodeQuery);
    return nodes;
  } else {
    const [decks] = await listDraftDecks(deckQuery);
    const nodes = {directories: [], decks};
    return nodes;
  }
}, "listDraftNodesOrDecks");

/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const addQuizzesToDraftDeck = createAsyncApi(async (id: Id, quizIds: Array<Id>): Promise<void> => {
  const req: AddQuizIDsReq = {draftDeckId: id, quizIds};
  const res = await client.post<AddQuizIDsRes>("/quizium/v3/draftdeck/quizids/add", req);
}, "addQuizzesToDraftDeck");

/**
 * @group API 関数
 * @category 下書きデッキ
 */
export const updateQuizzesInDraftDeck = createAsyncApi(async (id: Id, quizIds: Array<Id>): Promise<void> => {
  const req: UpdateQuizIDsReq = {draftDeckId: id, quizIds};
  const res = await client.post<UpdateQuizIDsRes>("/quizium/v3/draftdeck/quizids/update", req);
}, "updateQuizzesInDraftDeck");