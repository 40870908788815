import {DeckMemberType, GroupMemberType} from "src/types";


export const isAboveGroupMemberType = (memberType: GroupMemberType | null | undefined, target: GroupMemberType): boolean => {
  if (memberType === GroupMemberType.Owner) {
    return true;
  } else if (memberType === GroupMemberType.Director) {
    return target !== GroupMemberType.Owner;
  } else if (memberType === GroupMemberType.Editor) {
    return target !== GroupMemberType.Owner && target !== GroupMemberType.Director;
  } else if (memberType === GroupMemberType.Normal) {
    return target !== GroupMemberType.Owner && target !== GroupMemberType.Director && target !== GroupMemberType.Editor;
  } else {
    return false;
  }
};

export const isAboveDeckMemberType = (memberType: DeckMemberType | null | undefined, target: DeckMemberType): boolean => {
  if (memberType === DeckMemberType.Owner) {
    return true;
  } else if (memberType === DeckMemberType.Director) {
    return target !== DeckMemberType.Owner;
  } else if (memberType === DeckMemberType.Editor) {
    return target !== DeckMemberType.Owner && target !== DeckMemberType.Director;
  } else if (memberType === DeckMemberType.Normal) {
    return target !== DeckMemberType.Owner && target !== DeckMemberType.Director && target !== DeckMemberType.Editor;
  } else {
    return false;
  }
};