import {client} from "src/clients/client";
import {ReportReasonType, ReportTargetType} from "src/types/modified/report";
import {
  CreateReportReq,
  CreateReportRes
} from "src/types/raw/quizium/inquiry_service";
import {createAsyncApi} from "src/utils/api";


/**
 * @group 型
 * @category 通報
 */
export interface ReportForCreate {
  reasonType: ReportReasonType;
  targetType: ReportTargetType;
  targetId: string;
  appId: string;
  detail: string;
}

/**
 * @group API 関数
 * @category 通報
 */
export const createReport = createAsyncApi(async (report: ReportForCreate): Promise<void> => {
  const req: CreateReportReq = {report};
  const res = await client.post<CreateReportRes>("/quizium/v3/report/create", req);
}, "createReport");