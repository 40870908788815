import {client} from "src/clients/client";
import {
  DeckMember,
  DeckMemberType
} from "src/types";
import {Id} from "src/types/modified/misc";
import {
  CreateDeckMemberReq,
  CreateDeckMemberRes,
  DeleteDeckMemberReq,
  DeleteDeckMemberRes,
  ListDeckMemberReq,
  ListDeckMemberRes,
  UpdateDeckMemberReq,
  UpdateDeckMemberRes
} from "src/types/raw/quizium/deck_service";
import {createAsyncApi} from "src/utils/api";


/**
 * 指定された下書きデッキに対する権限をユーザーに付与します。
 * 対象の下書きデッキの `isRestricted` が `true` になっていないと意味がありません。
 * @param deckId 下書きデッキ ID
 * @param userId ユーザー ID
 * @param memberType 権限
 * @returns 紐づけオブジェクトの ID
 * @group API 関数
 * @category デッキメンバー
 */
export const createDeckMember = createAsyncApi(async (deckId: Id, userId: Id, memberType: DeckMemberType): Promise<Id> => {
  const req: CreateDeckMemberReq = {deckMember: {draftDeckId: deckId, userId, memberType}};
  const res = await client.post<CreateDeckMemberRes>("/quizium/v3/deck/member", req);
  return res.data.id;
}, "createDeckMember");

/**
 * 指定された下書きデッキに対する権限を変更します。
 * @param deckId 下書きデッキ ID
 * @param userId ユーザー ID
 * @param memberType 権限
 * @group API 関数
 * @category デッキメンバー
 */
export const updateDeckMember = createAsyncApi(async (deckId: Id, userId: Id, memberType: DeckMemberType): Promise<void> => {
  const req: UpdateDeckMemberReq = {deckMember: {draftDeckId: deckId, userId, deckMemberId: "update", memberType}};
  const res = await client.post<UpdateDeckMemberRes>("/quizium/v3/deck/member/update", req);
}, "updateDeckMember");

/**
 * @group API 関数
 * @category デッキメンバー
 */
export const deleteDeckMember = createAsyncApi(async (deckId: Id, userId: Id): Promise<void> => {
  const req: DeleteDeckMemberReq = {draftDeckId: deckId, userId};
  const res = await client.post<DeleteDeckMemberRes>("/quizium/v3/deck/member/delete", req);
}, "deleteDeckMember");

/**
 * @group 型
 * @category デッキメンバー
 */
export interface DeckMemberQuery {
  deckId?: string;
};

/**
 * @group API 関数
 * @category デッキメンバー
 */
export const listDeckMembers = createAsyncApi(async (query: DeckMemberQuery): Promise<Array<DeckMember>> => {
  const req: Partial<ListDeckMemberReq> = {draftDeckId: query.deckId};
  const res = await client.post<ListDeckMemberRes>("/quizium/v3/deck/member/list", req);
  return res.data.members;
}, "listDeckMembers");