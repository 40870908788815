// 共通
export * from "./clients/client";
export * from "./clients/constants";
export * from "./clients/firebase";

export * from "./hooks/api";
export * from "./hooks/queryClient";

export * from "./types";
export * from "./modules";

// API
export {
  updateCookieAgreed,
  getCookieAgreed,
  getCookieAgreedSet
} from "./repositories/library/cookie";
export {
  createCompetition,
  CompetitionForCreate,
  getCompetition,
  listCompetitions,
  CompetitionQuery,
  aggregateCompetition,
  getCompetitionWholeResult,
  getCompetitionQuizResult,
  getCompetitionUserResult,
  participateCompetition,
  holdCompetition
} from "./repositories/library/competition";
export {
  getIncorrectChoices
} from "./repositories/library/openai";
export {
  getPlayableDeck,
  getPlayableDeckByCompetition,
  listPlayableDecks,
  PlayableDeckQuery
} from "./repositories/library/playableDeck";
export {
  getGroupByHandle,
  getGroupById,
  getGroupsByIds
} from "./repositories/library/group";
export {
  listGroupUsers,
  GroupUserQuery,
  listGroupsOfUser,
  GroupOfUser
} from "./repositories/library/groupUser";
export {
  getImage,
  ImageWithUrl
} from "./repositories/library/image";
export {
  getQuiz,
  getQuizByOriginId,
  getQuizzes,
  getQuizByCompetition,
  getQuizzesByCompetition,
  getQuizzesInPlayableDecksByCompetition,
  QuizWithDeckId
} from "./repositories/library/quiz";
export {
  getAggregationTagSpec,
  createEncounter,
  ScoreForCreateWithEncounter,
  createScores,
  ScoreForCreate
} from "./repositories/library/stats";
export {
  createUser,
  updateUser,
  login,
  logout,
  getMe,
  getUserByHandle,
  getUserById,
  listUsers,
  UserQuery
} from "./repositories/library/user";